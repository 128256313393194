import React from "react";

import { useGetIntegrationDetails } from "../../../../api/workspace";
import { CardWithTitle } from "../../../../components/CardWithTitle/CardWithTitle";
import { CodeBox } from "../../../../components/CodeBox/CodeBox";
import { HintBanner } from "../../../../components/HintBanner/HintBanner";
import { StatusStripe } from "../../../../components/StatusStripe/StatusStripe";
import { useGetEcommerceStatusStripeProperties } from "../../../../hooks/integration/useGetEcommerceStatusStripeProperties";
import { useGetIntegrationGlobalSiteTag } from "../../../../hooks/integration/useGetIntegrationGlobalSiteTag";
import { EcommercePlatformEnum } from "../../../../redux/types";
import { CONTACT_APP_URL, CURRENCY_LIST_URL } from "../../../../utils/constants/urlStrings";

import styles from "../IntegrationContent.module.scss";

export const CustomPlatform: React.FC = () => {
  const { isFetching } = useGetIntegrationDetails();
  const { commonUrlParametersProps, commonConversionParametersProps } = useGetEcommerceStatusStripeProperties();
  const globalSiteTag = useGetIntegrationGlobalSiteTag(EcommercePlatformEnum.custom);

  return (
    <>
      <HintBanner>
        Struggling to connect your custom platform yourself? You can{" "}
        <a href={CONTACT_APP_URL} target="_blank" rel="noreferrer">
          contact us
        </a>{" "}
        and our developers can code the integration for you (hourly rate applied).
      </HintBanner>
      <CardWithTitle iconName="ico-custom-big" title="Initial information" subtitle="Custom" videoControl={false}>
        <div className={styles.customInfo}>
          <h3>How long would take adding one more header in the custom integration?</h3>
          <p>
            We are the first tracking software that allows to connect your store that is on a custom plaform! So if you
            don’t have store on platform we already support, such as Shopify or WooCommerce, you can follow the steps
            below to get the most accurate data even if you are on a custom platform.
          </p>
          <p>
            Follow the instructions below, and for successful connection, send these instructions to the site
            manager/developer. They just need to put these two codes on the website, most likely changing the coding
            language of the codes below to the coding language of the custom platform to ensure the correct data in the
            correct format is being sent to us.
          </p>
        </div>
      </CardWithTitle>
      <CardWithTitle iconName="ico-custom-big" title="Global Tracking Code" subtitle="Custom" videoControl={false}>
        <p>You need to install the global site tag in between the {"<head></head>"} tags on your site.</p>
        <CodeBox src={globalSiteTag} />
        <StatusStripe isLoading={isFetching} {...commonUrlParametersProps} />
      </CardWithTitle>

      <CardWithTitle iconName="ico-custom-big" title="Event Snippet" subtitle="Custom" videoControl={false}>
        <p>After that, you also need to install the event snippet.</p>
        <p>
          An event snippet tracks a conversion any time somebody clicks one of your ads and then later reaches the
          checkout page on your online store.
        </p>
        <ol>
          <li>
            <p>The event snippet should run on every successfull order. It’s best to put it in the ’thank you’ page.</p>
          </li>
          <li>
            <p>Then you’ll need to get the data to send to us.</p>
          </li>
          <li>
            <p>
              Note: Make sure that the event snippet runs only once per order and doesn’t rerun on a page refresh.
              You’ll need to create your own logic for that.
            </p>
          </li>
        </ol>
        <p>
          <span>
            See{" "}
            <a href={CURRENCY_LIST_URL} target="_blank" rel="noreferrer">
              list of supported currencies
            </a>
          </span>
        </p>
        <CodeBox
          src={`<script>
  etag('event', 'conversion', {
    'value': 'ORDER_SUBTOTAL',
    'order_id': 'ORDER_ID',
    'currency': 'CURRENCY',
    'transaction_id': 'TRANSACTION_ID',
    'customer_email': 'CUSTOMER_EMAIL',
    'customer_id': 'CUSTOMER_ID',
    'customer_first_name': 'CUSTOMER_FIRST_NAME',
    'customer_last_name': 'CUSTOMER_LAST_NAME',
    'customer_total_spent': 'ORDER_TOTAL',
    'customer_phone': 'CUSTOMER_PHONE',
    'customer_accepts_marketing': 'CUSTOMER_ACCEPTS_MARKETING'
  });
  </script>`}>
          {/* // prettier-ignore */}
          <span>
            {`<script>`}
            {`\n`}
            {`\n`}
            {`etag('event', 'conversion', {`}
            {`\n`}
            {/* // prettier-ignore */}
            {`'value'`}: <span className={styles.customChange}>{`'ORDER_SUBTOTAL'`}</span>,
            {`    // string, REQUIRED (e.g. "20.00")`}
            {`\n`}
            {/* // prettier-ignore */}
            {`'order_id'`}: <span className={styles.customChange}>{`'ORDER_ID'`}</span>,
            {`    // string, REQUIRED (e.g. "1234567890")`}
            {`\n`}
            {/* // prettier-ignore */}
            {`'currency'`}: <span className={styles.customChange}>{`'CURRENCY'`}</span>,
            {`    // string, REQUIRED, must be from the currency list (link above)`}
            {`\n`}
            {/* // prettier-ignore */}
            {`'transaction_id'`}: <span className={styles.customChange}>{`'TRANSACTION_ID'`}</span>,
            {`    // string, OPTIONAL (e.g. "1234567890")`}
            {`\n`}
            {/* // prettier-ignore */}
            {`'customer_email'`}: <span className={styles.customChange}>{`'CUSTOMER_EMAIL'`}</span>,
            {`    // string, OPTIONAL (e.g. "john.doe@email.com")`}
            {`\n`}
            {/* // prettier-ignore */}
            {`'customer_id'`}: <span className={styles.customChange}>{`'CUSTOMER_ID'`}</span>,
            {`    // string, OPTIONAL (e.g. "1234567890")`}
            {`\n`}
            {`'customer_first_name'`}: <span className={styles.customChange}>{`'CUSTOMER_FIRST_NAME'`}</span>,
            {`    // string, OPTIONAL (e.g. "John")`}
            {`\n`}
            {`'customer_last_name'`}: <span className={styles.customChange}>{`'CUSTOMER_LAST_NAME'`}</span>,
            {`    // string, OPTIONAL (e.g. "Doe")`}
            {`\n`}
            {`'customer_total_spent'`}: <span className={styles.customChange}>{`'ORDER_TOTAL'`}</span>,
            {`    // string, OPTIONAL (e.g. "20.00")`}
            {`\n`}
            {`'customer_phone'`}: <span className={styles.customChange}>{`'CUSTOMER_PHONE'`}</span>,{" "}
            {`    // string, OPTIONAL (e.g. "123456789")`}
            {`\n`}
            {`'customer_accepts_marketing'`}:
            <span className={styles.customChange}>{`'CUSTOMER_ACCEPTS_MARKETING'`}</span>
            {`    // string, OPTIONAL, allowed values: "true", "false"`}
            {`\n`}
            {`});`}
            {`\n`}
            {`\n`}
            {`</script>`}
          </span>
        </CodeBox>
        <StatusStripe isLoading={isFetching} {...commonConversionParametersProps} />
      </CardWithTitle>
    </>
  );
};
