import React from "react";
import { useHistory, useLocation } from "react-router";
import classNames from "classnames";

import { MainMenuItem } from "../../../hooks/componentSpecific/useGetMainMenuItems";
import { useGetBreakpointProperties } from "../../../hooks/screen/useGetBreakpointProperties";
import { Icon } from "../../Icon/Icon";

import styles from "./FooterNavbar.module.scss";

type FooterNavbarItemProps = Pick<MainMenuItem, "text" | "to" | "isHidden" | "iconName" | "errorInfo">;

export const FooterNavbarItem: React.FC<FooterNavbarItemProps> = ({ text, to, isHidden, iconName, errorInfo }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isMobile } = useGetBreakpointProperties();
  const isWorkspace = text.startsWith("Workspace") && isMobile;
  const isActive = pathname.startsWith(to);
  const appliedItemClassName = isHidden ? styles.hiddenItem : styles.item;

  const getAppliedClassName = () => {
    const appliedClassNamesArray = [styles.text];
    let additionalClassName = "";
    if (isWorkspace) {
      appliedClassNamesArray.push(styles.workspaceText);
    }
    if (isActive && isWorkspace) {
      additionalClassName = styles.workspaceActive;
    }
    if (isActive) {
      additionalClassName = styles.active;
      appliedClassNamesArray.push(additionalClassName);
    }

    const appliedClassName = classNames(appliedClassNamesArray);
    return appliedClassName;
  };

  const handleNavigate = () => {
    if (isHidden) {
      return;
    }
    history.push(to);
  };

  return (
    <li className={appliedItemClassName}>
      <div className={styles.link} onClick={handleNavigate}>
        <div className={styles.iconsContainer}>
          <Icon name={iconName} isActive={isActive} className={styles.icon} />
          {!isHidden && errorInfo && <Icon name="ico-hint-red" width="16" className={styles.errorIcon} />}
        </div>
        <h6 className={getAppliedClassName()}>{isWorkspace ? "Work. setup" : text}</h6>
      </div>
    </li>
  );
};
