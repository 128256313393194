import React from "react";

import { ContentSegment } from "./ContentSegment";
import { HeaderSegment } from "./HeaderSegment";

interface InnerContainerComponentProps {
  children: React.ReactNode;
  child: React.ReactNode;
  i: number;
}

export const InnerContainerComponent: React.FC<InnerContainerComponentProps> = ({ children, child, i }) => {
  const isHeaderSegment: boolean = i === 0;
  const hasOnlyOneChild: boolean = React.Children.toArray(children).length === 1;

  if (hasOnlyOneChild) {
    return <ContentSegment child={children} />;
  }

  if (isHeaderSegment) {
    return <HeaderSegment child={child} />;
  }

  return <ContentSegment child={child} />;
};
