import React, { useEffect, useMemo, useState } from "react";
import { ResponsiveLine } from "@nivo/line";

import { DashboardViewResponseDTO } from "../../../api/views/dashboard/dto";
import { Container } from "../../../components/Container/Container";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { getUrlDateRange } from "../../../utils/formatters/dateFormatters";
import { LockedSection } from "../LockedSection/LockedSection";
import { SummaryTooltip } from "./SummaryTooltip";

import styles from "./Summary.module.scss";

export const Summary: React.FC<DashboardViewResponseDTO> = ({ dataByDates: { previousPeriod, currentPeriod } }) => {
  const [aggregation, setAggregation] = useState<string>("daily");
  const pad = (num: any) => ("0" + num).slice(-2);
  const makeDate = (d: any) => `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${d.getDate()}`;
  const { startDate, endDate } = getUrlDateRange();
  useEffect(() => {
    const difference = new Date(startDate).getTime() - new Date(endDate).getTime();
    const dayDiff = difference / (1000 * 3600 * 24);
    if (Math.abs(dayDiff) > 15) setAggregation("weekly");
  }, [startDate, endDate]);

  const graphData = useMemo(
    () => [
      {
        id: "Revenue from Ads",
        color: "hsl(201, 70%, 50%)",
        data: previousPeriod?.map((_, index) => ({
          x: makeDate(new Date(currentPeriod[index].date)),
          y: currentPeriod[index].sumPurchasesValueFromAds,
          historicValue: previousPeriod[index].sumPurchasesValueFromAds,
        })),
      },
      {
        id: "Organic Revenue",
        color: "hsl(201, 70%, 50%)",
        data: previousPeriod?.map((_, index) => ({
          x: makeDate(new Date(currentPeriod[index].date)),
          y: currentPeriod[index].sumPurchasesOrganicValue,
          historicValue: previousPeriod[index].sumPurchasesOrganicValue,
        })),
      },
    ],
    [currentPeriod],
  );

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <h3>Summary</h3>
      </div>
      <div className={styles.chart}>
        {graphData && (
          <ResponsiveLine
            data={graphData}
            colors={["#2E58FF", "#132BB1"]}
            margin={{ top: 50, right: 25, bottom: 50, left: 50 }}
            xScale={{ type: "time", format: "%Y-%m-%d", precision: "hour" }}
            yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
            yFormat=" >-.2f"
            curve="linear"
            axisTop={null}
            axisRight={null}
            enableGridX={true}
            xFormat="time:%Y-%m-%d"
            axisBottom={{
              format: "%b %d",
              tickValues: `every ${aggregation === "daily" ? "day" : "week"}`,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: "middle",
            }}
            enablePoints={currentPeriod.length === 1}
            tooltip={SummaryTooltip as any}
            enableCrosshair={false}
            crosshairType="x"
            useMesh={true}
            legends={[
              {
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: -40,
                translateY: -50,
                itemsSpacing: 50,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
              },
            ]}
          />
        )}
      </div>
    </Container>
  );
};

export const LoadingSummary: React.FC = () => {
  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <h3>Summary</h3>
      </div>
      <LoadingSpinner />
    </Container>
  );
};

export const LockedSummary: React.FC = () => {
  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <h3>Summary</h3>
      </div>
      <LockedSection height={310} />
    </Container>
  );
};
