import { useMutation } from "react-query";

import { useSwitchWorkspace } from "../../../hooks/workspace/useSwitchWorkspace";
import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { NotificationSeverity, Workspace } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { CreateNewWorkspaceRequestDTO } from "./dto";

export const useCreateWorkspaceMutation = () => {
  return useMutation(
    (data: CreateNewWorkspaceRequestDTO) => {
      return defaultMutationFn("workspace/new", { data: data });
    },

    {
      onSuccess: async (data: Workspace) => {
        useSwitchWorkspace(data, true);

        store.dispatch(
          showNotification({
            message: "Workspace successfully created",
            severity: NotificationSeverity.SUCCESS,
          }),
        );

        // this is to force react query to refetch data on getting back to the add workspace page
        queryClient.removeQueries();
      },
    },
  );
};
