import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { WorkspaceWithRolesDTO } from "../get-one/dto";
import { AddUserToWorkspaceRequestDTO } from "./dto";

export const useAddWorkspaceUserMutation = () => {
  return useMutation(
    (data: AddUserToWorkspaceRequestDTO) => {
      return defaultMutationFn(`workspace/${data.workspaceId}/user`, { data: { email: data.email, role: data.role } });
    },
    {
      onSuccess: async (data, vars) => {
        queryClient.setQueryData<WorkspaceWithRolesDTO | undefined>([`workspace/${vars.workspaceId}`], (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              invitedUsers: [...oldData.invitedUsers, { email: vars.email, role: vars.role }],
            };
          }
        });
        await queryClient.invalidateQueries([`workspace/${vars.workspaceId}`]);
        store.dispatch(
          showNotification({ message: "User successfully added", severity: NotificationSeverity.SUCCESS }),
        );
      },
    },
  );
};
