import React from "react";
import classNames from "classnames";

import { Icon } from "../Icon";

import styles from "./ChevronIcon.module.scss";

interface ChevronIconProps {
  isActive: boolean;
}

export const ChevronIcon: React.FC<ChevronIconProps> = ({ isActive }) => {
  const getAppliedIconClasses = () => {
    const appliedIconClassesArray = [styles.arrow];
    if (isActive) {
      appliedIconClassesArray.push(styles.active);
    }

    const appliedIconClasses = classNames(...appliedIconClassesArray);
    return appliedIconClasses;
  };

  return <Icon className={getAppliedIconClasses()} height="12px" name="chevron" />;
};
