import React, { CSSProperties } from "react";
import classNames from "classnames";

import styles from "./TariffUsageBar.module.scss";

interface TariffUsageBarProps extends CSSProperties {
  percentage: number;
}

export const TariffUsageBar: React.FC<TariffUsageBarProps> = ({ percentage, height }) => {
  //so we don't overflow
  const displayedPercentage = percentage >= 100 ? 100 : percentage;
  const getAppliedIndicatorClassNames = () => {
    const indicatorClassNamesArray = [styles.indicator];
    if (displayedPercentage > 80) {
      indicatorClassNamesArray.push(styles.orangeIndicator);
    }
    if (displayedPercentage === 100) {
      indicatorClassNamesArray.push(styles.redIndicator);
    }

    const appliedIndicatorClassNames = classNames(indicatorClassNamesArray);
    return appliedIndicatorClassNames;
  };

  return (
    <div className={styles.container} style={{ height }}>
      <span
        className={getAppliedIndicatorClassNames()}
        style={{
          width: `${displayedPercentage}%`,
        }}
      />
    </div>
  );
};
