import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { IntegrationDetails, NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { getIntegrationDetailUrl } from "../../workspace";

export const useSetFacebookAccessToken = () => {
  return useMutation(
    (data: { token: string | null; workspaceId: number }) => {
      return defaultMutationFn("facebook/set-access-token", {
        data: {
          facebookToken: data.token,
          workspaceId: data.workspaceId,
        },
        method: "PUT",
      });
    },
    {
      onSuccess: (_, vars) => {
        queryClient.setQueryData<IntegrationDetails | undefined>(
          getIntegrationDetailUrl(vars.workspaceId),
          (oldData) => {
            if (oldData) {
              return {
                ...oldData,
                fbConnected: !!vars.token,
              };
            }
          },
        );

        queryClient.invalidateQueries(getIntegrationDetailUrl(vars.workspaceId));

        if (vars.token) {
          store.dispatch(
            showNotification({ message: "Facebook successfully authorized", severity: NotificationSeverity.SUCCESS }),
          );
          return;
        }

        store.dispatch(
          showNotification({ message: "Facebook successfully unauthorized", severity: NotificationSeverity.SUCCESS }),
        );
      },
    },
  );
};
