import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { EcommercePlatformEnum } from "../../redux/types";

export const useGetIntegrationGlobalSiteTag = (platform: EcommercePlatformEnum): string => {
  const { data: currentWorkspaceData } = useGetCurrentWorkspaceDetail();
  const workspaceIdentifier = currentWorkspaceData?.identifier;

  const globalSiteTag = `<!— Global site tag (etag.js) - ecomtrack.io analytics —>
  <script async src="${process.env.REACT_APP_API_URL}/tag/script?id=${workspaceIdentifier}&p=${platform}&v=${process.env.REACT_APP_VERSION}"></script>
  <script>
    window.edata = window.edata || [];
    function etag(){edata.push(arguments);}
  </script>
  `;

  return globalSiteTag;
};
