import { MutableRefObject, useEffect, useRef } from "react";

type ClickOutsideHandler = ((event: MouseEvent) => void) | (() => void);

export const useHandleClickOutside = (clickOutsideHandler?: ClickOutsideHandler): MutableRefObject<any> => {
  const ref = useRef<any | null>(null);
  const handleClickOutside = (event: MouseEvent) => {
    const isOutside = ref.current && !ref.current.contains(event.target);

    if (isOutside && clickOutsideHandler) {
      clickOutsideHandler(event);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return ref;
};
