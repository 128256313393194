import React from "react";

import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

import styles from "./Switch.module.scss";

interface SwitchProps {
  checkedLabel: string;
  uncheckedLabel: string;
  onCheck?: () => void;
  onUncheck?: () => void;
  checked?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const Switch: React.FC<SwitchProps> = ({
  checkedLabel,
  uncheckedLabel,
  onCheck,
  onUncheck,
  checked = false,
  isDisabled = false,
  isLoading = false,
}) => {
  const actionCanBeTriggered = !isDisabled && onCheck && onUncheck;
  const handleClick = () => {
    if (!actionCanBeTriggered) {
      return;
    }
    if (checked && onUncheck) {
      onUncheck();
      return;
    }
    if (onCheck) {
      onCheck();
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner containerWidth={60} />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.label.concat(" ", checked ? styles.checked : "")}>
            {checked ? checkedLabel : uncheckedLabel}
          </div>
          <input disabled={isDisabled} type="checkbox" name="toggle" id={styles.toggle} checked={checked} readOnly />
          <label htmlFor="toggle" onClick={handleClick} />
        </div>
      )}
    </>
  );
};
