import React, { MouseEventHandler, useState } from "react";
import { useHistory } from "react-router";
import { AnimatePresence, motion } from "framer-motion";

import { useModal } from "../../hooks/modal/useModal";
import { useHandleClickOutside } from "../../hooks/screen/useHandleClickOutside";
import { ReferAndEarnModal } from "../../modals/ReferAndEarnModal/ReferAndEarnModal";
import { HELP_APP_URL } from "../../utils/constants/urlStrings";
import { invalidateSession } from "../../utils/localStorageHandlers";
import { Icon } from "../Icon/Icon";

import styles from "./UserMenu.module.scss";

interface UserMenuItem {
  name: string;
  icon: string;
  onClick: (bool?: boolean) => void;
  to?: string;
}

export const UserMenu: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { toggle, visible: modalVisible } = useModal();

  const goToSettings = () => {
    history.push("/user/settings");
  };

  const goToHelp = () => {
    window.open(HELP_APP_URL, "_blank");
  };

  const menuItems: UserMenuItem[] = [
    {
      name: "User Settings",
      icon: "ico__menuCog",
      onClick: goToSettings,
      to: "/user/settings",
    },
    {
      name: "Helpdesk",
      icon: "ico__menuHelp",
      onClick: goToHelp,
    },
    {
      name: "Refer & Earn",
      icon: "ico__menuRefer",
      onClick: toggle,
    },
    {
      name: "Log out",
      icon: "ico__menuHand",
      onClick: invalidateSession,
    },
  ];

  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    setVisible(false);
  };
  const ref = useHandleClickOutside(handleClickOutside);
  const handleClick = () => {
    setVisible(true);
  };

  return (
    <>
      <div className={styles.userMenuWrapper} onClick={handleClick}>
        <Icon name="ico_dots" height="20px" />
        <AnimatePresence>
          {visible && (
            <motion.div variants={fadeIn} initial="hidden" animate="visible" exit="exit" ref={ref}>
              <div className={styles.userMenu}>
                {menuItems.map((item) => {
                  const handleMenuItemClick: MouseEventHandler<HTMLDivElement> = (event) => {
                    event.stopPropagation();
                    item.onClick();
                    setVisible(false);
                  };
                  return (
                    <div className={styles.menuItem} key={item.name} onClick={handleMenuItemClick}>
                      <Icon name={item.icon} />
                      <p className={styles.text}>{item.name}</p>
                    </div>
                  );
                })}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <ReferAndEarnModal toggle={toggle} visible={modalVisible} />
    </>
  );
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "ease",
    },
    "will-change": "opacity",
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      type: "ease",
    },
    "will-change": "opacity",
  },
};
