import React from "react";

import { IModal } from "../../../redux/types";
import { DatePicker } from "../../DatePicker/DatePicker";
import { UserInfo } from "../../UserInfo/UserInfo";
import { BigWorkspaceSwitchButton } from "../HeaderWorkspaceSwitchButton/BigWorkspaceSwitchButton";

import styles from "./HeaderContent.module.scss";

export const MobileHeaderContent: React.FC<IModal> = (props) => {
  return (
    <div className={styles.mobileHeaderContent}>
      <div className={styles.top}>
        <DatePicker />
        <BigWorkspaceSwitchButton {...props} />
        <div className={styles.user}>
          <UserInfo />
        </div>
      </div>
    </div>
  );
};
