import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SubscriptionDetailInfo } from "../../utils/getSubscriptionDetailInfo";
import { getWorkspaceFromLocalStorage } from "../../utils/localStorageHandlers";
import { CurrencyType, IntegrationDetails, WorkspaceState } from "../types";

const workspaceFromLocalStorage = getWorkspaceFromLocalStorage();

const defaultSubscriptionInfo = {
  percentageUsed: 0,
  hasASubscription: false,
  maxLevel: false,
  currentRevenue: 0,
  subscriptionLimit: 0,
  subscriptionLimitRoundedString: "",
  currentRevenueRoundedString: "",
  currency: CurrencyType.USD,
  price: 0,
  priceRoundedString: "",
};

const initWorkspaceState: WorkspaceState = {
  integrationDetails: undefined as unknown as IntegrationDetails,
  currentId: workspaceFromLocalStorage ? parseInt(workspaceFromLocalStorage) : undefined,
  subscriptionInfo: defaultSubscriptionInfo,
};

const workspaceSlice = createSlice({
  name: "workspace",
  initialState: initWorkspaceState,
  reducers: {
    setCurrentId: (state, action: any) => {
      state.currentId = action.payload;
    },
    setSubscriptionInfo: (state, action: PayloadAction<SubscriptionDetailInfo>) => {
      state.subscriptionInfo = action.payload;
    },
  },
});

export const { setCurrentId, setSubscriptionInfo } = workspaceSlice.actions;
export default workspaceSlice.reducer;
