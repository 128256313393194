import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { getAttribution } from "../../../redux/selectors/app";
import { IAggregatedCampaigns } from "../../../redux/types";
import { getUrlDateRange } from "../../../utils/formatters/dateFormatters";
import { GenericViewsProps } from "../types";

const useGetCampaigns = ({ from, to, attributionType, workspaceId }: GenericViewsProps) => {
  return useQuery<IAggregatedCampaigns>(
    [`views/campaigns/${workspaceId}?from=${from}&to=${to}&attributionType=${attributionType}`],
    {
      enabled: !!workspaceId,
    },
  );
};

export const useGetCampaignsFromCurrentDetails = () => {
  const { from, to } = getUrlDateRange();
  const currentId = useGetCurrentWorkspaceId();
  const attribution = useSelector(getAttribution);
  return useGetCampaigns({
    from,
    to,
    attributionType: attribution,
    workspaceId: currentId,
  });
};
