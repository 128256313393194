import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CampaignsState, DataContentStatus, ExtendedCampaign, ICampaignColsInput } from "../types";

const initCampaignState: CampaignsState = {
  campaigns: [],
  extendedCampaigns: [],
  campaignsTableCols: { all: [], temporarilySelected: [] },
  dataContentStatus: DataContentStatus.NO_DATA,
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState: initCampaignState,
  reducers: {
    setCampaigns: (state, action: PayloadAction<any[]>) => {
      state.campaigns = action.payload;
    },
    setExtendedCampaigns: (state, action: PayloadAction<ExtendedCampaign[]>) => {
      state.extendedCampaigns = action.payload;
    },
    updateCampaignsCols: (state, action: PayloadAction<ICampaignColsInput>) => {
      const cols = action.payload.cols;
      const type = action.payload.type;

      state.campaignsTableCols[type] = cols;
    },
    restoreDefaultCampaigns: (state) => {
      state.campaigns = [];
      state.extendedCampaigns = [];
    },
    setDataContentStatus: (state, action: PayloadAction<DataContentStatus>) => {
      state.dataContentStatus = action.payload;
    },
  },
});

export const {
  setCampaigns,
  setExtendedCampaigns,
  updateCampaignsCols,
  restoreDefaultCampaigns,
  setDataContentStatus,
} = campaignSlice.actions;
export default campaignSlice.reducer;
