import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { createGetPricingPlanInfoUrl, getPricingPlanInfoForAllWorkspacesUrl } from "../../billing";
import { useGetLoggedInUser } from "../../user";

export const useRestoreWorkspace = () => {
  const { refetch } = useGetLoggedInUser();

  return useMutation((id: number) => defaultMutationFn(`workspace/${id}/restore`, { method: "PATCH" }), {
    onMutate: (id) => {
      store.dispatch(
        showNotification({ severity: NotificationSeverity.SUCCESS, message: "Workspace reactivated successfully" }),
      );
      queryClient.invalidateQueries([getPricingPlanInfoForAllWorkspacesUrl]);
      queryClient.invalidateQueries([`workspace/${id}`]);
      queryClient.invalidateQueries([createGetPricingPlanInfoUrl(id)]);
    },
    onSuccess: () => {
      refetch();
    },
  });
};
