import React from "react";
import { components } from "react-select";

import { ChevronIcon } from "../Icon/ChevronIcon/ChevronIcon";

import styles from "./Select.module.scss";

export const SelectDropdownIndicator: React.FC<any> = (props) => {
  const { DropdownIndicator } = components;
  const menuIsOpen: boolean = props.selectProps.menuIsOpen;

  return (
    <DropdownIndicator {...props}>
      <div className={styles.iconContainer}>
        <ChevronIcon isActive={menuIsOpen} />
      </div>
    </DropdownIndicator>
  );
};
