import React from "react";

import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { useSendPopupWindowMessage } from "../../hooks/user/useSendPopupWindowMessage";

export const GoogleAuthenticationResult: React.FC = () => {
  useSendPopupWindowMessage();

  return <LoadingSpinner />;
};
