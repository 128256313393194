import React from "react";

import { Button, ButtonVariant } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { IModal } from "../../redux/types";
import { AFFILIATES_APP_LOGIN_URL, AFFILIATES_APP_REGISTER_URL } from "../../utils/constants/urlStrings";

import styles from "./ReferAndEarnModal.module.scss";

type ReferAndEarnModalProps = IModal;

export const ReferAndEarnModal: React.FC<ReferAndEarnModalProps> = ({ toggle, visible }) => {
  const goToAffiliatesRegister = () => {
    window.open(AFFILIATES_APP_REGISTER_URL, "_blank");
  };

  return (
    <Modal visible={visible} animation="fadeIn" fitContent toggle={toggle} className={styles.modal}>
      <div className={styles.content}>
        <h2 className={styles.header}>Refer ecomtrack {"&"} Earn commissions</h2>
        <p className={styles.text}>
          Join ecomtrack Affiliate Club and receive <span className={styles.highlighted}>15% recurring commission</span>{" "}
          on all payments as long as the customer stays with us! You’ll get your unique URL link, dashboard with stats +
          a 60-day cookie.
        </p>
        <Button variant={ButtonVariant.PRIMARY} onClick={goToAffiliatesRegister} className={styles.btn}>
          Create your Affiliate account
        </Button>
        <p className={styles.subtext}>
          Already have the Affiliate account?{" "}
          <a href={AFFILIATES_APP_LOGIN_URL} rel="noreferrer" target="_blank" className={styles.link}>
            Login.
          </a>
        </p>
      </div>
    </Modal>
  );
};
