import React from "react";

import { Icon } from "../Icon/Icon";

import styles from "./EcomtrackVsFbColumnHeader.module.scss";

export const EcomtrackVsFbColumnHeader: React.FC = () => (
  <div>
    <div className={styles.container}>
      <Icon name="logo_square" width="14" height="14" />
      <span>vs</span>
      <Icon name="facebook" width="14" height="14" />
    </div>
  </div>
);
