import React from "react";

import { useGetIntegrationDetails } from "../../../../api/workspace";
import { CardWithTitle } from "../../../../components/CardWithTitle/CardWithTitle";
import { CodeBox } from "../../../../components/CodeBox/CodeBox";
import { Divider } from "../../../../components/Divider/Divider";
import { StatusStripe } from "../../../../components/StatusStripe/StatusStripe";
import { getStatusStripeProperties } from "../../../../utils/getStatusStripeProperties";

export const TikTokAdsProvider: React.FC = () => {
  const { isFetching: isIntegrationFetching, data: integrationDetails } = useGetIntegrationDetails();
  const urlParameters = integrationDetails?.adProvidersData.tiktok.urlParameters;
  const tiktokUrlParametersProps = getStatusStripeProperties([urlParameters]);

  return (
    <CardWithTitle iconName="ico-tiktok-big" title="Add URL Parameters" subtitle="TikTok Ads" videoControl={false}>
      <p>You need to set up your TikTok Ads URL parameters for every ad connected to your e-shop.</p>
      <ol>
        <li>
          <p>Navigate to your TikTok Ads Manager.</p>
        </li>
        <li>
          <p>
            Go to Campaigns and select the campaign or create a new one. Make sure {"Advertising objective"} in Campaign
            section is set to Traffic, otherwise the following steps will NOT work.
          </p>
        </li>
        <li>
          <p>Make sure {"Promotion type"} in Ad group section is set to Website.</p>
        </li>
        <li>
          <p>
            In the Ad section, navigate to Ad details and paste the url of the page where the user will be redirected
            upon clicking - make sure that Website is selected as type.
          </p>
        </li>
        <li>
          <p>Paste the following code at the end of the url.</p>
        </li>
        <li>
          <p>Click the Submit button.</p>
        </li>
      </ol>
      <CodeBox src={`?tt_ad_id=__CID__`} />
      <p>
        (If you already have UTMs or parameters in your tracking area, add our url parameter at the end of the url with
        an “&” sign at the beginning, instead of the question mark.)
      </p>

      <Divider />
      <StatusStripe isLoading={isIntegrationFetching} {...tiktokUrlParametersProps} />
    </CardWithTitle>
  );
};
