import React, { MouseEventHandler } from "react";

import { useGetCampaignsColumnsUtils } from "../../../hooks/campaigns/useGetCampaignsColumnsUtils";
import { TableColumn } from "../../../redux/types/table";
import { CustomCheckmark } from "../../CustomCheckmark/CustomCheckmark";
import { EcomtrackVsFbColumnHeader } from "../../EcomtrackVsFbColumnHeader/EcomtrackVsFbColumnHeader";
import { Icon } from "../../Icon/Icon";

import styles from "./TableColumnDropdownItem.module.scss";

interface TableColumnDropdownItemProps {
  col: TableColumn;
}

export const TableColumnDropdownItem: React.FC<TableColumnDropdownItemProps> = ({ col }) => {
  const { isColumnSelected, handleCheckmarkClick } = useGetCampaignsColumnsUtils();
  const isActive = isColumnSelected(col);
  const handleCheckboxClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    handleCheckmarkClick(col);
  };

  return (
    <li key={col.accessor} onClick={handleCheckboxClick} className={styles.dropdownItem}>
      <CustomCheckmark isActive={isActive} />
      <div className={styles.itemTextContainer}>
        {col.isRealColumn && <Icon name="logo_square" width="14" height="14" />}
        {col.isEcomtrackVsFbColumn ? (
          <EcomtrackVsFbColumnHeader />
        ) : (
          <p key={col.accessor} className={styles.itemText}>
            {col.name}
          </p>
        )}
      </div>
    </li>
  );
};
