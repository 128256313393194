import { useState } from "react";

import { createStripeSession } from "../../api/billing";
import { useGetCurrentWorkspaceId } from "../workspace/useGetCurrentWorkspaceId";

interface StripeSessionHandlers {
  handleClick: (id?: number | undefined) => void;
  loading: boolean;
}

export const useCreateStripeSession = (): StripeSessionHandlers => {
  const currentId = useGetCurrentWorkspaceId();
  const [loading, setLoading] = useState(false);

  const handleClick = (id?: number) => {
    let appliedId = currentId;
    // if the id is not provided, react gives the function an event object as a parameter natively. this is why is is not sufficient to check if the id exists, but it must be a number
    if (typeof id === "number") {
      appliedId = id;
    }
    setLoading(true);
    createStripeSession(appliedId);
  };

  return { handleClick, loading };
};
