import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { GetLoggedInUserResponseDTO, NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { getCurrentUserUrl } from "../get-logged-in";
import { UpdateProfilePictureResponseDTO } from "./request";

export const postUpdateProfilePicture = "user/profile-picture";

export const useUploadProfilePicture = () => {
  return useMutation(
    (image: File) => {
      const data = new FormData();
      data.append("file", image);

      return defaultMutationFn(postUpdateProfilePicture, { data, headers: { "Content-Type": "multipart/form-data" } });
    },
    {
      onMutate: () => {
        const previousData: GetLoggedInUserResponseDTO | undefined = queryClient.getQueryData([getCurrentUserUrl]);
        return previousData;
      },
      onSuccess: (data: UpdateProfilePictureResponseDTO) => {
        //optimistically set the profile pic
        queryClient.setQueryData<GetLoggedInUserResponseDTO | undefined>([getCurrentUserUrl], (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              profilePicture: data.profilePicture,
            };
          }
        });
        store.dispatch(
          showNotification({
            message: "Profile picture successfully uploaded",
            severity: NotificationSeverity.SUCCESS,
          }),
        );
      },
      onError: (_, __, context) => {
        queryClient.setQueryData<GetLoggedInUserResponseDTO | undefined>([getCurrentUserUrl], (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              profilePicture: context?.profilePicture,
            };
          }
        });
      },
    },
  );
};
