import React from "react";
import classNames from "classnames";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { getPresentableUsers } from "./helpers";

import styles from "./WorkspaceUsersTable.module.scss";

export const MobileScreenView: React.FC = () => {
  const workspace = useGetCurrentWorkspaceDetail();
  const users = workspace.data ? getPresentableUsers(workspace.data) : [];
  return (
    <ul>
      {users.map((user, i) => {
        const isLastItem: boolean = i === users.length - 1;

        const getMobileItemClasses = () => {
          const appliedMobileItemClassNameArray = [styles.mobileItem];
          if (isLastItem) {
            appliedMobileItemClassNameArray.push(styles.mobileItemLast);
          }
          return classNames(...appliedMobileItemClassNameArray);
        };

        return (
          <li className={getMobileItemClasses()} key={user.email}>
            <div className={styles.listItem}>
              <p>Name: {}</p>
            </div>
            <div className={styles.listItem}>
              <p>Email: {user.email}</p>
            </div>
            <div className={styles.listItem}>
              <p>Role: {user.role}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
