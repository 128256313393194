import { useQuery } from "react-query";

import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { MultipleIntegrationDetails } from "../../../redux/types";

export const useGetAllWorkspacesIntegrationDetails = () => {
  const currentId = useGetCurrentWorkspaceId();

  return useQuery<MultipleIntegrationDetails>([`workspace/integration-statuses`], {
    enabled: !!currentId,
  });
};
