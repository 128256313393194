import { IModal } from "../../redux/types";
import { useCheckPaymentStatus } from "../billing/useCheckPaymentStatus";
import { useLoadCampaigns } from "../campaigns/useLoadCampaigns";
import { useLoadSelectedCampaignColumns } from "../campaigns/useLoadSelectedCampaignColumns";
import { useModal } from "../modal/useModal";
import { useLiveAgentScript } from "../user/useLiveAgentScript";

export const useLoadMainPage = (): IModal => {
  const overLimitModal = useModal();
  useLoadCampaigns();
  useLoadSelectedCampaignColumns();

  useLiveAgentScript();
  useCheckPaymentStatus(overLimitModal);

  return overLimitModal;
};
