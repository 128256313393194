import React from "react";

import { Modal } from "../../components/Modal/Modal";
import { IModal } from "../../redux/types";

import styles from "./VideoModal.module.scss";

interface VideoModalProps extends IModal {
  videoUrlString: string;
}

export const VideoModal: React.FC<VideoModalProps> = ({ visible, toggle, videoUrlString }) => {
  return (
    <Modal className={styles.modal} visible={visible} toggle={toggle} animation="slideUp">
      <iframe
        src={`https://player.vimeo.com/video/${videoUrlString}`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        className={styles.iframe}
      />
    </Modal>
  );
};
