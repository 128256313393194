//We do this in through queryClient so we can have more flexibility and call it directly like this
//while also passing the id as a parameter.
import { loadStripe } from "@stripe/stripe-js";

import { setStripeRequestSent } from "../../../redux/reducers/appReducer";
import { store } from "../../../redux/store";
import { defaultMutationFn, queryClient } from "../../api";
import { CreateNewSubscriptionResponseDTO } from "./dto";

export const createStripeSession = async (id: number) => {
  const startSession = async () => {
    store.dispatch(setStripeRequestSent(true));

    function getClientReferenceId() {
      //@ts-ignore
      return window.Rewardful?.referral || "checkout_" + new Date().getTime();
    }

    const stripePromise = await loadStripe(
      process.env.REACT_APP_STRIPE_KEY ?? "",
    );

    await queryClient.executeMutation<CreateNewSubscriptionResponseDTO>({
      mutationFn: () => {
        const url = `${window.location.origin}/dashboard`;
        return defaultMutationFn("billing/subscription/creation-session", {
          data: {
            workspaceId: id,
            successUrl: url,
            cancelUrl: url,
            clientReferenceId: getClientReferenceId(),
          },
        });
      },
      onSuccess: (data) => {
        if (stripePromise) {
          stripePromise.redirectToCheckout({
            sessionId: data.sessionId,
          });
        }
      },
      onError: () => {
        window.location.href = "/dashboard";
      },
    });
  };

  await startSession();
};
