import React from "react";
import classNames from "classnames";

import { Icon } from "../../../components/Icon/Icon";
import { useGetBreakpointProperties } from "../../../hooks/screen/useGetBreakpointProperties";

import styles from "./Overview.module.scss";

interface OverviewRowProps {
  change?: number;
  icon: string;
  title: string;
  subtitle?: string;
}

interface ChangeProperties {
  appliedChangeClassNames: string;
  appliedIconName: string;
}

export const OverviewRow: React.FC<OverviewRowProps> = ({ icon, title, subtitle, change }) => {
  const { isMobile } = useGetBreakpointProperties();
  const getAppliedChangeProperties = (): ChangeProperties => {
    const appliedChangeClassNamesArray = [styles.change];
    let appliedIconName = "arrow-gray";

    if (change === 0) {
      appliedChangeClassNamesArray.push(styles.neutral);
    }

    if (typeof change === "number" && change > 0) {
      appliedChangeClassNamesArray.push(styles.increase);
      appliedIconName = "arrow-green";
    }

    if (typeof change === "number" && change < 0) {
      appliedChangeClassNamesArray.push(styles.decrease);
      appliedIconName = "arrow-red";
    }

    const appliedChangeClassNames = classNames(...appliedChangeClassNamesArray);
    return { appliedChangeClassNames, appliedIconName };
  };
  const { appliedChangeClassNames, appliedIconName } = getAppliedChangeProperties();

  const getPercentageIncreaseString = (): string => {
    let percentageIncreaseString = "";
    const mainString = `${change?.toFixed(2)}%`;

    if (typeof change === "number" && change >= 0) {
      percentageIncreaseString = "+";
    }

    percentageIncreaseString += mainString;

    return percentageIncreaseString;
  };
  const percentageIncreaseString = getPercentageIncreaseString();

  return (
    <div className={styles.row}>
      <div className={styles.left}>
        <Icon name={icon} />
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={styles.right}>
        <div className={appliedChangeClassNames}>
          {typeof change === "number" && (
            <>
              {!isMobile && <Icon name={appliedIconName} width="13px" />}
              <p>{percentageIncreaseString}</p>
            </>
          )}
        </div>
        <p className={styles.title}>{title}</p>
      </div>
    </div>
  );
};
