import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import { hideNotification } from "../../redux/reducers/notificationReducer";
import { getNotification } from "../../redux/selectors/notification";
import { Icon } from "../Icon/Icon";

import styles from "./Notification.module.scss";

export const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const notification = useSelector(getNotification);
  const closeNotification = () => {
    dispatch(hideNotification());
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (notification.open) {
        dispatch(hideNotification());
      }
    }, notification.hideAfter);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [dispatch, notification.hideAfter, notification.open, notification.message]);

  const animation = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      x: 0,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 400,
      },
    },
    exit: {
      opacity: 0,
      scale: 1,
      x: 350,
      y: 0,
      transition: {
        type: "spring",
        damping: 30,
        stiffness: 200,
      },
    },
  };

  return (
    <AnimatePresence initial>
      <motion.div
        variants={animation}
        initial="hidden"
        animate={notification.open ? "visible" : "exit"}
        exit="exit"
        className={`${styles.notification} ${styles[notification.severity]}`}
        onClick={closeNotification}>
        {notification.icon && <Icon name={notification.icon} />}
        <div className={styles.text}>{notification.message}</div>
      </motion.div>
    </AnimatePresence>
  );
};
