import React from "react";

import styles from "./SlideShow.module.scss";

interface SlideProps {
  img: string;
  title: string;
  body: string;
  style?: React.CSSProperties;
}

export const Slide = ({ img, title, body, style }: SlideProps) => {
  return (
    <div className={styles.slide} style={style}>
      <img className={styles.img} src={img} alt="Slide banner image with promotion" />
      <div className={styles.text}>
        <h2>{title}</h2>
        <span>{body}</span>
      </div>
    </div>
  );
};
