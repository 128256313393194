import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useHandleClickOutside } from "../../hooks/screen/useHandleClickOutside";
import { getAllCampaignCols, getTemporaryCampaignCols } from "../../redux/selectors/campaigns";
import { ChevronIcon } from "../Icon/ChevronIcon/ChevronIcon";
import { Icon } from "../Icon/Icon";
import { TableColumnDropdown } from "./TableColumnDropdown/TableColumnDropdown";

import styles from "./TableColumnSelect.module.scss";

export const TableColumnSelect: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const campaignsTableCols = useSelector(getAllCampaignCols);
  const temporarySelectedCols = useSelector(getTemporaryCampaignCols);
  const isEverythingSelected = campaignsTableCols.length === temporarySelectedCols.length;

  const handleClick = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };
  const ref = useHandleClickOutside(handleClick);
  const handleOpenDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={styles.container}>
      <p className={styles.text}>Columns</p>
      <div className={styles.selectContainer} onClick={handleOpenDropdown} ref={ref}>
        <div className={styles.wrapper}>
          <Icon name="ico-columns" width="23px" />
          <p className={styles.selectText}>{isEverythingSelected ? "Default" : "Custom"}</p>
        </div>
        <ChevronIcon isActive={isDropdownOpen} />
        {isDropdownOpen && <TableColumnDropdown />}
      </div>
    </div>
  );
};
