import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";

import { showNotification } from "../../redux/reducers/notificationReducer";
import { NotificationSeverity } from "../../redux/types/notification";

import styles from "./CodeBox.module.scss";

type CodeBoxProps = {
  children?: ReactNode;
  src: string;
};

export const CodeBox: React.FC<CodeBoxProps> = ({ children, src }) => {
  const contentToDisplay = children ?? src;
  const dispatch = useDispatch();

  const handleCopy = () => {
    navigator.clipboard.writeText(src);
    dispatch(
      showNotification({
        message: "Code was copied to clipboard",
        severity: NotificationSeverity.SUCCESS,
        hideAfter: 2250,
        icon: "clipboard",
      }),
    );
  };

  return (
    <div className={styles.codeBox} onClick={handleCopy}>
      <code className={styles.code}>{contentToDisplay}</code>
    </div>
  );
};
