import React from "react";

import { Container } from "../../../components/Container/Container";
import { WorkspaceForm, WorkspaceFormType } from "../../../forms/WorkspaceForm/WorkspaceForm";

import styles from "../WorkspaceSetupPage.module.scss";

export const WorkspaceSettings: React.FC = () => {
  return (
    <Container noFlex>
      <div className={styles.title}>Settings</div>
      <WorkspaceForm type={WorkspaceFormType.UPDATE} />
    </Container>
  );
};
