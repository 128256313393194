import { useSelector } from "react-redux";

import { getAppBreakpointState } from "../../redux/selectors/app";
import { ApplicationBreakpoint } from "../../redux/types";

interface BreakpointProperties {
  appBreakpoint: ApplicationBreakpoint;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
}

export const useGetBreakpointProperties = (): BreakpointProperties => {
  const appBreakpoint = useSelector(getAppBreakpointState);
  const isMobile = appBreakpoint === ApplicationBreakpoint.mobile;
  const isTablet = appBreakpoint === ApplicationBreakpoint.tablet;
  const isDesktop = appBreakpoint === ApplicationBreakpoint.desktop;
  const isLargeDesktop = appBreakpoint === ApplicationBreakpoint.large;

  const breakpointProperties: BreakpointProperties = {
    appBreakpoint,
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
  };

  return breakpointProperties;
};
