import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";

interface DatePickerStaticRange {
  label: string;
  range: () => {
    startDate: Date;
    endDate: Date;
  };
  isSelected(range: any): boolean;
}

export const useGetDatePickerStaticRanges = (): DatePickerStaticRange[] => {
  const dates = {
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLast7Days: subDays(new Date(), 6),
    endOfToday: endOfDay(new Date()),
    startOfLast14Day: subDays(new Date(), 13),
    startOfLast30Day: subDays(new Date(), 29),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  };

  const datePickerStaticRanges: DatePickerStaticRange[] = [
    {
      label: "Today",
      range: () => ({
        startDate: dates.startOfToday,
        endDate: dates.endOfToday,
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
      },
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: dates.startOfYesterday,
        endDate: dates.endOfYesterday,
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
      },
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: dates.startOfLast7Days,
        endDate: dates.endOfToday,
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
      },
    },
    {
      label: "Last 14 Days",
      range: () => ({
        startDate: dates.startOfLast14Day,
        endDate: dates.endOfToday,
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
      },
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: dates.startOfLast30Day,
        endDate: dates.endOfToday,
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
      },
    },
    {
      label: "This Month",
      range: () => ({
        startDate: dates.startOfMonth,
        endDate: dates.endOfMonth,
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
      },
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: dates.startOfLastMonth,
        endDate: dates.endOfLastMonth,
      }),
      isSelected(range: any) {
        const definedRange = this.range();
        return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
      },
    },
  ];

  return datePickerStaticRanges;
};
