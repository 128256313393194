import React from "react";

import { Container } from "../../../components/Container/Container";
import { Image } from "../../../components/Image/Image";

import styles from "./BannerCard.module.scss";

export interface BannerCardProps {
  title: string;
  text: string;
  imageName: string;
  linkText?: string;
  onLinkClick?: () => void;
}

export const BannerCard: React.FC<BannerCardProps> = ({ title, text, linkText, onLinkClick, imageName }) => {
  return (
    <Container className={styles.bannerCard}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.top}>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.text}>{text}</p>
          </div>
          {linkText && (
            <p className={styles.linkText} onClick={onLinkClick}>
              {linkText}
            </p>
          )}
        </div>
        <Image src={imageName} alt={imageName} height="128px" />
      </div>
    </Container>
  );
};
