import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAppBreakpoint } from "../../redux/reducers/appReducer";
import { getAppBreakpointState } from "../../redux/selectors/app";
import { ApplicationBreakpoint } from "../../redux/types";

export const useResize = (): void => {
  const appBreakpoint = useSelector(getAppBreakpointState);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = (): void => {
      let resultAppBreakPoint = ApplicationBreakpoint.tablet;

      if (window.innerWidth <= 480) {
        resultAppBreakPoint = ApplicationBreakpoint.mobile;
      }
      if (window.innerWidth > 1200) {
        resultAppBreakPoint = ApplicationBreakpoint.desktop;
      }
      if (window.innerWidth > 1500) {
        resultAppBreakPoint = ApplicationBreakpoint.large;
      }

      if (appBreakpoint !== resultAppBreakPoint) {
        dispatch(setAppBreakpoint(resultAppBreakPoint));
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch, appBreakpoint]);
};
