import { useEffect } from "react";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { IModal, SubscriptionStatus } from "../../redux/types";
import { useGetCurrentWorkspaceId } from "../workspace/useGetCurrentWorkspaceId";

export const useCheckPaymentStatus = (overLimitModal: IModal): void => {
  const workspace = useGetCurrentWorkspaceDetail();
  const currentId = useGetCurrentWorkspaceId();
  const subStatus = workspace.data?.subscriptionStatus;

  const checkPaymentStatus = () => {
    if (!currentId) {
      return;
    }
    if (subStatus === SubscriptionStatus.Unapproved) {
      overLimitModal.toggle(true);
    }
  };

  useEffect(() => {
    checkPaymentStatus();

    return () => {
      overLimitModal.toggle(false);
    };
  }, [workspace.data?.subscriptionStatus, currentId]);
};
