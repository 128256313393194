import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { CurrencyType } from "../../redux/types";
import { getFormattedPrice } from "../../utils/formatters/currencyFormatters";

type GetCurrencyTypeCellValue = (value: number) => string;

export const useGetCurrencyTypeCellValue = (): GetCurrencyTypeCellValue => {
  const currentWorkspace = useGetCurrentWorkspaceDetail();
  return (value): string =>
    getFormattedPrice(Math.round(value * 100) / 100, currentWorkspace.data?.currency ?? CurrencyType.USD) ?? "-";
};
