import React from "react";

import { useGetPricingPlanInfoForCurrent } from "../../../../api/billing";
import { useGetCurrentWorkspaceDetail } from "../../../../api/workspace";
import { getFormattedPrice } from "../../../../utils/formatters/currencyFormatters";

import styles from "../AlertBanner.module.scss";

export const AlertBannerTextComponentUnapproved: React.FC = () => {
  const { data } = useGetPricingPlanInfoForCurrent();
  const currentWorkspace = useGetCurrentWorkspaceDetail();
  const workspace = data;
  const currency = workspace?.currency;
  const workspaceName = currentWorkspace.data?.name;
  const currentRevenue = getFormattedPrice(workspace?.currentRevenue ?? 0, currency);
  const nextPrice = getFormattedPrice(workspace?.next?.price ?? 0, currency);
  const nextRevenueLimit = getFormattedPrice(workspace?.next?.revenueMax ?? 0, currency);
  const maxLevel = workspace?.now?.revenueMax === 0;

  return (
    <p className={styles.text}>
      {workspaceName} is doing really well and it has just hit {currentRevenue} in revenue. Upgrade your plan to keep
      the workspace tracked. Next plan lets you track{" "}
      {maxLevel ? (
        "an unlimited amount"
      ) : (
        <>
          up to <span className={styles.bold}>{nextRevenueLimit}</span>
        </>
      )}{" "}
      in ad revenue for <span className={styles.bold}>just {nextPrice} a month</span>.
    </p>
  );
};
