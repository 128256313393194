import React from "react";

import { IMenuItem, Menu } from "../../components/Menu/Menu";
import { WorkspaceRoleType } from "../../redux/types/workspace";

export const UserSettingsMenu = () => {
  const userSettingsMenuItems: IMenuItem[] = [
    {
      to: "/user/settings/personal-settings",
      text: "Personal Settings",
      iconName: "ico-person",
    },
    {
      to: "/user/settings/change-password",
      text: "Password",
      iconName: "ico-lock",
    },
    {
      to: "/user/settings/workspace-manager",
      text: "Workspace Manager",
      iconName: "ico-workspaces",
      role: WorkspaceRoleType.ADMIN,
    },
  ];

  return <Menu menuItems={userSettingsMenuItems} />;
};
