import { IntegrationEventBlock, IntegrationStatus } from "../redux/types";

type StatusStripeMessage = string | undefined;

export interface StatusStripeProperties extends StatusBanner {
  title: string;
  messages: StatusStripeMessage[];
}
interface StatusBanner {
  text: string;
  status: IntegrationStatus;
}

export const getStatusStripeProperties = (
  eventDataArray: (IntegrationEventBlock | undefined)[],
  adAccountId?: string,
): StatusStripeProperties => {
  const getDisplayedStatusBanner = (): StatusBanner => {
    const isEverythingSuccess = !eventDataArray.some((event) => event?.status !== IntegrationStatus.success);
    const isSomeError = eventDataArray.some((event) => event?.status === IntegrationStatus.error);
    let text = "No data";
    let displayedStatus = IntegrationStatus.warning;

    if (isEverythingSuccess) {
      text = "Successfully connected";
      displayedStatus = IntegrationStatus.success;
    }
    if (isSomeError) {
      text = "Error";
      displayedStatus = IntegrationStatus.error;
    }
    const statusBanner: StatusBanner = { text, status: displayedStatus };

    return statusBanner;
  };
  const { text, status } = getDisplayedStatusBanner();

  const getTitle = () => {
    let title = "Status";
    if (adAccountId && adAccountId !== "act_null") {
      title += ` (${adAccountId})`;
    }

    return title;
  };
  const title = getTitle();
  const messages = eventDataArray.map((event) => event?.message);
  const statusStripeProperties: StatusStripeProperties = { status, text, title, messages };

  return statusStripeProperties;
};
