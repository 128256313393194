import React, { CSSProperties } from "react";

import styles from "./Divider.module.scss";

export interface DividerProps {
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
}

export const Divider: React.FC<DividerProps> = ({ marginTop, marginBottom, marginLeft, marginRight }) => {
  const getAppliedStyles = () => {
    let appliedStyles: CSSProperties = { marginTop, marginBottom, marginLeft, marginRight };

    if (marginLeft && marginRight) {
      const widthAddition = `${Math.abs(marginLeft) + Math.abs(marginRight)}px`;
      appliedStyles = { ...appliedStyles, width: `calc(100% + ${widthAddition})` };
    }

    return appliedStyles;
  };

  return <div className={styles.divider} style={getAppliedStyles()} />;
};
