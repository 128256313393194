import React from "react";

import { TableHeaderRowProps } from "../../../redux/types/table";
import { TableHeaderCell } from "./TableHeaderCell";

export const TableHeaderRow: React.FC<TableHeaderRowProps> = ({ headerGroup, ...props }) => (
  <tr {...headerGroup.getHeaderGroupProps()}>
    {headerGroup.headers.map((column: any) => {
      return <TableHeaderCell column={column} headerGroup={headerGroup} {...props} key={column.id} />;
    })}
  </tr>
);
