import React from "react";

import styles from "./ProfilePicture.module.scss";

export interface ProfilePictureProps {
  size?: number;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
}
export const ProfilePicture: React.FC<ProfilePictureProps> = ({ size, firstName, lastName, profilePictureUrl }) => {
  const appliedProfilePictureStyles = { width: size ?? "", minWidth: size ?? "", height: size ?? "" };
  const appliedDummyImageStyles = { width: size ?? "", height: size ?? "" };
  const getInitials = () => {
    return firstName.substring(0, 1).concat(lastName.substring(0, 1)) || "ZZ";
  };

  return (
    <div className={styles.profilePicture} style={appliedProfilePictureStyles}>
      {profilePictureUrl ? (
        <img src={profilePictureUrl} className={styles.profileImage} alt={getInitials()} />
      ) : (
        <div className={styles.dummyImage} style={appliedDummyImageStyles}>
          <p>{getInitials()}</p>
        </div>
      )}
    </div>
  );
};
