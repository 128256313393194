import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { CreateNewWorkspaceRequestDTO } from "../../api/workspace/create-new/dto";
import { SelectOption } from "../../components/Select/Select";
import { WorkspaceFormType } from "../../forms/WorkspaceForm/WorkspaceForm";
import { CurrencyType } from "../../redux/types";
import { getCurrencyOption } from "../../utils/formatters/currencyFormatters";
import { useGetCurrentWorkspaceId } from "../workspace/useGetCurrentWorkspaceId";

export const usePrepareWorkspaceForm = (type: WorkspaceFormType, prefilledData?: CreateNewWorkspaceRequestDTO) => {
  const workspaceId = useGetCurrentWorkspaceId();
  const workspace = useGetCurrentWorkspaceDetail();
  const isCreateForm = type === WorkspaceFormType.CREATE;
  const isUpdateForm = type === WorkspaceFormType.UPDATE;
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    prefilledData?.timeZone ?? workspace.data?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [selectedCurrency, setSelectedCurrency] = useState<SelectOption>(
    getCurrencyOption((prefilledData?.currency as any) ?? workspace.data?.currency ?? CurrencyType.USD),
  );
  const formHandler = useForm<CreateNewWorkspaceRequestDTO>({ reValidateMode: "onSubmit" });
  const { handleSubmit, setValue } = formHandler;

  useEffect(() => {
    if (isUpdateForm && workspace.data) {
      setSelectedTimezone(workspace.data?.timeZone);
      setSelectedCurrency(getCurrencyOption(workspace.data.currency));
      setValue("domain", workspace.data.domain);
      setValue("name", workspace.data.name);
    }
  }, [workspaceId, workspace.data]);

  return {
    selectedTimezone,
    setSelectedTimezone,
    selectedCurrency,
    setSelectedCurrency,
    handleSubmit,
    formHandler,
    isCreateForm,
    isUpdateForm,
  };
};
