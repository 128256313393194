import { useGetLoggedInUser } from "../../api/user";
import { WorkspaceRoleType } from "../../redux/types";
import { useGetCurrentWorkspaceId } from "../workspace/useGetCurrentWorkspaceId";

export const useGetUserRole = (): WorkspaceRoleType => {
  const workspaceId = useGetCurrentWorkspaceId();
  const user = useGetLoggedInUser();
  return user.data?.roles.find((role) => role.workspace.id === workspaceId)?.role || WorkspaceRoleType.CLIENT;
};

export const isAllowed = (role: WorkspaceRoleType, allowedRoles: WorkspaceRoleType[]): boolean => {
  return allowedRoles.includes(role);
};
