import React, { ReactNode } from "react";

import { Icon } from "../Icon/Icon";

import styles from "./ComplexHeader.module.scss";

interface ComplexHeaderProps {
  iconName: string;
  title: string;
  subtitle: string;
  rightComponent?: ReactNode;
}

export const ComplexHeader: React.FC<ComplexHeaderProps> = ({ iconName, title, subtitle, rightComponent }) => {
  return (
    <div className={styles.integrationHeader}>
      <div className={styles.leftSegment}>
        <Icon name={iconName} width="32" height="32" />
        <div className={styles.text}>
          <span className={styles.title}>{title}</span>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      </div>
      <div className={styles.rightSegment}>{rightComponent}</div>
    </div>
  );
};
