import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useUpdateWorkspaceMutation } from "../../api/workspace";
import { Button, ButtonVariant } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { platformObjects } from "../../containers/IntegrationPage/IntegrationContent/EcommercePlatforms";
import { useGetSelectedEcommercePlatformObject } from "../../hooks/integration/useGetSelectedEcommercePlatformObject";
import { useGetModalGridStyles } from "../../hooks/modal/useGetModalGridStyles";
import { getWorkspaceId } from "../../redux/selectors/workspace";
import { EcommercePlatformObject, IModal } from "../../redux/types";
import { ProviderItemComponent } from "./ProviderItemComponent";

import styles from "./ChangeProviderModal.module.scss";

type ChangeProviderModalProps = IModal;

export const ChangeProviderModal: React.FC<ChangeProviderModalProps> = ({ visible, toggle }) => {
  const workspaceId = useSelector(getWorkspaceId);
  const { mutate: mutateWorkspace, isLoading } = useUpdateWorkspaceMutation();
  const selectedPlatformObject = useGetSelectedEcommercePlatformObject();
  const selectedPlatformName = selectedPlatformObject.platformName;
  const platformNames = platformObjects.map((platform) => platform.platformName);
  const [temporarilySelected, setTemporarilySelected] =
    useState<EcommercePlatformObject["platformName"]>(selectedPlatformName);

  const handleSave = () => {
    if (workspaceId) {
      mutateWorkspace(
        {
          eCommercePlatform: temporarilySelected,
          workspaceId,
        },
        {
          onSuccess: () => {
            toggle();
          },
        },
      );
    }
  };

  useEffect(() => {
    setTemporarilySelected(selectedPlatformName);
  }, [selectedPlatformName]);

  const appliedModalGridStyles = useGetModalGridStyles(platformNames);

  return (
    <Modal visible={visible} toggle={toggle} animation="fadeIn" fitContent className={styles.selectProviderModal}>
      <div className={styles.content}>
        <h3 className={styles.header}>Select e-commerce platform</h3>
        <p className={styles.text}>
          {
            "You can select only one platform. We currently support Shopify, MyCashFlow, WooCommerce, and Custom platforms. Any other platforms you'd want us to add? "
          }
          <a href="https://ecomtrack.io/contact" target="_blank" rel="noreferrer" className={styles.link}>
            Tell us.
          </a>
        </p>
        <div className={styles.selectProviderContainer} style={appliedModalGridStyles}>
          {platformNames.map((platformName) => (
            <ProviderItemComponent
              key={platformName}
              platformName={platformName}
              temporarilySelected={temporarilySelected}
              setTemporarilySelected={setTemporarilySelected}
            />
          ))}
        </div>
        <div className={styles.btn}>
          <Button
            variant={ButtonVariant.PRIMARY}
            label="Save my choice"
            onClick={handleSave}
            loading={isLoading}
            fullWidth
          />
        </div>
      </div>
    </Modal>
  );
};
