import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateCampaignsCols } from "../../redux/reducers/campaignReducer";
import { getAllCampaignCols } from "../../redux/selectors/campaigns";
import { CampaignColsEnum, ICampaignColsInput } from "../../redux/types";
import { TableColumn } from "../../redux/types/table";
import { updateLocalStorageCampaingsColumns } from "../../utils/localStorageHandlers";
import { useLoadAllCampaignsColumns } from "./useLoadAllCampaignsColumns";

type SelectedCampaignsColumnsLocalStorageValue = string[] | undefined;

export const useLoadSelectedCampaignColumns = (): void => {
  const campaignsTableCols = useSelector(getAllCampaignCols);

  useLoadAllCampaignsColumns();

  const dispatch = useDispatch();

  const getSelectedCampaignsColumnsFromLocalStorage = (): SelectedCampaignsColumnsLocalStorageValue => {
    const localStorageValue = localStorage.getItem("selectedCampaignsColumns");

    return localStorageValue ? JSON.parse(localStorageValue) : undefined;
  };

  const localStorageValue = getSelectedCampaignsColumnsFromLocalStorage();

  useEffect(() => {
    if (localStorageValue && localStorageValue.length) {
      const temporarySelectedColumns = localStorageValue.map((localColumn) => {
        const fullColumnObject = campaignsTableCols.find((column) => column.accessor === localColumn);

        return fullColumnObject as TableColumn;
      });

      const colsInput: ICampaignColsInput = {
        cols: temporarySelectedColumns,
        type: CampaignColsEnum.TEMPORARILY_SELECTED,
      };

      dispatch(updateCampaignsCols(colsInput));
      return;
    }
    updateLocalStorageCampaingsColumns(campaignsTableCols);
  }, [campaignsTableCols, localStorageValue]);
};
