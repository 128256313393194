import { useGetIntegrationDetails } from "../../api/workspace";
import { getStatusStripeProperties, StatusStripeProperties } from "../../utils/getStatusStripeProperties";

interface EcommerceStatusStripeProperties {
  commonUrlParametersProps: StatusStripeProperties;
  commonConversionParametersProps: StatusStripeProperties;
  combinedParametersProps: StatusStripeProperties;
}

export const useGetEcommerceStatusStripeProperties = (): EcommerceStatusStripeProperties => {
  const { data } = useGetIntegrationDetails();
  const pageView = data?.ecommerceProvidersData.pageView;
  const conversion = data?.ecommerceProvidersData.conversion;
  const commonUrlParametersProps = getStatusStripeProperties([pageView]);
  const commonConversionParametersProps = getStatusStripeProperties([conversion]);
  const combinedParametersProps = getStatusStripeProperties([pageView, conversion]);

  const ecommerceStatusStripeProperties = {
    commonUrlParametersProps,
    commonConversionParametersProps,
    combinedParametersProps,
  };

  return ecommerceStatusStripeProperties;
};
