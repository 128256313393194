import { IntegrationDetails, Workspace } from "../redux/types";
import { getAdProvidersIntegrationStates } from "./getAdProvidersIntegrationStates";
import { getEcommerceProviderIntegrationStates } from "./getEcommerceProviderIntegrationStates";
import { getSelectedAdProviders } from "./getSelectedAdProviders";

export const isGlobalIntegrationError = (
  integrationDetailsData: IntegrationDetails | undefined,
  workspaceDetailData: Workspace | undefined,
) => {
  const selectedAdProviders = getSelectedAdProviders(workspaceDetailData);
  const { isGlobalEcommerceProviderError } = getEcommerceProviderIntegrationStates(integrationDetailsData);
  const { isGlobalAdProvidersError } = getAdProvidersIntegrationStates(integrationDetailsData, selectedAdProviders);
  return Boolean(isGlobalEcommerceProviderError || isGlobalAdProvidersError);
};
