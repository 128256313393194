import React from "react";

import { WorkspaceForm, WorkspaceFormType } from "../../forms/WorkspaceForm/WorkspaceForm";

import styles from "./AddWorkspacePage.module.scss";

export const AddWorkspaceComponent: React.FC = () => {
  return (
    <div className={styles.content}>
      <div>
        <h1 className={styles.title}>Add Workspace</h1>
        <p className={styles.subtitle}>Create a new workspace</p>
      </div>
      <WorkspaceForm type={WorkspaceFormType.CREATE} />
    </div>
  );
};
