import React from "react";

import { useGetCampaignsMobileProperties } from "../../../hooks/campaigns/useGetCampaignsMobileProperties";
import { ExtendedCampaign } from "../../../redux/types";
import { CampaignRow } from "./CampaignRow";
import { ExpandedCampaignRow } from "./ExpandedCampaignRow";

interface CampaignRowProps {
  campaign: ExtendedCampaign;
  index: number;
}

export const CampaignItem: React.FC<CampaignRowProps> = ({ campaign, index }) => {
  const { handleExpand, campaignName, isLastItem, subRowArray } = useGetCampaignsMobileProperties(campaign, index);

  return campaign.expanded ? (
    <ExpandedCampaignRow handleExpand={handleExpand} campaignName={campaignName} campaign={campaign} />
  ) : (
    <CampaignRow isLastItem={isLastItem} handleExpand={handleExpand} subRowArray={subRowArray} campaign={campaign} />
  );
};
