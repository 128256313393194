import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { Input, InputStyle } from "../../components/Input/Input";
import { UserInput } from "./PersonalSettingsForm";

interface FirstNameInputComponentProps {
  formHandler: UseFormReturn<UserInput, any>;
}

export const FirstNameInputComponent: React.FC<FirstNameInputComponentProps> = ({ formHandler }) => {
  const registerFirstNameOptions = {
    required: "This field is required",
    pattern: { value: /\S/, message: "Name cannot be empty" },
  };

  const {
    register,
    formState: { errors },
  } = formHandler;

  return (
    <div>
      <Input
        label="Fist Name*"
        type="text"
        error={!!errors.firstName}
        {...register("firstName", registerFirstNameOptions)}
        inputStyle={InputStyle.VERTICAL}
        icon="user"
      />
      <ErrorMessage
        errors={errors}
        name="firstName"
        render={({ message }) => (
          <span role="alert" className="errorMessage">
            {message}
          </span>
        )}
      />
    </div>
  );
};
