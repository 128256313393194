import React from "react";

import { ConfirmationModal } from "../../modals/ConfirmationModal/ConfirmationModal";
import { IModal } from "../../redux/types/app";

interface ConfirmationModalWrapperProps {
  confirmation: IModal;
  deleteWorkspace: (id: number) => void;
  selectedId: number | undefined;
}

export const ConfirmationModalWrapper: React.FC<ConfirmationModalWrapperProps> = ({
  confirmation,
  deleteWorkspace,
  selectedId,
}) => {
  const handleConfirm = () => {
    if (selectedId) {
      deleteWorkspace(selectedId);
    }
  };

  return (
    <ConfirmationModal
      visible={confirmation.visible}
      toggle={confirmation.toggle}
      onConfirm={handleConfirm}
      title="Delete workspace"
      onCancelText="Continue tracking"
      onConfirmText="Delete workspace">
      <p>
        {` Do you insist on deleting this workspace? If yes, proceed to continue. You won't be charged for this anymore. The workspace will disappear once your subscription period ends.`}
      </p>
    </ConfirmationModal>
  );
};
