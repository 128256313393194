import React from "react";
import { UseMutationResult } from "react-query";
import classNames from "classnames";

import { queryClient } from "../../../api/api";
import {
  getIntegrationDetailUrl,
  useGetAllWorkspacesIntegrationDetails,
  useGetWorkspaceDetailMutation,
} from "../../../api/workspace";
import { isAllowed, useGetUserRole } from "../../../hooks/user/useGetUserRole";
import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { Workspace, WorkspaceRoleType } from "../../../redux/types";
import { getDaysDifferenceString } from "../../../utils/formatters/dateFormatters";
import { isDangerSubscriptionStatus } from "../../../utils/isDangerSubscriptionStatus";
import { isGlobalIntegrationError } from "../../../utils/isGlobalIntegrationError";
import { Icon } from "../../Icon/Icon";

import styles from "./WorkspaceSwitchCard.module.scss";

interface WorkspaceSwitchCardProps {
  workspace: Workspace;
  onClose?: () => void;
  //We need the mutation passed down from Header in order to correctly show a loading spinner there
  getWorkspace?: UseMutationResult<Workspace, unknown, number, unknown>;
}

export const WorkspaceSwitchCard: React.FC<WorkspaceSwitchCardProps> = ({ workspace, onClose, getWorkspace }) => {
  const toBeDeletedAt = workspace.toBeDeletedAt as Date;
  const daysDifferenceString = getDaysDifferenceString(toBeDeletedAt).daysDifferenceFullString;
  const currentWorkspaceId = useGetCurrentWorkspaceId();
  const getWorkspaceDetail = useGetWorkspaceDetailMutation();
  const mutationHandler = getWorkspace ?? getWorkspaceDetail;
  const isCurrentWorkspace = workspace.id === currentWorkspaceId;
  const workspaceName: string = workspace.name ?? `Workspace ${workspace.id}`;
  const userRole = useGetUserRole();
  const { data: allIntegrationDetailsData } = useGetAllWorkspacesIntegrationDetails();
  const thisWorkspaceIntegrationDetail = allIntegrationDetailsData
    ? allIntegrationDetailsData[workspace.id]
    : undefined;
  const hasErrorInfo =
    isGlobalIntegrationError(thisWorkspaceIntegrationDetail, workspace) ||
    isDangerSubscriptionStatus(workspace.subscriptionStatus);

  const handleWorkspaceSelect = (id: number) => {
    const prefetchWorkspaceQuery = () => {
      if (isAllowed(userRole, [WorkspaceRoleType.ADMIN]))
        //prefetch integration details on workspace change
        queryClient.prefetchQuery(getIntegrationDetailUrl(id));
    };

    mutationHandler.mutate(id, {
      onSuccess: prefetchWorkspaceQuery,
    });
    if (onClose) onClose();
  };

  const getAppliedCardClassName = () => {
    const appliedCardClassNameArray = [styles.card];
    if (isCurrentWorkspace) {
      appliedCardClassNameArray.push(styles.currentWorkspace);
    }
    if (!isCurrentWorkspace) {
      appliedCardClassNameArray.push(styles.notCurrentWorkspace);
    }

    return classNames(...appliedCardClassNameArray);
  };

  return (
    <div className={getAppliedCardClassName()} onClick={() => handleWorkspaceSelect(workspace.id)} key={workspace.id}>
      <div className={styles.content}>
        <span className={styles.name}>{workspaceName}</span>
      </div>
      {toBeDeletedAt ? (
        <span className={styles.expiredWorkspace}>{daysDifferenceString}</span>
      ) : (
        <div className={styles.border} />
      )}
      {hasErrorInfo && <Icon name="ico-hint-red" width="16" className={styles.errorIcon} />}
    </div>
  );
};
