import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { useHandleClickOutside } from "../../hooks/screen/useHandleClickOutside";
import { Icon } from "../Icon/Icon";

import styles from "./TableDropdown.module.scss";

interface TableDropdownProps {
  items: DropdownOptions[];
}

export interface DropdownOptions {
  name: string;
  icon: string;
  color?: string;
  onClick: () => void;
}
export const TableDropdown: React.FC<TableDropdownProps> = ({ items }) => {
  const [visible, setVisible] = useState(false);
  const handleClickOutside = () => setVisible(false);
  const ref = useHandleClickOutside(handleClickOutside);

  return (
    <div className={styles.wrapper} ref={ref}>
      <Icon name="ico_dots" height="14px" onClick={() => setVisible((prev) => !prev)} />

      <AnimatePresence>
        {visible && (
          <motion.div variants={fadeIn} initial="hidden" animate="visible" exit="exit" className={styles.z}>
            <div className={styles.tableDropdown.concat(" ", styles.z)}>
              {items.map((item) => {
                const handleClick = () => {
                  item.onClick();
                  setVisible(false);
                };

                const appliedTextColor = item.color ?? "";

                return (
                  <div
                    className={styles.menuItem}
                    key={item.name}
                    style={{ color: appliedTextColor }}
                    onClick={handleClick}>
                    <Icon name={item.icon} />
                    {item.name}
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "ease",
    },
    "will-change": "opacity",
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      type: "ease",
    },
    "will-change": "opacity",
  },
};
