import React from "react";
import { useSelector } from "react-redux";

import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { getHeaderTitle } from "../../redux/selectors/app";

import styles from "./PageContainer.module.scss";

interface PageContainerProps {
  children: React.ReactNode;
}

export const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  const { isMobile } = useGetBreakpointProperties();
  const headerTitle = useSelector(getHeaderTitle);

  return (
    <div className={styles.pageContainer}>
      {isMobile && <h2 className={styles.title}>{headerTitle}</h2>}
      {children}
    </div>
  );
};
