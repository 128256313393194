/* eslint-disable no-irregular-whitespace */
import React from "react";

import { useGetCurrentWorkspaceDetail } from "../../../api/workspace";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { useGetSelectedEcommercePlatformObject } from "../../../hooks/integration/useGetSelectedEcommercePlatformObject";
import { EcommercePlatformEnum, EcommercePlatformObject } from "../../../redux/types";
import { CustomPlatform } from "./EcommercePlatformsComponents/CustomPlatform";
import { MyCashflowPlatform } from "./EcommercePlatformsComponents/MyCashflowPlatform";
import { ShopifyPlatform } from "./EcommercePlatformsComponents/ShopifyPlatform";
import { ShoptetPlatform } from "./EcommercePlatformsComponents/ShoptetPlatform";
import { WooCommercePlatform } from "./EcommercePlatformsComponents/WooCommercePlatform";

import styles from "./IntegrationContent.module.scss";

export const EcommercePlatforms: React.FC = () => {
  const { isLoading: currentWorkspaceLoading } = useGetCurrentWorkspaceDetail();
  const selectedPlatformObject = useGetSelectedEcommercePlatformObject();

  if (currentWorkspaceLoading) {
    return (
      <div className={styles.layout}>
        <LoadingSpinner />
      </div>
    );
  }

  return <>{selectedPlatformObject.platformComponent}</>;
};

export const platformObjects: EcommercePlatformObject[] = [
  {
    platformName: EcommercePlatformEnum.toString(EcommercePlatformEnum.shopify),
    platformComponent: <ShopifyPlatform />,
  },
  {
    platformName: EcommercePlatformEnum.toString(EcommercePlatformEnum.myCashFlow),
    platformComponent: <MyCashflowPlatform />,
  },
  {
    platformName: EcommercePlatformEnum.toString(EcommercePlatformEnum.wooCommerce),
    platformComponent: <WooCommercePlatform />,
  },
  {
    platformName: EcommercePlatformEnum.toString(EcommercePlatformEnum.shoptet),
    platformComponent: <ShoptetPlatform />,
  },
  {
    platformName: EcommercePlatformEnum.toString(EcommercePlatformEnum.custom),
    platformComponent: <CustomPlatform />,
  },
];
