import appReducer from "./appReducer";
import campaignsReducer from "./campaignReducer";
import notificationReducer from "./notificationReducer";
import workspaceReducer from "./workspaceReducer";

export const rootReducer = {
  app: appReducer,
  campaigns: campaignsReducer,
  workspace: workspaceReducer,
  notification: notificationReducer,
};
