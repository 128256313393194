import { useGetCurrentWorkspaceDetail, useGetIntegrationDetails } from "../../api/workspace";
import { WorkspaceRoleType } from "../../redux/types";
import { isDangerSubscriptionStatus } from "../../utils/isDangerSubscriptionStatus";
import { isGlobalIntegrationError } from "../../utils/isGlobalIntegrationError";
import { isAllowed, useGetUserRole } from "../user/useGetUserRole";

export interface MainMenuItem {
  to: string;
  text: string;
  iconName: string;
  roles?: WorkspaceRoleType | WorkspaceRoleType[];
  errorInfo?: boolean;
  isHidden?: boolean;
}

interface AllMainMenuItems {
  mainMenuItems: MainMenuItem[];
  visibleMainMenuItems: MainMenuItem[];
}

export const useGetMainMenuItems = (): AllMainMenuItems => {
  const { data: integrationDetailsData } = useGetIntegrationDetails();
  const { data: workspaceDetailData } = useGetCurrentWorkspaceDetail();
  const isIntegrationError = isGlobalIntegrationError(integrationDetailsData, workspaceDetailData);
  const subscriptionError = isDangerSubscriptionStatus(workspaceDetailData?.subscriptionStatus);
  const userRole = useGetUserRole();
  const integrationRoles = [WorkspaceRoleType.ADMIN, WorkspaceRoleType.CLIENT];
  const workspaceSetupRoles = [WorkspaceRoleType.ADMIN];
  const isIntegrationRoleAllowed = isAllowed(userRole, integrationRoles);
  const isWorkspaceSetupRolesAllowed = isAllowed(userRole, workspaceSetupRoles);
  const isIntegrationHidden = !isIntegrationRoleAllowed || subscriptionError;

  const mainMenuItems: MainMenuItem[] = [
    {
      to: "/dashboard",
      text: "Dashboard",
      iconName: "home",
      errorInfo: subscriptionError,
    },
    {
      to: "/campaigns",
      text: "Campaigns",
      iconName: "flag",
      isHidden: subscriptionError,
    },
    {
      to: "/integration",
      text: "Integration",
      iconName: "cog",
      roles: integrationRoles,
      errorInfo: isIntegrationError,
      isHidden: isIntegrationHidden,
    },
    {
      to: "/workspace-setup",
      text: "Workspace Setup",
      iconName: "ico-manage",
      roles: workspaceSetupRoles,
      isHidden: !isWorkspaceSetupRolesAllowed,
    },
  ];

  const visibleMainMenuItems = mainMenuItems.filter(({ isHidden }) => !isHidden);
  const allMainMenuItems: AllMainMenuItems = { mainMenuItems, visibleMainMenuItems };

  return allMainMenuItems;
};
