import React from "react";

import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { getUrlDateRange, toLocalDateStringShort } from "../../utils/formatters/dateFormatters";
import { ChevronIcon } from "../Icon/ChevronIcon/ChevronIcon";
import { Icon } from "../Icon/Icon";

import styles from "./DatePicker.module.scss";

interface DatePickerButtonProps {
  isOpen: boolean;
  handleOpen: () => void;
}

export const DatePickerButton: React.FC<DatePickerButtonProps> = ({ isOpen, handleOpen }) => {
  const { isDesktop, isLargeDesktop } = useGetBreakpointProperties();

  const getAppliedSelectRange = () => {
    const { from, to } = getUrlDateRange();

    const startDate = new Date(from);
    const endDate = new Date(to);

    let appliedSelectRange = `${toLocalDateStringShort(startDate)} - ${toLocalDateStringShort(endDate)}`;

    const isOneDayRange = startDate?.getTime() === endDate?.getTime();

    if (isOneDayRange) {
      appliedSelectRange = toLocalDateStringShort(startDate);
    }

    return appliedSelectRange;
  };

  return !isDesktop && !isLargeDesktop ? (
    <div className={styles.mobileIcon} onClick={handleOpen}>
      <Icon name="ico_calendar_top" />
    </div>
  ) : (
    <div onClick={handleOpen} className={styles.select}>
      <Icon name="ico_calendar_top" width="22px" />
      <span>{getAppliedSelectRange()}</span>
      <ChevronIcon isActive={isOpen} />
    </div>
  );
};
