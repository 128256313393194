import React, { CSSProperties } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";

import { TableBodyCellProps } from "../../../redux/types/table";
import { getCellFlexTableCellAlignmentClassName } from "../Table";

import styles from "./TableBody.module.scss";

export const TableBodyCell: React.FC<TableBodyCellProps> = ({ cell }) => {
  const column = cell.column;

  const getAppliedRowCellClassName = () => {
    const classesArray = [styles.rowCellGeneric];
    if (cell.column.id === "src") {
      classesArray.push(styles.noLineHeight);
    }
    if (column.alignment) {
      const cellFlexTableCellAlignmentClassName = getCellFlexTableCellAlignmentClassName(column.alignment);
      classesArray.push(styles[cellFlexTableCellAlignmentClassName]);
    }

    const appliedRowCellClass = classNames(...classesArray);
    return appliedRowCellClass;
  };

  const appliedRowCellStyles: CSSProperties = {
    flex: column.flexGrow !== undefined ? column.flexGrow : column.minWidth,
    minWidth: column.minWidth,
    width: column.width,
    maxWidth: column.maxWidth,
  };

  return (
    <motion.td
      {...cell.getCellProps({ className: "cell" })}
      className={getAppliedRowCellClassName()}
      style={appliedRowCellStyles}>
      {cell.render("Cell")}
    </motion.td>
  );
};
