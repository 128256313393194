import React from "react";

import { ContentWithMenu } from "../../components/ContentWithMenu/ContentWithMenu";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { UserSettingsContent } from "./UserSettingsContent";
import { UserSettingsMenu } from "./UserSettingsMenu";

export const UserSettingsPage: React.FC = () => {
  return (
    <PageContainer>
      <ContentWithMenu customContainer>
        <UserSettingsMenu />
        <UserSettingsContent />
      </ContentWithMenu>
    </PageContainer>
  );
};
