import React from "react";
import { SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router";
import classNames from "classnames";

import {
  useCreateWorkspaceMutation,
  useGetCurrentWorkspaceDetail,
  useUpdateWorkspaceMutation,
} from "../../api/workspace";
import { CreateNewWorkspaceRequestDTO } from "../../api/workspace/create-new/dto";
import { UpdateWorkspaceRequestDTO } from "../../api/workspace/update-details/request";
import { Callout } from "../../components/Callout/Callout";
import { FormDivider } from "../../components/Divider/FormDivider";
import { useCreateStripeSession } from "../../hooks/billing/useCreateStripeSession";
import { usePrepareWorkspaceForm } from "../../hooks/componentSpecific/usePrepareWorkspaceForm";
import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { CurrencyType } from "../../redux/types";
import { ConfirmButtonComponent } from "./ConfirmButtonComponent";
import { CurrencyDropdownComponent } from "./CurrencyDropdownComponent";
import { DomainInputComponent } from "./DomainInputComponent";
import { NameInputComponent } from "./NameInputComponent";
import { TimezoneDropdownComponent } from "./TimezoneDropdownComponent";

import styles from "./WorkspaceForm.module.scss";

export enum WorkspaceFormType {
  CREATE = "create",
  UPDATE = "update",
}

interface WorkspaceFormProps {
  type: WorkspaceFormType;
  prefilledData?: CreateNewWorkspaceRequestDTO;
}

export const WorkspaceForm: React.FC<WorkspaceFormProps> = ({ type, prefilledData }) => {
  const {
    selectedTimezone,
    setSelectedTimezone,
    selectedCurrency,
    setSelectedCurrency,
    handleSubmit,
    formHandler,
    isCreateForm,
    isUpdateForm,
  } = usePrepareWorkspaceForm(type, prefilledData);

  const workspace = useGetCurrentWorkspaceDetail();
  const history = useHistory();
  const hasNoBackOption = isUpdateForm || "/add-first-workspace" === history.location.pathname;
  const { loading, handleClick } = useCreateStripeSession();
  const { isMobile } = useGetBreakpointProperties();
  const isSideBySideAlign = isUpdateForm && !isMobile;
  const updateMutation = useUpdateWorkspaceMutation();
  const createMutation = useCreateWorkspaceMutation();
  const isMutationLoading = updateMutation.isLoading || createMutation.isLoading || loading;

  const mainProps = { formHandler, prefilledData, isMutationLoading };
  const currencyDropdownProps = { formHandler, selectedCurrency, setSelectedCurrency, isCreateForm, isMutationLoading };
  const timezoneDropdownProps = { selectedTimezone, setSelectedTimezone, isMutationLoading };
  const confirmButtonProps = {
    isCreateForm,
    isMutationLoading,
    isSideBySideAlign,
  };

  const onSubmit: SubmitHandler<CreateNewWorkspaceRequestDTO | UpdateWorkspaceRequestDTO> = async (data) => {
    // --- CREATE FORM ---
    const createFormMutate = () => {
      const formattedData = {
        name: data.name!.trim(),
        domain: data.domain!.trim().match(/^(?:https?:\/\/)?(?:www\d*\.)?(.*)$/)![1],
        //because initial value is only Eg. Europe/Prague and when selected it's {..., value: "Europe/Prague"}
        timeZone: selectedTimezone,
        currency: selectedCurrency.value as CurrencyType,
      };

      createMutation.mutate(formattedData, {
        onSuccess: (data) => {
          handleClick(data.id);
        },
      });
    };
    // --- UPDATE FORM ---
    const updateFormMutate = () => {
      if (workspace.data) {
        updateMutation.mutate({
          name: data.name?.trim() ?? undefined,
          domain: data.domain!.trim().match(/^(?:https?:\/\/)?(?:www\d*\.)?(.*)$/)![1] ?? undefined,
          timeZone: selectedTimezone ?? selectedTimezone,
          workspaceId: workspace.data.id,
        });
      }
    };

    if (isCreateForm) {
      createFormMutate();
      return;
    }
    updateFormMutate();
  };

  const getAppliedFormClasses = (): string => {
    const appliedFormClassesArray = [styles.workspaceForm];
    if (isSideBySideAlign) {
      appliedFormClassesArray.push(styles.sideBySideAlign);
    }

    const appliedFormClasses = classNames(...appliedFormClassesArray);
    return appliedFormClasses;
  };

  return (
    <form className={getAppliedFormClasses()} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputsContainer}>
        <NameInputComponent {...mainProps} />
        <DomainInputComponent {...mainProps} />
        <CurrencyDropdownComponent {...currencyDropdownProps} />
        <TimezoneDropdownComponent {...timezoneDropdownProps} />
      </div>
      {!hasNoBackOption && (
        <div className={styles.calloutContainer}>
          <Callout type="info">This workspace will cost you an additional 99$ for its base variant.</Callout>
        </div>
      )}
      {isUpdateForm && <FormDivider />}
      <ConfirmButtonComponent {...confirmButtonProps} />
    </form>
  );
};
