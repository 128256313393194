import { Variants } from "framer-motion/types/types";

interface ModalAnimationProperties {
  blur: Variants;
  fadeIn: Variants;
  animate: Variants;
}

export const getModalAnimationProperties = (animation: string): ModalAnimationProperties => {
  const slideUp: Variants = {
    hidden: { y: "100vh" },
    visible: {
      y: 0,
      opacity: 0.9,
      transition: {
        duration: 0.6,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0.9,
      transition: {
        duration: 0.4,
      },
    },
  };

  const blur: Variants = {
    hidden: {
      backdropFilter: "blur(0px)",
    },
    visible: {
      backdropFilter: "blur(3px)",
      transition: {
        duration: 0,
      },
    },
    exit: {
      backdropFilter: "blur(0px)",
      transition: {
        duration: 0.1,
      },
    },
  };

  const fadeIn: Variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        type: "ease",
        duration: 0.4,
      },
    },
  };

  const animate: Variants = animation === "slideUp" ? slideUp : fadeIn;
  const modalAnimationProperties: ModalAnimationProperties = { blur, fadeIn, animate };

  return modalAnimationProperties;
};
