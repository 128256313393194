import { useQuery } from "react-query";

import { GenericViewsProps } from "../types";
import { DashboardViewResponseDTO } from "./dto";

export const useGetDashboard = ({ from, to, attributionType, workspaceId }: GenericViewsProps) => {
  return useQuery<DashboardViewResponseDTO>(
    [`views/dashboard/${workspaceId}?from=${from}&to=${to}&attributionType=${attributionType}`],
    { enabled: !!workspaceId },
  );
};
