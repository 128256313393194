import { useHistory } from "react-router";

import { useConsentSubscriptionPriceUpgrade } from "../../../api/billing";
import { ButtonVariant } from "../../../components/Button/Button";
import { useCreateStripeSession } from "../../../hooks/billing/useCreateStripeSession";
import { HELP_APP_URL } from "../../../utils/constants/urlStrings";
import { AlertBannerVariant, BannerType } from "./AlertBanner";
import { AlertBannerName } from "./types";

export const useGetAllAlertBanners = (): AlertBannerVariant[] => {
  const { loading, handleClick: handleRedirectToStripe } = useCreateStripeSession();
  const history = useHistory();
  const approve = useConsentSubscriptionPriceUpgrade();

  const handleRedirectToIntegration = () => {
    history.push("/integration");
  };
  const handleRedirectToHelpdesk = () => {
    window.open(HELP_APP_URL, "_blank");
  };

  const handleApproveSubscription = () => {
    approve.mutate();
  };

  return [
    {
      name: AlertBannerName.PAYMENT_PENDING,
      title: "Awaiting payment.",
      text: "We are having trouble processing your payment. Please reach out to our support team.",
      imageName: "payment-pending.png",
      button: { text: "Helpdesk", variant: ButtonVariant.PRIMARY },
      onButtonClick: handleRedirectToHelpdesk,
      bannerType: BannerType.BLUE,
    },
    {
      name: AlertBannerName.NO_DATA_AND_YOUNGER_THAN_WEEK,
      title: "Welcome on board! Add data now.",
      text: "Import your eshop details to see full coverage of users interaction.",
      imageName: "no-data-img.png",
      linkText: "Need help?",
      linkUrl: HELP_APP_URL,
      button: { text: "Connect data", variant: ButtonVariant.PRIMARY },
      onButtonClick: handleRedirectToIntegration,
      bannerType: BannerType.BLUE,
    },
    {
      name: AlertBannerName.NO_DATA_IN_PERIOD_AND_DATA_OUTSIDE,
      title: "No data available in this period.",
      text: "Try switching the date on the top of the screen to see data from your shop or check the Integration.",
      imageName: "no-current-data-img.png",
      linkText: "Need help?",
      linkUrl: HELP_APP_URL,
      button: { text: "Check integration", variant: ButtonVariant.PRIMARY },
      onButtonClick: handleRedirectToIntegration,
      bannerType: BannerType.GRAY,
    },
    {
      name: AlertBannerName.NO_DATA,
      title: "No data available.",
      text: "We have not tracked any data from your e-commerce store yet. This could be caused by having zero conversions in store or there might be an error in integration. Please check.",
      imageName: "no-current-data-img.png",
      linkText: "Need help?",
      linkUrl: HELP_APP_URL,
      button: { text: "Check integration", variant: ButtonVariant.PRIMARY },
      onButtonClick: handleRedirectToIntegration,
      bannerType: BannerType.GRAY,
    },
    {
      name: AlertBannerName.PAYMENT_EMPTY,
      title: "Incomplete registration proccess.",
      text: "It seems that there is something undone in your registation process. In order to fully exploit power of ecomtrack, please finish the payment process.",
      imageName: "payment-red.png",
      button: { text: "Finish payment", variant: ButtonVariant.DANGER },
      onButtonClick: handleRedirectToStripe,
      isLoading: loading,
      bannerType: BannerType.RED,
    },
    {
      name: AlertBannerName.PAYMENT_ERROR,
      title: "Payment not successful.",
      text: "Please check your bank account or try to resend the payment again. Once we receive the payment, we will allow you to access the Campaigns section again. If the problem remains, contact our ",
      imageName: "payment-red.png",
      linkText: "support",
      linkUrl: HELP_APP_URL,
      button: { text: "Finish payment", variant: ButtonVariant.DANGER },
      onButtonClick: handleRedirectToStripe,
      isLoading: loading,
      bannerType: BannerType.RED,
    },
    {
      name: AlertBannerName.PAYMENT_UNAPPROVED,
      title: "Please upgrade your plan.",
      imageName: "payment-unapproved.svg",
      button: { text: "Upgrade plan", variant: ButtonVariant.PRIMARY },
      onButtonClick: handleApproveSubscription,
      isLoading: approve.isLoading,
      bannerType: BannerType.BLUE,
    },
  ];
};
