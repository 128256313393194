import React from "react";

import styles from "./Container.module.scss";

interface ContentSegmentProps {
  child: React.ReactNode;
}

export const ContentSegment: React.FC<ContentSegmentProps> = ({ child }) => (
  <div className={styles.contentSegment}>{child}</div>
);
