import React from "react";

import { ContentWithMenu } from "../../components/ContentWithMenu/ContentWithMenu";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { IntegrationContent } from "./IntegrationContent/IntegrationContent";
import { IntegrationMenu } from "./IntegrationMenu/IntegrationMenu";

export const IntegrationPage: React.FC = () => {
  return (
    <PageContainer>
      <ContentWithMenu>
        <IntegrationMenu />
        <IntegrationContent />
      </ContentWithMenu>
    </PageContainer>
  );
};
