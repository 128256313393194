/**
 * takes a date and returns string formatted as YYYY-MM-DD reflecting timezone offset
 * Used only for formatting dates for backend requests
 *
 * Example:
 *
 * "2021-10-05T22:00:10.001Z" => "2021-10-06"
 */
export const toLocalDateString = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

interface TimeDifferenceDetail {
  daysDifference: number;
  hoursDifference: number;
  minutesDifference: number;
}

/**
 * takes a date and returns an object, which tells us how many days, hours, and minutes are left until that date from now
 *
 */

export const getTimeDifference = (dateString: Date): TimeDifferenceDetail => {
  const date = new Date(dateString);
  const milisecondsPerDay = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const todayTime = Date.now();
  const utcTime = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  const daysDifferencePrecise = (utcTime - todayTime) / milisecondsPerDay; // in days
  const daysDifference = Math.floor(daysDifferencePrecise); // in days
  const hoursDifference = Math.floor(daysDifferencePrecise * 24);
  const minutesDifference = Math.floor(daysDifferencePrecise * 24 * 60) - hoursDifference * 60;

  const timeDifferenceObject: TimeDifferenceDetail = {
    daysDifference,
    hoursDifference,
    minutesDifference,
  };

  return timeDifferenceObject;
};

interface DaysDifferenceObject {
  daysDifferenceString: string;
  daysDifferenceFullString: string;
}

/**
 * takes a date and returns a string containing info of how many days are left until that date from now
 *
 */

export const getDaysDifferenceString = (dateString: Date): DaysDifferenceObject => {
  const { daysDifference } = getTimeDifference(dateString);

  let daysDifferenceString = `${daysDifference} days`;
  let daysDifferenceFullString = `expires in ${daysDifference} days`;
  if (daysDifference === 1) {
    daysDifferenceString = `${daysDifference} day`;
    daysDifferenceFullString = `expires in ${daysDifference} day`;
  }
  if (daysDifference === 0) {
    daysDifferenceString = "today";
    daysDifferenceFullString = "expires today";
  }
  if (daysDifference < 0) {
    daysDifferenceString = "expired";
    daysDifferenceFullString = "expired";
  }
  const daysDifferenceObject = { daysDifferenceString, daysDifferenceFullString };

  return daysDifferenceObject;
};

export const getMillisecondsFromDate = (date?: Date): number => date?.valueOf() ?? Date.now();

interface DateRange {
  from: string;
  to: string;
  startDate: number;
  endDate: number;
}

export const getUrlDateRange = (): DateRange => {
  const urlParamsStartDate = transformDateSearchParams("from");
  const urlParamsEndDate = transformDateSearchParams("to");
  const now = Date.now();
  const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

  let endDate = now;
  // by default, the start date is put a week before the end date
  let startDate = endDate - weekInMilliseconds;

  // for the date range to be valid, end date must be an earlier date than end date, and no date can be a future date
  const datesRangeIsValid =
    urlParamsStartDate < now && urlParamsEndDate < now && urlParamsStartDate <= urlParamsEndDate;

  if (datesRangeIsValid) {
    startDate = urlParamsStartDate;
    endDate = urlParamsEndDate;
  }

  const from = toLocalDateString(new Date(startDate));
  const to = toLocalDateString(new Date(endDate));

  const dateRange: DateRange = { from, to, startDate, endDate };

  return dateRange;
};

const transformDateSearchParams = (key: keyof DateRange): number => {
  const searchParams = new URLSearchParams(window.location.search);
  const searchParamsValue = searchParams.get(key);
  const searchParamsValueNumber = getMillisecondsFromDate(new Date(searchParamsValue as string));
  let transformedSeachParams = Date.now();
  if (searchParamsValue && searchParamsValueNumber < transformedSeachParams) {
    transformedSeachParams = searchParamsValueNumber;
  }

  return transformedSeachParams;
};

/**
 * takes a date and returns string formatted as mmm-dd reflecting timezone offset
 *
 * Example:
 *
 * "2021-10-05T22:00:10.001Z" => "Oct 06"
 */
export const toLocalDateStringShort = (date?: Date): string => {
  const dateString = date?.toLocaleDateString(`en`, { month: "short", day: "numeric" });
  return `${dateString}`;
};
