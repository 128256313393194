import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NotificationInput, NotificationSeverity, NotificationState } from "../types";

const initNotificationState: NotificationState = {
  notification: { open: false, message: "", severity: NotificationSeverity.INFO, icon: undefined, hideAfter: 5000 },
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initNotificationState,
  reducers: {
    showNotification: (state, action: PayloadAction<NotificationInput>) => {
      state.notification = { ...state.notification, ...action.payload, open: true };
    },
    hideNotification: (state) => {
      state.notification = { ...state.notification, open: false };
    },
    somethingWentWrong: (state) => {
      state.notification = {
        ...state.notification,
        open: true,
        message: "Something went wrong. Please try again in a while.",
        severity: NotificationSeverity.ERROR,
      };
    },
  },
});

export const { showNotification, hideNotification, somethingWentWrong } = notificationSlice.actions;
export default notificationSlice.reducer;
