import React from "react";

import { useGetMainMenuItems } from "../../../hooks/componentSpecific/useGetMainMenuItems";
import { FooterNavbarItem } from "./FooterNavbarItem";

import styles from "./FooterNavbar.module.scss";

export const FooterNavbar: React.FC = () => {
  const { mainMenuItems } = useGetMainMenuItems();

  return (
    <nav className={styles.navigation}>
      <ul className={styles.list}>
        {mainMenuItems.map(({ iconName, text, to, isHidden, errorInfo }) => (
          <FooterNavbarItem key={iconName} {...{ iconName, text, to, isHidden, errorInfo }} />
        ))}
      </ul>
    </nav>
  );
};
