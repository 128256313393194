import React, { CSSProperties, ReactNode } from "react";

import { useModal } from "../../hooks/modal/useModal";
import { VideoModal } from "../../modals/VideoModal/VideoModal";
import { VideoIds, videoIds } from "../../utils/constants/videoIds";
import { Button, ButtonSize, ButtonVariant } from "../Button/Button";
import { ComplexHeader } from "../ComplexHeader/ComplexHeader";
import { BaseDivider } from "../Divider/BaseDivider";
import { Icon } from "../Icon/Icon";

import styles from "./CardWithTitle.module.scss";

interface CardWithTitleProps {
  iconName: string;
  title: string;
  subtitle: string;
  rightComponent?: ReactNode;
  videoControl?: boolean;
  style?: CSSProperties;
  tag?: string;
}

export const CardWithTitle: React.FC<CardWithTitleProps> = ({
  iconName,
  title,
  subtitle,
  rightComponent,
  videoControl,
  style,
  children,
  tag,
}) => {
  const { visible, toggle } = useModal();

  const videoButton = (
    <Button className={styles.small} size={ButtonSize.SMALL} variant={ButtonVariant.SECONDARY} onClick={toggle}>
      <Icon name="playButton" />
      Instructions
    </Button>
  );

  const getPropertyName = (str: string): keyof VideoIds => str.replaceAll(" ", "_").toLowerCase();

  const getVideoUrlString = (): string => {
    if (!videoControl) {
      return "";
    }

    const videoIdObject = videoIds[getPropertyName(subtitle)];

    const propertyName = getPropertyName(title);

    let str = videoIdObject[propertyName];

    if (tag) {
      str = videoIdObject[tag];
    }
    return str;
  };

  const videoUrlString = getVideoUrlString();

  const getRightComponent = () => {
    if (rightComponent) {
      return rightComponent;
    }

    if (videoControl) {
      return videoButton;
    }

    return null;
  };

  return (
    <div className={styles.container} style={style}>
      <div className={styles.headerSegment}>
        <ComplexHeader iconName={iconName} rightComponent={getRightComponent()} title={title} subtitle={subtitle} />
      </div>
      <BaseDivider />
      <div className={styles.contentSegment}>{children}</div>
      {videoControl && <VideoModal visible={visible} toggle={toggle} videoUrlString={videoUrlString} />}
    </div>
  );
};
