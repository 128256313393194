import React from "react";

import { IMenuItem, Menu } from "../../../components/Menu/Menu";

export const WorkspaceSetupMenu: React.FC = () => {
  const workspaceSettingsItems: IMenuItem[] = [
    {
      to: "/workspace-setup/settings",
      text: "Settings",
      iconName: "ico-provider",
    },
    {
      to: "/workspace-setup/user-roles",
      text: "User roles",
      iconName: "ico-person",
    },
    {
      to: "/workspace-setup/plan-details",
      text: "Plan details",
      iconName: "ico-person",
    },
  ];

  return <Menu menuItems={workspaceSettingsItems} />;
};
