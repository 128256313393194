import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { EcomtrackVsFbColumnHeader } from "../../../components/EcomtrackVsFbColumnHeader/EcomtrackVsFbColumnHeader";
import { Icon } from "../../../components/Icon/Icon";
import { getAllCampaignCols } from "../../../redux/selectors/campaigns";
import { ExtendedCampaign } from "../../../redux/types";
import { FormattedCell } from "./FormattedCell";

import styles from "./CampaignItem.module.scss";

interface CampaignRowProps {
  isLastItem: boolean;
  handleExpand: () => void;
  subRowArray: ExtendedCampaign[];
  campaign: ExtendedCampaign;
}

export const CampaignRow: React.FC<CampaignRowProps> = ({ isLastItem, handleExpand, subRowArray, campaign }) => {
  const campaignsTableCols = useSelector(getAllCampaignCols);
  const getAppliedCampaignContainerClassNames = () => {
    const appliedCampaignContainerClassNamesArray = [styles.campaignContainer];
    if (isLastItem) {
      appliedCampaignContainerClassNamesArray.push(styles.lastCampaignContainer);
    }
    return classNames(...appliedCampaignContainerClassNamesArray);
  };

  return (
    <li onClick={handleExpand} className={getAppliedCampaignContainerClassNames()}>
      <ul className={styles.campaignMobile}>
        {campaignsTableCols.map(({ name, accessor, isEcomtrackVsFbColumn, isRealColumn }) => {
          const visible = (accessor === "src" && campaign.main) || accessor !== "src";

          if (!visible) return null;

          return (
            <li key={name} className={styles.expandedItemRow}>
              <div className={styles.nameContainer}>
                {isEcomtrackVsFbColumn && (
                  <div className={styles.iconContainer}>{isEcomtrackVsFbColumn && <EcomtrackVsFbColumnHeader />}</div>
                )}
                {isRealColumn && <Icon name="logo_square" width="14" height="14" />}
                <div className={styles.name}>
                  <p>{!isEcomtrackVsFbColumn && name}:</p>
                </div>
              </div>
              <FormattedCell
                subRowArray={subRowArray}
                campaign={campaign}
                accessor={accessor as keyof ExtendedCampaign}
              />
            </li>
          );
        })}
      </ul>
    </li>
  );
};
