import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { getJwt } from "../../../redux/selectors/app";
import { GetLoggedInUserResponseDTO } from "../../../redux/types";

export const getCurrentUserUrl = "user/current";

export const useGetLoggedInUser = () => {
  const jwtFromStore = useSelector(getJwt);

  return useQuery<GetLoggedInUserResponseDTO>([getCurrentUserUrl], {
    enabled: !!jwtFromStore,
  });
};
