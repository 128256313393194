import React, { useState } from "react";

import { Slide } from "./Slide";
import img from "./slide_1.png";
import img1 from "./slide_2.png";
import img2 from "./slide_3.png";

import styles from "./SlideShow.module.scss";

const slides = [
  {
    img: img2,
    title: "Scale your ads with certainty",
    body: "With accurate data, you will scale your store much faster.",
  },
  {
    img: img,
    title: "Advanced and accurate tracking in 15 minutes.",
    body: "Avoid missatribution, over-reporting or under-reporting.",
  },
  {
    img: img1,
    title: "One platform to scale your online store.",
    body: "With ecomtrack you’ll avoid wasting your budget for campaings that are not performing.",
  },
];

export const SlideShow: React.FC = () => {
  const [index, setIndex] = useState(0);
  const delay = 5000;

  // Auto slider timer
  React.useEffect(() => {
    const intervalRef = setInterval(() => setIndex(nextIndex(index)), delay);
    return () => clearInterval(intervalRef);
  }, [index]);

  // Returns index of next slide
  const nextIndex = (index: number) => {
    return index === slides.length - 1 ? 0 : index + 1;
  };

  // Stop propagation and set desired slide
  const handleClick = (event: React.MouseEvent, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    setIndex(index);
  };

  return (
    <div className={styles.container} onClick={(event) => handleClick(event, nextIndex(index))}>
      <div className={styles.slider}>
        {slides.map((slide) => {
          const appliedTransformString = `translate3d(${-index * 100}%, 0, 0)`;
          return (
            <Slide
              style={{ transform: appliedTransformString }}
              key={slide.title}
              img={slide.img}
              title={slide.title}
              body={slide.body}></Slide>
          );
        })}
      </div>
      <div className={styles.dots}>
        {slides.map((slide, idx) => (
          <div
            key={slide.title}
            onClick={(event) => handleClick(event, idx)}
            className={`${styles.dot} ${index === idx ? styles.active : ""}`}></div>
        ))}
      </div>
    </div>
  );
};
