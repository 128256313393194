import { CSSProperties } from "react";
import { Cell, Row } from "react-table";

export enum TableCellAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export interface TableColumn {
  name: string;
  accessor: string;
  alignment: TableCellAlignment;
  skeleton?: { height: number; width: number; round: boolean };
  disableLoading?: boolean;
  coloredNumber?: boolean; // Should show colors based on number value (i.e. === 0 -> black; >= 0 -> green; < 0 -> red)
  currency?: boolean;
  percentage?: boolean;
  element?: any;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  pointer?: boolean;
  notOnSmallDisplay?: boolean;
  tooltipAccessor?: string;
  style?: CSSProperties;
  isEcomtrackVsFbColumn?: boolean;
  flexGrow?: number;
  isRealColumn?: boolean;
  isHidden?: boolean;
}

export interface GetTableRowClassProps {
  rowNumber: number;
  expanded: boolean;
  rowId: string;
  rowsById: Record<string, Row<Record<string, any>>>;
}

export type GetRowClassType = (props: GetTableRowClassProps) => string;

export enum TableType {
  CAMPAIGNS = "campaigns",
  DASHBOARD = "dashboard",
  WORKSPACE_MANAGER = "workspaceManager",
  WORKSPACE_USERS = "workspaceUsers",
  DEFAULT = "default",
}

export interface TableHeaderProps {
  tableType: TableType;
  headerGroups: any[];
  setSortBy: (sortBy: any) => void;
  sortBy: Record<string, any>[] | undefined;
}

export interface TableHeaderRowProps extends TableHeaderProps {
  headerGroup: any;
}

export interface TableHeaderCellProps extends TableHeaderRowProps {
  column: any;
}

export interface TableBodyProps {
  tableType: TableType;
  getTableBodyProps: () => any;
  rows: any[];
  prepareRow: (row: any) => void;
  getRowClass?: GetRowClassType;
  rowsById: any;
}

export interface TableBodyRowProps extends TableBodyProps {
  row: any;
  idx: number;
}

export interface TableBodyCellProps extends TableBodyRowProps {
  cell: any;
}

export interface TableBodyCellContentProps {
  props: any;
  col: any;
  loading: boolean | undefined;
  getColumnValue: ((col: TableColumn, props: any) => JSX.Element) | undefined;
  rowClick: ((row: Row, cell: Cell) => void) | undefined;
}

export interface TableHeaderCellContentProps {
  col: any;
}

export interface TableProps {
  cols: TableColumn[];
  data: Record<string, any>[];
  rowClick?: (row: Row, cell: Cell) => void;
  loading?: boolean;
  /**
   * Array of objects of column ids the table should be sorted by
   */
  sortBy?: Record<string, any>[];
  handlePagination?: () => void;
  maxHeight?: string;
  defaultSortBy?: { id: string; desc: boolean };
  getRowClass?: GetRowClassType;
  getColumnValue?: (col: TableColumn, props: any) => JSX.Element;
  tableType: TableType;
}
