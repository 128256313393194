import React from "react";
import { useSelector } from "react-redux";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { Container } from "../../components/Container/Container";
import { HintBanner } from "../../components/HintBanner/HintBanner";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { getCampaignsDataContentStatus } from "../../redux/selectors/campaigns";
import { DataContentStatus, SubscriptionStatus } from "../../redux/types";
import { useGetBannerToShow } from "../DashboardPage/AlertBanner/useLoadAlertBannerProperties";
import { DataSegment } from "./DataSegment";
import { TopSegment } from "./TopSegment";

import styles from "./CampaignsPage.module.scss";

export const CampaignsPage: React.FC = () => {
  const dataContentStatus = useSelector(getCampaignsDataContentStatus);
  const { data: workspace } = useGetCurrentWorkspaceDetail();
  const banner = useGetBannerToShow(
    workspace?.subscriptionStatus ?? SubscriptionStatus.Empty,
    dataContentStatus ?? DataContentStatus.NO_DATA,
  );

  return (
    <PageContainer>
      {banner && (
        <HintBanner type={banner.bannerType}>
          {banner.text}{" "}
          <a target="_blank" rel="noreferrer" href={banner.linkUrl}>
            {banner.linkText}
          </a>
        </HintBanner>
      )}
      <Container className={styles.container} fullWidth>
        <TopSegment />
        <DataSegment />
      </Container>
    </PageContainer>
  );
};
