import React from "react";

import { Icon } from "../../components/Icon/Icon";
import { IAdsMenuItem } from "../../containers/IntegrationPage/IntegrationMenu/IntegrationMenu";

import styles from "./AddAdProviderModal.module.scss";

interface AddAdProviderItemProps {
  platform: IAdsMenuItem;
  temporarilySelected: IAdsMenuItem[];
  setTemporarilySelected: React.Dispatch<React.SetStateAction<IAdsMenuItem[]>>;
}

export const AdProviderItem: React.FC<AddAdProviderItemProps> = ({
  platform,
  temporarilySelected,
  setTemporarilySelected,
}) => {
  const selectedObj = temporarilySelected ? temporarilySelected.find((sel) => sel.name === platform.name) : null;
  const isSelected = Boolean(selectedObj);
  const handleSelect = () => {
    const arrWithoutPlatform = temporarilySelected.filter((obj) => obj.name !== selectedObj?.name);
    if (selectedObj && arrWithoutPlatform.length >= 1) {
      setTemporarilySelected(arrWithoutPlatform);
      return;
    }

    setTemporarilySelected([...temporarilySelected, platform]);
  };
  return (
    <div className={`${styles.selectProviderItem}`} onClick={handleSelect}>
      <input className={styles.platformCheckbox} type="checkbox" value={platform.name} checked={isSelected} readOnly />
      <div className={styles.customCheckmark}>
        <Icon name="ico-checkMark" />
      </div>
      <Icon name={platform.iconNameBig ?? ""} width="60px" height="60px" />
      <span className={styles.platformName}>{platform.text}</span>
    </div>
  );
};
