import { useSelector } from "react-redux";

import { setExtendedCampaigns } from "../../redux/reducers/campaignReducer";
import { getExtendedCampaigns } from "../../redux/selectors/campaigns";
import { store } from "../../redux/store";
import { ExtendedCampaign } from "../../redux/types";

interface CampaignsMobileProperties {
  campaignName: string;
  isLastItem: boolean;
  subRowArray: ExtendedCampaign[];
  handleExpand: () => void;
}

export const useGetCampaignsMobileProperties = (
  campaign: ExtendedCampaign,
  index: number,
): CampaignsMobileProperties => {
  const extendedCampaigns = useSelector(getExtendedCampaigns);

  const campaignName = getCampaignName(campaign);
  const subRowArray = getSubArray(campaign);
  const handleExpand = () => expandCampaign(extendedCampaigns, index, subRowArray, campaign);
  const isLastItem = isLastItemFn(index, extendedCampaigns);

  return { campaignName, handleExpand, isLastItem, subRowArray };
};

const getSubArray = (campaign: ExtendedCampaign) => {
  let subArray: ExtendedCampaign[] = [];

  if (campaign.subRows && campaign.campaigns && campaign.campaigns.length !== 0) {
    subArray = campaign.subRows.map((item) => {
      return { ...item, depth: 2 };
    });
  }

  if (campaign.subRows && campaign.adsets && campaign.adsets.length !== 0) {
    subArray = campaign.subRows.map((item) => {
      return { ...item, depth: 3 };
    });
  }
  if (campaign.subRows && campaign.ads && campaign.ads.length !== 0) {
    subArray = campaign.subRows.map((item) => {
      return { ...item, depth: 4 };
    });
  }

  return subArray;
};

const isLastItemFn = (index: number, currData: ExtendedCampaign[]): boolean => index === currData.length - 1;

const insertItem = (arr: any[], ind: number, newItem: any, currData: ExtendedCampaign[]) =>
  isLastItemFn(ind, currData) ? [...arr, ...newItem] : [...arr.slice(0, ind + 1), ...newItem, ...arr.slice(ind + 1)];

const getKeptSubItemsArray = (currData: ExtendedCampaign[], campaign: ExtendedCampaign) => {
  const newFilteredArray: ExtendedCampaign[] = currData.filter((currCamp) => {
    const toEliminate =
      campaign.rootId === currCamp.rootId && currCamp.id !== campaign.id && campaign.depth < currCamp.depth;

    return !toEliminate;
  });

  return newFilteredArray.map((item) => {
    let newItem = { ...item, lastExpanded: false };

    if (item.id === campaign.id) {
      newItem = { ...item, expanded: !item.expanded, lastExpanded: false };
      return newItem;
    }

    if (campaign.parentId === item.id) {
      newItem = { ...item, lastExpanded: true };
      return newItem;
    }

    return newItem;
  });
};

const getKeptMainItemsArray = (currData: ExtendedCampaign[], campaign: ExtendedCampaign) => {
  const newFilteredArray: ExtendedCampaign[] = currData.filter((currCamp) => {
    return (campaign.rootId !== currCamp.rootId || currCamp.main) && campaign.depth <= currCamp.depth;
  });

  const keptItemsArray: ExtendedCampaign[] = newFilteredArray.map((item) => {
    let newItem = { ...item, lastExpanded: false };

    if (item.id === campaign.id) {
      newItem = { ...newItem, expanded: !item.expanded };
    }
    return newItem;
  });

  return keptItemsArray;
};

const getExpandedArray = (
  currData: ExtendedCampaign[],
  index: number,
  subRowArray: ExtendedCampaign[],
  campaign: ExtendedCampaign,
) => {
  const addedCampaignsArray = subRowArray.map((newItem) => {
    const addedCampaign: ExtendedCampaign = {
      ...newItem,
      expanded: false,
      lastExpanded: false,
      main: false,
      rootId: campaign.rootId ? campaign.rootId : Number(campaign.id) || -1,
      parentId: campaign.id ? Number(campaign.id) : campaign.rootId || -1,
    };

    return addedCampaign;
  });

  const modifiedCurrData: ExtendedCampaign[] = currData.map((item) => {
    let newItem = { ...item, lastExpanded: false };

    if (item.id === campaign.id) {
      newItem = { ...item, expanded: !item.expanded, lastExpanded: true };
    }
    return newItem;
  });

  const expandedArray: ExtendedCampaign[] = insertItem(modifiedCurrData, index, addedCampaignsArray, currData);

  return expandedArray;
};

const expandCampaign = (
  currData: ExtendedCampaign[],
  index: number,
  subRowArray: ExtendedCampaign[],
  campaign: ExtendedCampaign,
) => {
  let newCurrData: ExtendedCampaign[];

  if (!subRowArray || subRowArray.length === 0) {
    return;
  }
  if (campaign.expanded && campaign.main) {
    newCurrData = getKeptMainItemsArray(currData, campaign);
    store.dispatch(setExtendedCampaigns(newCurrData));
    return;
  }

  if (campaign.expanded) {
    newCurrData = getKeptSubItemsArray(currData, campaign);
    store.dispatch(setExtendedCampaigns(newCurrData));
    return;
  }

  newCurrData = getExpandedArray(currData, index, subRowArray, campaign);

  store.dispatch(setExtendedCampaigns(newCurrData));
};

const getCampaignName = (campaign: ExtendedCampaign) => {
  if (campaign.name && campaign.name.length <= 20) {
    return campaign.name;
  }
  if (campaign.name) {
    return `${campaign.name.slice(0, 20)}...`;
  }

  return "";
};
