import { IntegrationDetails } from "../redux/types";
import { getHasArrayErrorStatus } from "./getHasArrayErrorStatus";

export interface EcommerceProviderIntegrationStates {
  isGlobalEcommerceProviderError: boolean;
}

export const getEcommerceProviderIntegrationStates = (
  integrationDetailsData: IntegrationDetails | undefined,
): EcommerceProviderIntegrationStates => {
  const statusArray = [
    integrationDetailsData?.ecommerceProvidersData.pageView.status,
    integrationDetailsData?.ecommerceProvidersData.conversion.status,
  ];

  const isGlobalEcommerceProviderError = getHasArrayErrorStatus(statusArray);

  return { isGlobalEcommerceProviderError };
};
