import React from "react";
import classNames from "classnames";

import { Button, ButtonSize, ButtonVariant, OnClickHandler } from "../../../components/Button/Button";
import { Container } from "../../../components/Container/Container";
import { Image } from "../../../components/Image/Image";
import { useGetBreakpointProperties } from "../../../hooks/screen/useGetBreakpointProperties";
import { AlertBannerTextComponentCommon } from "./AlertBannerTextComponent/AlertBannerTextComponentCommon";
import { AlertBannerTextComponentUnapproved } from "./AlertBannerTextComponent/AlertBannerTextComponentUnapproved";
import { AlertBannerName } from "./types";

import styles from "./AlertBanner.module.scss";

export enum BannerType {
  GRAY = "gray",
  BLUE = "blue",
  RED = "red",
}

export interface AlertBannerVariant {
  name: AlertBannerName;
  title: string;
  text?: string;
  imageName: string;
  linkText?: string;
  linkUrl?: string;
  button?: {
    text: string;
    variant: ButtonVariant;
  };
  onButtonClick?: OnClickHandler;
  isLoading?: boolean;
  bannerType: BannerType;
}

export const AlertBanner: React.FC<AlertBannerVariant> = ({
  name,
  title,
  imageName,
  button,
  onButtonClick,
  text,
  linkText,
  linkUrl,
  isLoading,
}) => {
  const { isMobile } = useGetBreakpointProperties();
  const appliedContainerClassNames = classNames([styles.container, styles[name]]);
  return (
    <Container className={appliedContainerClassNames}>
      <div className={styles.content}>
        <div className={styles.left}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.textContainer}>
            {name === AlertBannerName.PAYMENT_UNAPPROVED ? (
              <AlertBannerTextComponentUnapproved />
            ) : (
              <AlertBannerTextComponentCommon text={text} linkText={linkText} linkUrl={linkUrl} name={name} />
            )}
          </div>
          {button && (
            <Button onClick={onButtonClick} variant={button?.variant} size={ButtonSize.MEDIUM} loading={isLoading}>
              {button.text}
            </Button>
          )}
        </div>
        {!isMobile && (
          <div className={styles.right}>
            <Image src={imageName} alt={imageName} height="160px" />
          </div>
        )}
      </div>
    </Container>
  );
};
