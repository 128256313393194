export interface VideoIds {
  [key: string]: {
    [key: string]: string;
  };
}
export const videoIds: VideoIds = {
  shopify: {
    event_snippet: "686175687",
    global_tracking_code: "686175687",
  },
  facebook: {
    add_url_parameters: "686176214",
    tracking: "686176214",
  },
  woocommerce: {
    global_tracking_code: "686176095",
    event_snippet: "686176095",
  },
  //   for now all ids below point to the shopify video - add corresponding videos later!
  mycashflow: {
    global_tracking_code: "686175687",
    event_snippet: "686175687",
  },
  shoptet: {
    global_tracking_code: "686175687",
    event_snippet: "686175687",
  },
  custom: {
    global_tracking_code: "686175687",
    event_snippet: "686175687",
  },
};
