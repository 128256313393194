import "react-date-range/dist/styles.css"; // main css file
import "./DatePickerTheme.scss"; // theme css file

import React, { useState } from "react";

import { useSetDateUrlParams } from "../../hooks/url/useSetDateUrlParams";
import { getUrlDateRange } from "../../utils/formatters/dateFormatters";
import { DatePickerButton } from "./DatePickerButton";
import { DatePickerCalendar } from "./DatePickerCalendar";

import styles from "./DatePicker.module.scss";

export const DatePicker: React.FC = () => {
  const { datePickerState, setDatePickerState, setUrlParams } = useSetDateUrlParams();
  const { from, to } = getUrlDateRange();
  const [isOpen, setIsOpen] = useState(false);

  const isDifferentThanLastTime =
    JSON.stringify(datePickerState) !==
    JSON.stringify({
      startDate: from,
      endDate: to,
    });

  const handleOpen = (): void => {
    setIsOpen((prev) => !prev);
    if (isOpen && isDifferentThanLastTime) {
      setUrlParams();
    }
  };
  const datePickerCalendarProps = { datePickerState, setDatePickerState, handleOpen };
  const datePickerButtonProps = { isOpen, handleOpen };

  return (
    <div className={styles.DatePicker}>
      <DatePickerButton {...datePickerButtonProps} />
      {isOpen && <DatePickerCalendar {...datePickerCalendarProps} />}
    </div>
  );
};
