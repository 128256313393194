import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { Input, InputStyle } from "../../components/Input/Input";
import { UserInput } from "./PersonalSettingsForm";

interface LastNameInputComponentProps {
  formHandler: UseFormReturn<UserInput, any>;
}

export const LastNameInputComponent: React.FC<LastNameInputComponentProps> = ({ formHandler }) => {
  const registerLastNameOptions = {
    required: "This field is required",
    pattern: { value: /\S/, message: "Name cannot be empty" },
  };

  const {
    register,
    formState: { errors },
  } = formHandler;

  return (
    <div>
      <Input
        label="Last Name*"
        type="text"
        error={!!errors.lastName}
        {...register("lastName", registerLastNameOptions)}
        inputStyle={InputStyle.VERTICAL}
        icon="user"
      />
      <ErrorMessage
        errors={errors}
        name="lastName"
        render={({ message }) => (
          <span role="alert" className="errorMessage">
            {message}
          </span>
        )}
      />
    </div>
  );
};
