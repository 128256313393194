import { useMutation, useQuery } from "react-query";

import { useGetCurrentWorkspaceId } from "../hooks/workspace/useGetCurrentWorkspaceId";
import { showNotification } from "../redux/reducers/notificationReducer";
import { store } from "../redux/store";
import {
  GoogleAccessibleCustomer,
  GoogleSignInRedirect,
  IntegrationDetails,
  IntegrationEventBlock,
  IntegrationStatus,
  NotificationSeverity,
  Workspace,
} from "../redux/types";
import { defaultMutationFn, queryClient } from "./api";
import { getIntegrationDetailUrl, useGetIntegrationDetails } from "./workspace";

export const useSelectGoogleAccessibleCustomerMutation = () => {
  const workspaceId = useGetCurrentWorkspaceId();

  const mutationFunction = (googleAdId: string) => {
    const requestData = {
      workspaceId,
      googleAdId,
    };

    return defaultMutationFn(`google-tracking/choose-ad-account`, { data: requestData });
  };
  return useMutation(mutationFunction, {
    onMutate: (googleAdId) => {
      queryClient.setQueryData<IntegrationDetails | undefined>([getIntegrationDetailUrl(workspaceId)], (oldData) => {
        if (oldData) {
          const newData: IntegrationDetails = { ...oldData };
          newData.adProvidersData.google.adAccountId = googleAdId;

          return newData;
        }
      });
    },
    onSuccess: () => {
      store.dispatch(
        showNotification({ message: "Ad account successfully selected", severity: NotificationSeverity.SUCCESS }),
      );

      queryClient.invalidateQueries([getIntegrationDetailUrl(workspaceId)]);
    },
  });
};

export const useLogoutGoogleTrackingMutation = () => {
  const workspaceId = useGetCurrentWorkspaceId();

  const mutationFunction = () => {
    const requestData = {
      googleAdId: undefined,
    };

    return defaultMutationFn(`google-tracking/revoke-access/${workspaceId}`, { data: requestData, method: "POST" });
  };

  return useMutation(mutationFunction, {
    onMutate: () => {
      queryClient.setQueryData<IntegrationDetails | undefined>([getIntegrationDetailUrl(workspaceId)], (oldData) => {
        if (oldData) {
          const authenticationObject: IntegrationEventBlock = {
            status: IntegrationStatus.error,
            message: "No Google token found for this workspace, please sign in.",
          };
          const accountSelectionObject: IntegrationEventBlock = {
            status: IntegrationStatus.warning,
            message:
              "Before selecting which Google Ads account you want to track, you need to sign in with your Google account.",
          };
          const newData: IntegrationDetails = { ...oldData };
          newData.adProvidersData.google.authentication = authenticationObject;
          newData.adProvidersData.google.accountSelection = accountSelectionObject;

          return newData;
        }
      });
    },
    onSuccess: () => {
      store.dispatch(
        showNotification({ message: "You have revoked Google Authentication", severity: NotificationSeverity.SUCCESS }),
      );

      const workspaceDetailUrl = `workspace/${workspaceId}`;
      queryClient.setQueryData<Workspace | undefined>([workspaceDetailUrl], (oldData) => {
        if (oldData) {
          return { ...oldData, googleAdId: null };
        }
      });
    },
    onError: () => {
      queryClient.invalidateQueries([getIntegrationDetailUrl(workspaceId)]);
    },
  });
};

export const useGetGoogleAdAccounts = () => {
  const workspaceId = useGetCurrentWorkspaceId();
  const googleAdAccountsUrl = `google-tracking/accessible-ad-accounts/${workspaceId}`;

  const { data: integrationDetails } = useGetIntegrationDetails();
  const google = integrationDetails?.adProvidersData.google;
  const isFullyConnected =
    google &&
    google.urlParameters.status === IntegrationStatus.success &&
    google.authentication.status === IntegrationStatus.success;

  return useQuery<GoogleAccessibleCustomer[]>([googleAdAccountsUrl], {
    enabled: !!isFullyConnected,
  });
};

export const useGetGoogleSignIn = () => {
  const workspaceId = useGetCurrentWorkspaceId();
  const googleSignInUrl = `google-tracking/sign-in?workspaceId=${workspaceId}&redirectUrl=${window.location.origin}/authentication/result`;

  return useQuery<GoogleSignInRedirect>([googleSignInUrl], {
    onSuccess: (data: GoogleSignInRedirect) => {
      window.location.href = data.redirectUrl;
    },
  });
};
