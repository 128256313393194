import React from "react";
import { Link } from "react-router-dom";
import { AnimateSharedLayout, motion } from "framer-motion";

import { useGetMainMenuItems } from "../../hooks/componentSpecific/useGetMainMenuItems";
import { Image } from "../Image/Image";
import { MenuItem } from "../MenuItem/MenuItem";
import { PlanUsage } from "./PlanUsage/PlanUsage";

import styles from "./MainMenu.module.scss";

export const MainMenu: React.FC = () => {
  const { visibleMainMenuItems } = useGetMainMenuItems();

  return (
    <div className={styles.SidePanel}>
      <Link className={styles.logo} to="/dashboard">
        <Image src="logo.svg" alt="ecomtrack logo" height="50px" width="70%" />
      </Link>
      <AnimateSharedLayout>
        <div className={styles.navigation}>
          <motion.div className={styles.wrapper}>
            {visibleMainMenuItems.map((item, idx) => (
              <MenuItem {...item} key={item.text} index={idx} />
            ))}
          </motion.div>
        </div>
      </AnimateSharedLayout>
      <PlanUsage />
    </div>
  );
};
