import { adsMenuItems, IAdsMenuItem } from "../containers/IntegrationPage/IntegrationMenu/IntegrationMenu";
import { Workspace } from "../redux/types";

export const getSelectedAdProviders = (workspaceDetailData: Workspace | undefined): IAdsMenuItem[] => {
  let selectedObjArr: IAdsMenuItem[] = [];

  if (!workspaceDetailData) {
    return selectedObjArr;
  }

  const selectedObjectsArray = workspaceDetailData?.adProviders.map((adProvider) => {
    return adsMenuItems.find((menuItem) => adProvider === menuItem.name) as IAdsMenuItem;
  });

  if (selectedObjectsArray) {
    selectedObjArr = selectedObjectsArray;
  }

  return selectedObjArr.sort((a, b) => a.text.localeCompare(b.text));
};
