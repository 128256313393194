import React, { useEffect, useState } from "react";

import styles from "./CheckBox.module.scss";

interface CheckBoxProps {
  checked?: boolean;
  label?: string;
  onChange: (checked: boolean) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ checked, label, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked ?? false);

  useEffect(() => {
    if (checked) setIsChecked(checked);
  }, [checked]);

  const handleChange = () => {
    setIsChecked((prev) => !prev);
    if (onChange) onChange(!isChecked);
  };

  return (
    <div>
      <input type="checkbox" checked={checked} onChange={handleChange} className={styles.checkbox} id={`checkbox`} />
      {label && (
        <label style={{ cursor: "pointer" }} htmlFor="checkbox">
          {label}
        </label>
      )}
    </div>
  );
};
