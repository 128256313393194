import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";

import { TableHeaderCellProps } from "../../../redux/types/table";
import { EcomtrackVsFbColumnHeader } from "../../EcomtrackVsFbColumnHeader/EcomtrackVsFbColumnHeader";
import { Icon } from "../../Icon/Icon";
import { getCellFlexTableCellAlignmentClassName } from "../Table";

import styles from "./TableHeader.module.scss";

export const TableHeaderCell: React.FC<TableHeaderCellProps> = ({ sortBy, setSortBy, headerGroup, column }) => {
  const thisColumn: any = headerGroup.headers.find((header: any) => header.id === column.id);
  const isSorted = (): boolean => {
    let isColumnSorted = false;
    if (thisColumn) {
      if (thisColumn.sortedIndex === 0) {
        isColumnSorted = true;
      }
    }

    return isColumnSorted;
  };

  const handleSort = (column: any) => {
    const desc = !column.isSortedDesc;
    if (sortBy) {
      setSortBy([{ id: column.id, desc }, ...sortBy]);
      return;
    }

    setSortBy([{ id: column.id, desc }]);
  };

  const sortedDesc = isSorted() && thisColumn.isSortedDesc;
  const sortedAsc = isSorted() && !thisColumn.isSortedDesc;

  const appliedHeaderCellItemStyle = {
    flexGrow: column.flexGrow !== undefined ? column.flexGrow : column.minWidth,
    minWidth: column.minWidth,
    width: column.width,
    maxWidth: column.maxWidth,
  };

  const getAppliedHeaderCellClassName = () => {
    const appliedHeaderCellClassNamesArray = [
      styles.headerCell,
      styles[getCellFlexTableCellAlignmentClassName(column.alignment)],
    ];

    if (column.alignment) {
      appliedHeaderCellClassNamesArray.push(styles[column.alignment]);
    }
    if (column.canSort) {
      appliedHeaderCellClassNamesArray.push(styles.pointer);
    }

    const appliedHeaderCellClassName = classNames(appliedHeaderCellClassNamesArray);
    return appliedHeaderCellClassName;
  };

  return (
    <motion.th {...column.getHeaderProps()} style={appliedHeaderCellItemStyle}>
      <div className={getAppliedHeaderCellClassName()} onClick={() => column.canSort && handleSort(column)}>
        {column.isRealColumn && <Icon name="logo_square" width="14" height="14" />}
        {column.isEcomtrackVsFbColumn ? <EcomtrackVsFbColumnHeader /> : column.render("Header", { column })}
        {column.canSort && (
          <div className={styles.sortIcons}>
            <Icon name="sort-up" isActive={sortedDesc} />
            <Icon name="sort-down" isActive={sortedAsc} />
          </div>
        )}
      </div>
    </motion.th>
  );
};
