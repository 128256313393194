import React from "react";
import classNames from "classnames";

import { Icon } from "../../../components/Icon/Icon";
import { ExtendedCampaign } from "../../../redux/types";

import styles from "./CampaignItem.module.scss";

interface ExpandedCampaignRowProps {
  campaign: ExtendedCampaign;
  handleExpand: () => void;
  campaignName: string;
}

export const ExpandedCampaignRow: React.FC<ExpandedCampaignRowProps> = ({ campaign, handleExpand, campaignName }) => {
  const getAppliedExpandedListClassNames = () => {
    const appliedExpandedListClassNamesArray = [styles.expandedCampaignMobile];
    if (campaign.lastExpanded) {
      appliedExpandedListClassNamesArray.push(styles.stickyName);
    }
    const appliedExpandedListClassNames = classNames(...appliedExpandedListClassNamesArray);

    return appliedExpandedListClassNames;
  };

  return (
    <li onClick={handleExpand} className={getAppliedExpandedListClassNames()}>
      <div className={styles.nameContainer}>
        <div className={styles.name}>
          <p>Name:</p>
        </div>
      </div>

      <div className={styles.expandedChevronContainer}>
        <p className={styles.campaignName}>{campaignName}</p>
        <Icon name="chevron" rotation="180deg" width="14px" />
      </div>
    </li>
  );
};
