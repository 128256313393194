import React from "react";
import { useSelector } from "react-redux";

import { getExtendedCampaigns } from "../../../redux/selectors/campaigns";
import { CampaignItem } from "./CampaignItem";

export const CampaignsMobileList: React.FC = () => {
  const extendedCampaigns = useSelector(getExtendedCampaigns);

  return (
    <ul>
      {extendedCampaigns.map((campaign, i) => {
        const campaignItemProps = { campaign, index: i };
        return <CampaignItem key={campaign.id} {...campaignItemProps} />;
      })}
    </ul>
  );
};
