import React from "react";
import classNames from "classnames";

import { Icon } from "../../components/Icon/Icon";
import { EcommercePlatformObject } from "../../redux/types";
import { capitalizeString } from "../../utils/formatters/stringFormatters";

import styles from "./ChangeProviderModal.module.scss";

interface ProviderItemComponentProps {
  platformName: EcommercePlatformObject["platformName"];
  temporarilySelected: EcommercePlatformObject["platformName"];
  setTemporarilySelected: React.Dispatch<React.SetStateAction<EcommercePlatformObject["platformName"]>>;
}

export const ProviderItemComponent: React.FC<ProviderItemComponentProps> = ({
  platformName,
  temporarilySelected,
  setTemporarilySelected,
}) => {
  const isCurrentlySelectedProvider = platformName === temporarilySelected;
  const iconName = `ico-${platformName.toLowerCase()}-big`;

  const getAppliedProviderItemClasses = () => {
    const appliedProviderItemClassesArray = [styles.selectProviderItem];
    if (isCurrentlySelectedProvider) {
      appliedProviderItemClassesArray.push(styles.checked);
    }

    const appliedProviderItemClasses = classNames(appliedProviderItemClassesArray);
    return appliedProviderItemClasses;
  };
  const handleSelect = () => {
    setTemporarilySelected(platformName);
  };

  return (
    <div key={platformName} className={getAppliedProviderItemClasses()} onClick={handleSelect}>
      <input
        className={styles.platformRadioBtn}
        type="radio"
        value={platformName}
        checked={isCurrentlySelectedProvider}
        readOnly
      />
      <Icon name={iconName} width="60px" height="60px" />
      <span className={styles.platformName}>{capitalizeString(platformName)}</span>
    </div>
  );
};
