import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { SubscriptionStatus } from "../../redux/types";

interface SubscriptionStates {
  isSuccessSubscriptionStatus: boolean;
}

export const useGetSubscriptionStates = (): SubscriptionStates => {
  const { data: currentWorkspace } = useGetCurrentWorkspaceDetail();

  const isSuccessSubscriptionStatus =
    currentWorkspace?.subscriptionStatus === SubscriptionStatus.Active ||
    currentWorkspace?.subscriptionStatus === SubscriptionStatus.Ignored;

  return { isSuccessSubscriptionStatus };
};
