import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { PrivateRoute } from "../../PrivateRoute/PrivateRoute";
import { WorkspacePlanDetails } from "./WorkspacePlanDetails";
import { WorkspaceSettings } from "./WorkspaceSettings";
import { WorkspaceUserRoles } from "./WorkspaceUserRoles";

export const WorkspaceSetupContent: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/workspace-setup/settings" component={WorkspaceSettings} title="Workspace setup" />
      <PrivateRoute exact path="/workspace-setup/user-roles" component={WorkspaceUserRoles} title="Workspace setup" />
      <PrivateRoute
        exact
        path="/workspace-setup/plan-details"
        component={WorkspacePlanDetails}
        title="Workspace setup"
      />
      <Route>
        <Redirect to="/workspace-setup/settings" />
      </Route>
    </Switch>
  );
};
