import React, { forwardRef, useState } from "react";
import classNames from "classnames";

import { Skeleton, SkeletonType } from "../Skeleton/Skeleton";

import styles from "./Input.module.scss";

export enum InputStyle {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  icon?: string;
  error?: boolean;
  isLoading?: boolean;
  className?: string;
  inputStyle?: InputStyle;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ icon, required, error, isLoading, className, inputStyle, ...props }: InputProps, ref: any) => {
    const [focused, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    const getAppliedClassName = (initialClassName: string, root?: boolean): string => {
      const tempClassName = [styles[initialClassName]];
      if (className && root) {
        tempClassName.push(className);
      }

      if (inputStyle === InputStyle.VERTICAL) {
        tempClassName.push(styles[`${initialClassName}--vertical`]);
      }

      const appliedClassName = classNames(...tempClassName);

      return appliedClassName;
    };

    const appliedInputWrapperClassName = getAppliedClassName("inputWrapper", true);
    const appliedInputFieldWrapperClassName = getAppliedClassName("inputFieldWrapper");

    const getAppliedInputClassName = (): string => {
      const appliedInputClassNameArray = [styles.input];
      if (error) {
        appliedInputClassNameArray.push(styles.error);
      }
      if (icon) {
        appliedInputClassNameArray.push(styles.inputWithIcon);
      }

      const appliedInputClasses = classNames(...appliedInputClassNameArray);
      return appliedInputClasses;
    };

    const getIconUrlBackgroundString = (): string => {
      let iconUrlBackgroundString = `url("${process.env.PUBLIC_URL}/icons/${icon}`;
      const stringEnd = ".svg";
      if (focused) {
        iconUrlBackgroundString += "_active";
      }

      iconUrlBackgroundString += stringEnd;
      return iconUrlBackgroundString;
    };

    return (
      <div className={appliedInputWrapperClassName} onBlur={onBlur} onFocus={onFocus}>
        <label className={styles.label}>{props.label}</label>
        {isLoading ? (
          <Skeleton type={SkeletonType.INPUT} />
        ) : (
          <div className={appliedInputFieldWrapperClassName}>
            {icon && (
              <div
                className={styles.icon}
                style={{
                  background: getIconUrlBackgroundString(),
                }}
              />
            )}
            <div>
              <input {...props} className={getAppliedInputClassName()} required={required} ref={ref} />
            </div>
          </div>
        )}
      </div>
    );
  },
);
