import React from "react";

import { DashboardViewResponseDTO } from "../../../api/views/dashboard/dto";
import { Container } from "../../../components/Container/Container";
import { Skeleton, SkeletonVariant } from "../../../components/Skeleton/Skeleton";
import { CurrencyType } from "../../../redux/types";
import { getFormattedPrice } from "../../../utils/formatters/currencyFormatters";
import { getPercentageIncrease } from "../../../utils/formatters/numberFormatters";
import { LockedSection } from "../LockedSection/LockedSection";
import { OverviewRow } from "./OverviewRow";

import styles from "./Overview.module.scss";

export interface OverviewItem {
  title: string;
  subtitle?: string;
  icon: string;
  change?: number;
  accessor: string;
}

export const Overview: React.FC<{ data: DashboardViewResponseDTO; currency: CurrencyType }> = ({ data, currency }) => {
  const overviewItems = [
    {
      icon: "ico-revenue",
      title: getFormattedPrice(Math.round(data.currentPeriod.sumPurchasesValue * 100) / 100, currency),
      subtitle: "Total Revenue",
      change: getPercentageIncrease(data.previousPeriod.sumPurchasesValue, data.currentPeriod.sumPurchasesValue),
      accessor: "totalRevenue",
    },
    {
      icon: "ico-revenue",
      title: getFormattedPrice(Math.round(data.currentPeriod.sumPurchasesOrganicValue * 100) / 100, currency),
      subtitle: "Organic Revenue",
      accessor: "organicRevenue",
    },
    {
      icon: "ico-revenue-ads",
      title: getFormattedPrice(Math.round(data.currentPeriod.sumPurchasesValueFromAds * 100) / 100, currency),
      subtitle: "Revenue from Ads",
      change: getPercentageIncrease(
        data.previousPeriod.sumPurchasesValueFromAds,
        data.currentPeriod.sumPurchasesValueFromAds,
      ),
      accessor: "revenueFromAds",
    },
    {
      icon: "ico-total-spend",
      title: getFormattedPrice(Math.round(data.currentPeriod.spend * 100) / 100, currency),
      subtitle: "Total spend",
      change: getPercentageIncrease(data.previousPeriod.spend, data.currentPeriod.spend),
      accessor: "totalSpend",
    },
    {
      icon: "ico-no-ord",
      title: `${data.currentPeriod.sumPurchases}`,
      subtitle: "Number of orders",
      change: getPercentageIncrease(data.previousPeriod.sumPurchases, data.currentPeriod.sumPurchases),
      accessor: "numberOfOrders",
    },
    {
      icon: "ico-avg-val",
      title: getFormattedPrice(Math.round(Number(data.currentPeriod.avgSumPurchasesValue) * 100) / 100, currency),
      subtitle: "Average order value",
      change: getPercentageIncrease(
        data.previousPeriod.avgSumPurchasesValue,
        Number(data.currentPeriod.avgSumPurchasesValue),
      ),
      accessor: "averageOrderValue",
    },
  ];
  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <h3>Overview</h3>
      </div>
      <div className={styles.grid}>
        {overviewItems.map((item) => (
          <OverviewRow
            key={item.title}
            icon={item.icon}
            title={item.title}
            subtitle={item.subtitle}
            change={item.change}
          />
        ))}
      </div>
    </Container>
  );
};

export const LoadingOverview: React.FC = () => {
  return (
    <Container className={styles.overview}>
      <div className={styles.header}>
        <h3>Overview</h3>
      </div>
      <div className={styles.grid}>
        {[1, 2, 3, 4, 5].map((item) => (
          <Skeleton key={item} height={60} variant={SkeletonVariant.LIGHT} />
        ))}
      </div>
    </Container>
  );
};

export const LockedOverview: React.FC = () => {
  return (
    <Container className={styles.overview}>
      <div className={styles.header}>
        <h3>Overview</h3>
      </div>
      <LockedSection height={254} />
    </Container>
  );
};
