import React from "react";
import { Link, useLocation } from "react-router-dom";
import { AnimateSharedLayout, motion } from "framer-motion";

import { isAllowed, useGetUserRole } from "../../hooks/user/useGetUserRole";
import { WorkspaceRoleType } from "../../redux/types/workspace";
import { Icon } from "../Icon/Icon";

import styles from "./Menu.module.scss";

interface MenuProps {
  menuItems: IMenuItem[];
  children?: React.ReactNode | React.ReactNode[];
}

export interface IMenuItem {
  to: string;
  text: string;
  iconName: string;
  role?: WorkspaceRoleType;
  errorInfo?: boolean;
}

export const Menu: React.FC<MenuProps> = ({ menuItems, children }) => {
  const { pathname } = useLocation();
  const userRole = useGetUserRole();
  const allowedMenuItems = menuItems.filter((menuItem) =>
    !menuItem.role ? true : isAllowed(userRole, [menuItem.role]),
  );

  return (
    <>
      <AnimateSharedLayout>
        <motion.div className={styles.container}>
          {allowedMenuItems.map(({ to, text, iconName, errorInfo }) => {
            const isActive = pathname.startsWith(to);
            return (
              <Link key={to} to={to}>
                <div className={`${styles.menuItem} ${isActive ? styles.activeText : ""}`}>
                  <Icon isActive={isActive} name={iconName} className={styles.icon} />
                  {isActive && <motion.div className={styles.active} layoutId="active" />}
                  <span className={styles.text}>{text}</span>
                  {errorInfo && <Icon name="ico-hint-red" width="16" className={styles.errorIcon} />}
                </div>
              </Link>
            );
          })}
        </motion.div>
      </AnimateSharedLayout>
      {children}
    </>
  );
};
