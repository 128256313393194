import React from "react";

import { BaseDivider } from "../Divider/BaseDivider";

import styles from "./Container.module.scss";

interface HeaderSegmentProps {
  child: React.ReactNode;
}

export const HeaderSegment: React.FC<HeaderSegmentProps> = ({ child }) => (
  <>
    <div className={styles.headerSegment}>
      <div className={styles.headerSegmentContent}>{child}</div>
    </div>
    <BaseDivider />
  </>
);
