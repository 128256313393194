import React from "react";

import { useGetLoggedInUser } from "../../api/user";
import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { ProfilePicture } from "../ProfilePicture/ProfilePicture";
import { UserMenu } from "../UserMenu/UserMenu";

import styles from "./UserInfo.module.scss";

export const UserInfo: React.FC = () => {
  const { data } = useGetLoggedInUser();
  const { isMobile } = useGetBreakpointProperties();

  return (
    <div className={styles.container}>
      {!isMobile && (
        <div>
          <div className={styles.name}>{`${data?.firstName} ${data?.lastName}`}</div>
        </div>
      )}
      <div className={styles.left}>
        {data ? (
          <ProfilePicture
            profilePictureUrl={data.profilePicture}
            firstName={data.firstName}
            lastName={data.lastName}
            size={40}
          />
        ) : (
          ""
        )}
        <UserMenu />
      </div>
    </div>
  );
};
