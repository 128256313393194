import React from "react";
import ReactDOM from "react-dom";
import { UseMutationResult } from "react-query";
import { useHistory } from "react-router";
import { AnimatePresence, motion } from "framer-motion";

import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { useGetUserRoles } from "../../hooks/user/useGetUserRoles";
import { SubscriptionStatus, Workspace } from "../../redux/types";
import { PRICING_URL } from "../../utils/constants/urlStrings";
import { invalidateSession } from "../../utils/localStorageHandlers";
import { WorkspaceSwitchCard } from "./WorkspaceSwitchCard/WorkspaceSwitchCard";

import styles from "./WorkspaceSwitch.module.scss";

interface WorkspaceSwitchProps {
  switchVisible: boolean;
  onClose?: () => void;
  //We need the mutation passed down from Header in order to correctly show a loading spinner there
  getWorkspace?: UseMutationResult<Workspace, unknown, number, unknown>;
}

export const WorkspaceSwitch: React.FC<WorkspaceSwitchProps> = ({ switchVisible, onClose, getWorkspace }) => {
  const history = useHistory();
  const roles = useGetUserRoles();
  const { isMobile } = useGetBreakpointProperties();

  const unpaidWorkspaces = roles.filter(({ workspace }) => {
    const isWorkspaceUnpaid =
      workspace.subscriptionStatus &&
      (workspace.subscriptionStatus === SubscriptionStatus.Error ||
        workspace.subscriptionStatus === SubscriptionStatus.Empty);

    return isWorkspaceUnpaid;
  });
  const userHasNoPaidWorkspaces = unpaidWorkspaces.length === roles.length;
  const sortedRoles = roles?.slice().sort((a, b) => a.workspace.name.localeCompare(b.workspace.name));
  const appliedStyles = { gridTemplateColumns: `repeat(${sortedRoles.length === 1 ? 1 : 2},1fr)` };

  const goToAddNewWorkspacePage = () => {
    if (onClose) onClose();
    history.push("/add-workspace");
  };

  return ReactDOM.createPortal(
    <AnimatePresence initial>
      {switchVisible && (
        <motion.div className={styles.container} variants={animation} initial="hidden" animate="visible" exit="exit">
          <div className={styles.wrapper}>
            <div className={styles.title}>Select workspace.</div>
            <div className={styles.workspaceCards} style={appliedStyles}>
              {sortedRoles.map(({ workspace }) => {
                const workspaceSwitchCardProps = { workspace, onClose, getWorkspace };

                return <WorkspaceSwitchCard key={workspace.id} {...workspaceSwitchCardProps} />;
              })}
            </div>
          </div>
          <div className={styles.bottomTextContainer}>
            <div className={styles.bottomText}>
              {!isMobile && (
                <p>
                  {"*)"} Detailed ecomtrack pricing is displayed{" "}
                  <a href={PRICING_URL} target="_blank" rel="noreferrer">
                    here.
                  </a>
                </p>
              )}
              <div className={styles.newWorkspace} onClick={goToAddNewWorkspacePage}>
                <div className={styles.text}>
                  <p className={styles.name}>Create new</p>
                  <p className={styles.price}>$99/month*</p>
                </div>
                <div className={styles.plus}>+</div>
              </div>
              {userHasNoPaidWorkspaces && (
                <p className={styles.logout} onClick={invalidateSession}>
                  Log out
                </p>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById("root") as HTMLElement,
  );
};

const animation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      type: "easein",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      type: "easein",
    },
  },
};
