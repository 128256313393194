import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { ChangePasswordForm } from "../../forms/ChangePasswordForm/ChangePasswordForm";
import { PersonalSettingsForm } from "../../forms/PersonalSettingsForm/PersonalSettingsForm";
import { WorkspaceRoleType } from "../../redux/types";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { WorkspaceManager } from "./WorkspaceManager/WorkspaceManager";

import styles from "./UserSettingsPage.module.scss";

interface UserSettingsContentProps {}

export const UserSettingsContent: React.FC<UserSettingsContentProps> = () => {
  const currentWorkspace = useGetCurrentWorkspaceDetail();

  if (currentWorkspace.isLoading) {
    return (
      <div className={styles.layout}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className={styles.column}>
      <Switch>
        <PrivateRoute
          exact
          path="/user/settings/personal-settings"
          component={PersonalSettingsForm}
          title="User Settings"
        />
        <PrivateRoute
          exact
          path="/user/settings/change-password"
          component={ChangePasswordForm}
          title="User Settings"
        />
        <PrivateRoute
          exact
          path="/user/settings/workspace-manager"
          component={WorkspaceManager}
          title="User Settings"
          requiredRoles={[WorkspaceRoleType.ADMIN]}
        />
        <Route>
          <Redirect to="/user/settings/personal-settings" />
        </Route>
      </Switch>
    </div>
  );
};
