import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { GetLoggedInUserResponseDTO, NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { UserDetailDTO } from "../dto/request/user-detail";
import { getCurrentUserUrl } from "../get-logged-in";
import { UpdateUserDetailsRequestDTO } from "./request";

export const useUpdateUser = () => {
  return useMutation((data: UpdateUserDetailsRequestDTO) => defaultMutationFn("user", { data, method: "PUT" }), {
    onSuccess: (data: UserDetailDTO) => {
      // optimistic update
      queryClient.setQueryData<GetLoggedInUserResponseDTO | undefined>([getCurrentUserUrl], (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            ...data,
          };
        }
      });

      store.dispatch(
        showNotification({ message: "User successfully updated", severity: NotificationSeverity.SUCCESS }),
      );
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([], context);
    },
  });
};
