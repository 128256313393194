import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { GetLoggedInUserResponseDTO, NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { getCurrentUserUrl } from "../get-logged-in";

const deleteProfilePictureUrl = "user/profile-picture";

export const useDeleteProfilePicture = () => {
  return useMutation(() => defaultMutationFn(deleteProfilePictureUrl, { method: "DELETE" }), {
    onSuccess: () => {
      queryClient.setQueryData<GetLoggedInUserResponseDTO | undefined>([getCurrentUserUrl], (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            profilePicture: null,
          };
        }
      });
      store.dispatch(
        showNotification({ message: "Profile picture successfully deleted", severity: NotificationSeverity.INFO }),
      );
    },
    onError: (_, __, context: GetLoggedInUserResponseDTO | undefined) => {
      queryClient.setQueryData<GetLoggedInUserResponseDTO | undefined>([getCurrentUserUrl], (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            profilePicture: context?.profilePicture,
          };
        }
      });
    },
  });
};
