import React, { CSSProperties } from "react";

import { Icon } from "../../../components/Icon/Icon";

import styles from "./LockedSection.module.scss";

interface LockedSectionProps {
  height: CSSProperties["height"];
}

export const LockedSection: React.FC<LockedSectionProps> = ({ height }) => {
  return (
    <div style={{ height }} className={styles.container}>
      <div className={styles.content}>
        <Icon name="ico-lock-small-gray" />
        <p className={styles.text}>Available after payment</p>
      </div>
    </div>
  );
};
