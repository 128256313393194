import React from "react";
import classNames from "classnames";

import styles from "./Skeleton.module.scss";

export enum SkeletonType {
  INPUT = "input",
  SKELETON = "skeleton",
}

export enum SkeletonVariant {
  LIGHT = "light",
  DARK = "dark",
}

interface SkeletonProps {
  height?: number;
  width?: number;
  round?: boolean;
  type?: SkeletonType;
  variant?: SkeletonVariant;
}

export const Skeleton: React.FC<SkeletonProps> = ({
  height,
  width,
  round,
  type = SkeletonType.SKELETON,
  variant = SkeletonVariant.LIGHT,
}) => {
  const appliedPlaceholderStyles = { height: height ?? "24px", width: width ?? "100%" };

  const getAppliedPlaceholderClassName = () => {
    const appliedPlaceholderClassesArray = [styles.placeholder];
    if (round) {
      appliedPlaceholderClassesArray.push(styles.round);
    }
    appliedPlaceholderClassesArray.push(styles[type]);
    appliedPlaceholderClassesArray.push(styles[variant]);

    const appliedPlaceholderClasses = classNames(...appliedPlaceholderClassesArray);
    return appliedPlaceholderClasses;
  };

  return (
    <div className={styles.container}>
      <div className={getAppliedPlaceholderClassName()} style={appliedPlaceholderStyles} />
    </div>
  );
};
