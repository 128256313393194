import React from "react";
import { useSelector } from "react-redux";

import { useGetDashboard } from "../../api/views";
import { DashboardViewResponseDTO } from "../../api/views/dashboard/dto";
import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { getAttribution } from "../../redux/selectors/app";
import { AttributionType, CurrencyType, DataContentStatus, SubscriptionStatus } from "../../redux/types";
import { getUrlDateRange } from "../../utils/formatters/dateFormatters";
import { isDangerSubscriptionStatus } from "../../utils/isDangerSubscriptionStatus";
import { AlertBanner, AlertBannerVariant } from "./AlertBanner/AlertBanner";
import { useGetBannerToShow } from "./AlertBanner/useLoadAlertBannerProperties";
import { BannerCard } from "./BannerCard/BannerCard";
import { bannerCardsArray } from "./BannerCard/usedCards";
import { Campaigns, LoadingCampaigns, LockedCampaigns } from "./Campaigns/Campaigns";
import { LoadingOverview, LockedOverview, Overview } from "./Overview/Overview";
import { LoadingSummary, LockedSummary, Summary } from "./Summary/Summary";

import styles from "./DashboardPage.module.scss";

export const DashboardPage: React.FC = () => {
  const { data: workspace, isLoading: isLoadingWorkspace } = useGetCurrentWorkspaceDetail();
  const { from, to } = getUrlDateRange();
  const attribution = useSelector(getAttribution);
  const isDangerSubStatus = isDangerSubscriptionStatus(workspace?.subscriptionStatus);
  const {
    isLoading,
    data,
    error: dashboardError,
  } = useGetDashboard({
    from,
    to,
    workspaceId: workspace?.id,
    attributionType: attribution ?? AttributionType.lastClick,
  });
  const alertBannerToShow = useGetBannerToShow(
    workspace?.subscriptionStatus ?? SubscriptionStatus.Empty,
    data?.dataContentStatus ?? DataContentStatus.NO_DATA,
  );

  if (isLoadingWorkspace || isLoading || !workspace || !data || data.dataByDates.previousPeriod.length === 0) {
    return (
      <PageContainer>
        <LoadingDashboardSections />
        <ReferralBanners />
      </PageContainer>
    );
  }
  if (isDangerSubStatus || dashboardError) {
    return (
      <PageContainer>
        <LockedDashboardSections alertBanner={alertBannerToShow} />
        <ReferralBanners />
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      <OpenDashboardSections alertBanner={alertBannerToShow} data={data} currency={workspace.currency} />
      <ReferralBanners />
    </PageContainer>
  );
};

const LockedDashboardSections: React.FC<{ alertBanner: AlertBannerVariant | null }> = ({ alertBanner }) => {
  return (
    <div className={styles.content}>
      {alertBanner != null ? (
        <div className={styles.segmentContainer}>
          <AlertBanner {...alertBanner} />
        </div>
      ) : null}
      <div className={styles.segmentContainer}>
        <LockedCampaigns />
      </div>
      <div className={styles.segmentContainer}>
        <LockedSummary />
      </div>
      <div className={styles.segmentContainer}>
        <LockedOverview />
      </div>
    </div>
  );
};

const LoadingDashboardSections = () => {
  return (
    <div className={styles.content}>
      <div className={styles.segmentContainer}>
        <LoadingCampaigns />
      </div>
      <div className={styles.segmentContainer}>
        <LoadingSummary />
      </div>
      <div className={styles.segmentContainer}>
        <LoadingOverview />
      </div>
    </div>
  );
};

const OpenDashboardSections: React.FC<{
  data: DashboardViewResponseDTO;
  alertBanner: AlertBannerVariant | null;
  currency: CurrencyType;
}> = ({ data, alertBanner, currency }) => {
  return (
    <div className={styles.content}>
      {alertBanner != null ? (
        <div className={styles.segmentContainer}>
          <AlertBanner {...alertBanner} />
        </div>
      ) : null}
      <div className={styles.segmentContainer}>
        <Campaigns data={data} currency={currency} />
      </div>
      <div className={styles.segmentContainer}>
        <Summary {...data} />
      </div>
      <div className={styles.segmentContainer}>
        <Overview currency={currency} data={data} />
      </div>
    </div>
  );
};

const ReferralBanners: React.FC = () => {
  const { isLargeDesktop } = useGetBreakpointProperties();
  return isLargeDesktop ? (
    <div className={styles.banners}>
      {bannerCardsArray.map((props) => (
        <BannerCard key={props.text} {...props} />
      ))}
    </div>
  ) : null;
};
