import { Row } from "react-table";
import classNames from "classnames";

import { GetRowClassType } from "../../../redux/types/table";

import styles from "./CampaignsTable.module.scss";

export const getRowClassName: GetRowClassType = ({ rowNumber, expanded, rowId, rowsById }) => {
  const parentRowId = rowId.split(".").slice(0, -1).join("."); // string
  const parentParentRowId = rowId.split(".").slice(0, -2).join("."); // string
  const thisRow = rowsById[rowId];
  const parentRow = rowsById[parentRowId];
  const parentParentRow = rowsById[parentParentRowId];
  const rowClassNameArray = [];
  const getLastIndex = (obj: Row<Record<string, any>>) => obj.subRows.length - 1;
  const getLastItemId = (obj: Row<Record<string, any>>) => obj.subRows[getLastIndex(obj)].id;
  const isCampaign = rowNumber === 1;
  const isAdset = rowNumber === 2;
  const isAd = rowNumber === 3;

  const isLastNotExpandedAdsetInExpandedCampaign =
    //@ts-ignore
    parentRow?.isExpanded &&
    isAdset &&
    rowId === getLastItemId(parentRow) &&
    //@ts-ignore
    !thisRow.isExpanded;

  const isLastAdInExpandedAdset =
    isAd &&
    rowId === getLastItemId(parentRow) &&
    //@ts-ignore
    parentRow.isExpanded &&
    parentRowId === getLastItemId(parentParentRow);

  let className = "";
  if (isCampaign) className = "campaign";
  if (isAdset) className = "adset";
  if (isAd) className = "ad";

  if (isLastAdInExpandedAdset) {
    className = "lastItem";
  }
  if (isLastNotExpandedAdsetInExpandedCampaign) {
    className = "lastItem";
  }

  rowClassNameArray.push(styles[className]);
  if (expanded) {
    const expandedClassName = className.concat("-expanded");
    rowClassNameArray.push(styles[expandedClassName]);
  }
  const appliedRowClassName = classNames(rowClassNameArray);

  return appliedRowClassName;
};
