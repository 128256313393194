import React from "react";

import { useGetBreakpointProperties } from "../../../../hooks/screen/useGetBreakpointProperties";
import { AlertBannerVariant } from "../AlertBanner";
import { AlertBannerName } from "../types";

import styles from "../AlertBanner.module.scss";
export type AlertBannerTextComponentProps = Pick<AlertBannerVariant, "text" | "linkText" | "name" | "linkUrl">;

export const AlertBannerTextComponentCommon: React.FC<AlertBannerTextComponentProps> = ({
  text,
  linkText,
  name,
  linkUrl,
}) => {
  const isPaymentError = name === AlertBannerName.PAYMENT_ERROR;
  const isNoData = name === AlertBannerName.NO_DATA;
  const { isMobile } = useGetBreakpointProperties();
  const isInlineLink = isMobile || isPaymentError || isNoData;

  return (
    <>
      <p className={styles.text}>
        {text}
        {isInlineLink && (
          <>
            {" "}
            <a target="_blank" rel="noreferrer" href={linkUrl}>
              {linkText}
            </a>
            {isPaymentError && "."}
          </>
        )}
      </p>
      {linkText && !isInlineLink && (
        <a target="_blank" rel="noreferrer" href={linkUrl}>
          {linkText}
        </a>
      )}
    </>
  );
};
