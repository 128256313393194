import React from "react";

import { useGetIntegrationDetails } from "../../../../api/workspace";
import { CardWithTitle } from "../../../../components/CardWithTitle/CardWithTitle";
import { CodeBox } from "../../../../components/CodeBox/CodeBox";
import { StatusStripe } from "../../../../components/StatusStripe/StatusStripe";
import { useGetEcommerceStatusStripeProperties } from "../../../../hooks/integration/useGetEcommerceStatusStripeProperties";
import { useGetIntegrationGlobalSiteTag } from "../../../../hooks/integration/useGetIntegrationGlobalSiteTag";
import { EcommercePlatformEnum } from "../../../../redux/types";

export const WooCommercePlatform: React.FC = () => {
  const { isFetching } = useGetIntegrationDetails();
  const { combinedParametersProps } = useGetEcommerceStatusStripeProperties();
  const globalSiteTag = useGetIntegrationGlobalSiteTag(EcommercePlatformEnum.wooCommerce);

  const codeText = `// ecomtrack hooks
add_action( 'wp_head', 'ecomtrack_tag' );
add_action( 'woocommerce_thankyou', 'ecomtrack_track_purchase' );

// put the tracking tag in <head>
function ecomtrack_tag() {
  ?>
${globalSiteTag}
  <?php
}

// conversion tracking script
function ecomtrack_track_purchase( $order_id ) {
  $order = wc_get_order( $order_id );

// prevent duplicate conversion reports
  $conversionTracked = get_post_meta( $order_id, "etag-".$order->get_transaction_id(), true );
  if ($order->get_subtotal() > 0 && empty($conversionTracked)) {
  update_post_meta( $order_id, "etag-".$order->get_transaction_id(), 1 )
  ?>
	<script>
		etag('event', 'conversion', {
		  'value': '<?php echo $order->get_subtotal(); ?>',
		  'order_id': '<?php echo $order->get_id(); ?>',
		  'currency': '<?php echo $order->get_currency(); ?>',
		  'transaction_id': '<?php echo $order->get_transaction_id(); ?>',
		  'customer_email': '<?php echo $order->get_billing_email(); ?>',
		  'customer_id': '<?php echo $order->get_customer_id(); ?>',
		  'customer_first_name': '<?php echo $order->get_shipping_first_name(); ?>',
		  'customer_last_name': '<?php echo $order->get_shipping_last_name(); ?>',
		  'customer_total_spent': '<?php echo $order->get_total(); ?>',
		  'customer_phone': '<?php echo $order->get_billing_phone() ?>',
		});
	</script>
  <?php
  }
}
`;

  return (
    <>
      <CardWithTitle
        iconName="ico-woocommerce-big"
        title="Global Tracking Code + Event Snippet"
        subtitle="WooCommerce"
        tag="global_tracking_code"
        videoControl={true}>
        <p>
          The global site tags tracks users accross your page. An event snippet tracks a conversion any time somebody
          clicks one of your ads and then later reaches the checkout page on your online store.
        </p>
        <p>You need to install the global site tag and an event snippet in your Wordpress page.</p>

        <ol>
          <li>
            <p>Open your Wordpress admin and click Appearance {">"} Theme Editor.</p>
          </li>
          <li>
            <p>On the right side, click on ’functions.php’.</p>
          </li>
          <li>
            <p>Paste the following code just above the {"’?>’"} tag.</p>
          </li>
        </ol>
        <CodeBox src={codeText} />
        <StatusStripe isLoading={isFetching} {...combinedParametersProps} />
      </CardWithTitle>
    </>
  );
};
