import { useMutation } from "react-query";

import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { createGetPricingPlanInfoUrl } from "../get-pricing-plan-info";

export const useConsentSubscriptionPriceUpgrade = () => {
  const workspaceId = useGetCurrentWorkspaceId();

  return useMutation(
    () =>
      defaultMutationFn(`billing/subscription/creation-session`, {
        method: "PATCH",
        data: {
          workspaceId,
        },
      }),
    {
      onSuccess: () => {
        store.dispatch(
          showNotification({ severity: NotificationSeverity.SUCCESS, message: "Your plan has been upgraded." }),
        );
      },
      onSettled: () => {
        const workspaceInfoUrl = `workspace/${workspaceId}`;
        queryClient.invalidateQueries([workspaceInfoUrl]);
        queryClient.invalidateQueries([createGetPricingPlanInfoUrl(workspaceId)]);
      },
    },
  );
};
