import React, { forwardRef } from "react";

import styles from "./RadioButton.module.scss";

interface RadioButtonProps extends React.HTMLProps<HTMLInputElement> {
  checked?: boolean;
  value: string;
  text: string;
}

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ checked, value, text, ...props }: RadioButtonProps, ref: any) => (
    <label className={styles.radioButton}>
      <div className={styles.radioButtonInner}>
        <input type="radio" value={value} {...props} ref={ref} defaultChecked={checked} />
        <span />
      </div>
      <span>{text}</span>
    </label>
  ),
);
