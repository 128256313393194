import React from "react";

import { useGetPdfFromCurrentDetails } from "../../api/views";
import { Button, ButtonSize, ButtonVariant } from "../../components/Button/Button";
import { Icon } from "../../components/Icon/Icon";

import styles from "./CampaignsPage.module.scss";

export const DownloadControls: React.FC = () => {
  const getPdf = useGetPdfFromCurrentDetails();
  const handlePrintExport = () => getPdf.mutate();

  return (
    <div className={styles.download}>
      {getPdf.isLoading ? (
        <div>
          {"Creating file..." + " "}
          <Icon name="spinner" width="12px" height="12px" className={styles.spin} />
        </div>
      ) : (
        <Button
          className={styles.downloadButton}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.SMALL}
          onClick={handlePrintExport}
          label="PDF download"
        />
      )}
    </div>
  );
};
