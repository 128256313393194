import React from "react";
import classNames from "classnames";

import { SubscriptionStatus } from "../../redux/types";
import { capitalizeString } from "../../utils/formatters/stringFormatters";
import { TariffUsageBar } from "../TariffUsageBar/TariffUsageBar";

import styles from "./TariffUsage.module.scss";

interface TariffUsageProps {
  percentage: number;
  status: SubscriptionStatus | undefined;
}

export const TariffUsage: React.FC<TariffUsageProps> = ({ percentage, status }) => {
  //so we don't overflow
  const displayedPercentage = percentage >= 100 ? 100 : percentage;
  const isEmpty = status === SubscriptionStatus.Empty;
  const isIgnored = status === SubscriptionStatus.Ignored;
  const hasValidPlan = status === SubscriptionStatus.Active || status === SubscriptionStatus.Unapproved;

  const getText = (status: SubscriptionStatus) => {
    let text = capitalizeString(status);
    if (isEmpty) {
      text = "Not tracking";
    }
    if (isIgnored) {
      text = "Unlimited";
    }

    return text;
  };

  const getAppliedTextClassNames = () => {
    const textClassNamesArray = [styles.text];
    if (displayedPercentage > 80) {
      textClassNamesArray.push(styles.orangeText);
    }
    if (displayedPercentage === 100) {
      textClassNamesArray.push(styles.redText);
    }

    const appliedTextClassNames = classNames(textClassNamesArray);
    return appliedTextClassNames;
  };

  return (
    <div className={styles.TariffUsage}>
      {!hasValidPlan ? (
        <p className={styles.textUnlimited}>{status && getText(status)}</p>
      ) : (
        <>
          <p className={getAppliedTextClassNames()}>{displayedPercentage}%</p>
          <div className={styles.tariffBarWrapper}>
            <TariffUsageBar percentage={percentage} />
          </div>
        </>
      )}
    </div>
  );
};
