import { useDispatch, useSelector } from "react-redux";

import { updateCampaignsCols } from "../../redux/reducers/campaignReducer";
import { getAllCampaignCols, getTemporaryCampaignCols } from "../../redux/selectors/campaigns";
import { CampaignColsEnum, ICampaignColsInput } from "../../redux/types";
import { TableColumn } from "../../redux/types/table";
import { updateLocalStorageCampaingsColumns } from "../../utils/localStorageHandlers";

interface CampaignsColumnsUtils {
  isColumnSelected: (col: TableColumn) => boolean;
  sortTemporaryArray: (a: TableColumn, b: TableColumn) => number;
  handleCheckmarkClick: (col: TableColumn) => void;
}

export const useGetCampaignsColumnsUtils = (): CampaignsColumnsUtils => {
  const campaignsTableCols = useSelector(getAllCampaignCols);
  const temporarySelectedCols = useSelector(getTemporaryCampaignCols);

  const dispatch = useDispatch();

  const isColumnSelected = (col: TableColumn): boolean => {
    const selectedColumn = temporarySelectedCols.find((column) => column.accessor === col.accessor);
    return Boolean(selectedColumn);
  };

  const sortTemporaryArray = (a: TableColumn, b: TableColumn): number => {
    return (
      campaignsTableCols.findIndex((col) => col.accessor === a.accessor) -
      campaignsTableCols.findIndex((col) => col.accessor === b.accessor)
    );
  };

  const handleCheckmarkClick = (col: TableColumn): void => {
    let updatedTemporaryCols: TableColumn[] = [...temporarySelectedCols, col];
    const isCurrentlySelected = temporarySelectedCols.some((column) => column.accessor === col.accessor);

    if (isCurrentlySelected) {
      updatedTemporaryCols = temporarySelectedCols.filter((column) => column.accessor !== col.accessor);
    }

    const sortedTemporarySelectedColumns = updatedTemporaryCols.slice().sort(sortTemporaryArray);

    const colsInput: ICampaignColsInput = {
      cols: sortedTemporarySelectedColumns,
      type: CampaignColsEnum.TEMPORARILY_SELECTED,
    };

    updateLocalStorageCampaingsColumns(sortedTemporarySelectedColumns);

    dispatch(updateCampaignsCols(colsInput));
  };

  return {
    isColumnSelected: isColumnSelected,
    sortTemporaryArray,
    handleCheckmarkClick,
  };
};
