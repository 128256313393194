/**
 * Calculates the percentage increase between two numbers
 *
 *
 */

export const getPercentageIncrease = (before: number | null, now: number): number => {
  let percentageIncrease = 0;
  if (Number(before) > 0) {
    percentageIncrease = Math.round(((now - Number(before)) / Number(before)) * 10000) / 100;
  }

  return percentageIncrease;
};
