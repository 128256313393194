import React from "react";

import { Icon } from "../../../components/Icon/Icon";

import styles from "../WorkspaceSetupPage.module.scss";

interface HighlightsCardProps {
  title: string;
  value: string;
  iconName: string;
}

export const HighlightsCard: React.FC<HighlightsCardProps> = ({ title, value, iconName }) => (
  <div className={styles.highlightsCard}>
    <Icon name={iconName} />
    <div>
      <h4>{title}</h4>
      <h5>{value}</h5>
    </div>
  </div>
);
