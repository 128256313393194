import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import { useGetIntegrationDetails } from "../../../api/workspace";
import { Icon } from "../../../components/Icon/Icon";
import { IMenuItem, Menu } from "../../../components/Menu/Menu";
import { useGetSelectedAdProviders } from "../../../hooks/integration/useGetSelectedAdProviders";
import { useGetSelectedEcommercePlatformObject } from "../../../hooks/integration/useGetSelectedEcommercePlatformObject";
import { useModal } from "../../../hooks/modal/useModal";
import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { AddAdProviderModal } from "../../../modals/AddAdProviderModal/AddAdProviderModal";
import { ChangeProviderModal } from "../../../modals/ChangeProviderModal/ChangeProviderModal";
import { AdProviderEnum } from "../../../redux/types";
import { capitalizeString } from "../../../utils/formatters/stringFormatters";
import { getAdProvidersIntegrationStates } from "../../../utils/getAdProvidersIntegrationStates";
import { getEcommerceProviderIntegrationStates } from "../../../utils/getEcommerceProviderIntegrationStates";

import styles from "./IntegrationMenu.module.scss";

export interface IAdsMenuItem {
  to: string;
  text: string;
  name: string;
  iconNameSmall: string;
  iconNameBig: string;
  errorInfo?: boolean;
}

export const adsMenuItems: IAdsMenuItem[] = [
  {
    to: "/integration/ad-providers/facebook",
    text: "Facebook",
    name: AdProviderEnum.FACEBOOK,
    iconNameSmall: "ico-facebookPixel",
    iconNameBig: "ico-facebook-big",
  },
  {
    to: "/integration/ad-providers/google",
    text: "Google Ads",
    name: AdProviderEnum.GOOGLE,
    iconNameSmall: "ico-googleads",
    iconNameBig: "ico-googleads-big",
  },
  {
    to: "/integration/ad-providers/tiktok",
    text: "TikTok Ads",
    name: AdProviderEnum.TIKTOK,
    iconNameSmall: "ico-tiktok",
    iconNameBig: "ico-tiktok-big",
  },
];

const useGetAdProvidersWithErrors = (selectedAdsMenuItems: IAdsMenuItem[]): IAdsMenuItem[] => {
  const { data: integrationDetails } = useGetIntegrationDetails();
  const selectedAdProviders = useGetSelectedAdProviders();
  const { isFbStatusError, isGoogleStatusError, isTikTokStatusError } = getAdProvidersIntegrationStates(
    integrationDetails,
    selectedAdProviders,
  );

  return selectedAdsMenuItems.map((menuItem) => {
    let errorInfo = false;
    switch (menuItem.name) {
      case AdProviderEnum.FACEBOOK:
        errorInfo = isFbStatusError;
        break;
      case AdProviderEnum.GOOGLE:
        errorInfo = isGoogleStatusError;
        break;
      case AdProviderEnum.TIKTOK:
        errorInfo = isTikTokStatusError;
        break;

      default:
        break;
    }

    const selectedAdsMenuItemWithErrors: IAdsMenuItem = {
      ...menuItem,
      errorInfo,
    };

    return selectedAdsMenuItemWithErrors;
  });
};

export const IntegrationMenu: React.FC = () => {
  const currentWorkspaceId = useGetCurrentWorkspaceId();
  const selectedAdsMenuItems = useGetSelectedAdProviders();
  const { data: integrationDetailsData } = useGetIntegrationDetails();
  const { isGlobalEcommerceProviderError } = getEcommerceProviderIntegrationStates(integrationDetailsData);
  const selectedAdsMenuItemsWithErrors = useGetAdProvidersWithErrors(selectedAdsMenuItems);
  const { pathname } = useLocation();
  const adsProvidersModal = useModal();
  const changeProviderModal = useModal();
  const history = useHistory();
  const selectedPlatformObject = useGetSelectedEcommercePlatformObject();

  useEffect(() => {
    if (pathname !== "/integration/ad-providers" || !selectedAdsMenuItemsWithErrors.length) {
      return;
    }

    history.push(`${selectedAdsMenuItemsWithErrors[0].to}`);
  }, [pathname, selectedAdsMenuItemsWithErrors]);

  const handleChangePlatformToggle = () => {
    changeProviderModal.toggle();
  };
  const handleAddAdProviderToggle = () => {
    adsProvidersModal.toggle();
  };

  const ecommerceMenuItems: IMenuItem[] = [
    {
      to: "/integration/ecommerce-provider",
      text: capitalizeString(selectedPlatformObject.platformName),
      iconName: `ico-${selectedPlatformObject.platformName.toLowerCase()}-small`,
      errorInfo: isGlobalEcommerceProviderError,
    },
  ];
  const appliedAdsMenuItems: IMenuItem[] = selectedAdsMenuItemsWithErrors.map(
    ({ to, text, iconNameSmall, errorInfo }) => {
      const adMenuItem: IMenuItem = {
        to,
        text,
        iconName: iconNameSmall,
        errorInfo,
      };

      return adMenuItem;
    },
  );

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <h3 className={styles.title}>ECOMMERCE PLATFORM</h3>
        <div>
          <Menu menuItems={ecommerceMenuItems} />
          <div className={styles.menuItemControl} onClick={handleChangePlatformToggle}>
            <Icon name="ico-change-provider" />
            <span className={styles.text}>Change platform</span>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <h3 className={styles.title}>AD PROVIDERS</h3>
        <div>
          <Menu menuItems={appliedAdsMenuItems} />
          <div className={styles.menuItemControl} onClick={handleAddAdProviderToggle}>
            <Icon name="ico-add" />
            <span className={styles.text}>Add new</span>
          </div>
        </div>
      </div>
      {currentWorkspaceId ? (
        <AddAdProviderModal
          toggle={adsProvidersModal.toggle}
          visible={adsProvidersModal.visible}
          platformObjects={adsMenuItems}
          selected={selectedAdsMenuItemsWithErrors}
        />
      ) : null}
      <ChangeProviderModal toggle={changeProviderModal.toggle} visible={changeProviderModal.visible} />
    </div>
  );
};
