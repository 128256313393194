import React from "react";
import { Link } from "react-router-dom";

import { Button, ButtonVariant } from "../../components/Button/Button";
import { Image } from "../../components/Image/Image";

import styles from "./RecoverPasswordPage.module.scss";

export const RecoveryLinkSentComponent: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.column}>
      <h1>Check your mailbox!</h1>
      <p className={styles.subtitle}>
        We have sent the recovery link to see your accurate data again. Just click on it. Set new password and you are
        good to go.
      </p>
    </div>
    <Link to="/login">
      <Button variant={ButtonVariant.PRIMARY}>Proceed to login</Button>
    </Link>
    <div className={styles.logo}>
      <Image src="logo.svg" alt="ecomtrack logo" />
    </div>
  </div>
);
