import { useMutation } from "react-query";

import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { defaultMutationFn } from "../../api";
import { CreatePricingPlanManagementSessionResponseDTO } from "./dto";

export const useManageSubscriptionPlan = () => {
  const workspaceId = useGetCurrentWorkspaceId();
  const returnUrl = `${window.location.origin}/workspace-setup/plan-details`;

  return useMutation<CreatePricingPlanManagementSessionResponseDTO>(
    () =>
      defaultMutationFn(`billing/subscription/management-session`, {
        method: "POST",
        data: { workspaceId, returnUrl },
      }),
    {
      onSuccess: (data) => {
        window.location.href = data.sessionUrl;
      },
    },
  );
};
