import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { WorkspaceWithRolesDTO } from "../get-one/dto";
import { RemoveUserFromWorkspaceRequestDTO } from "../set-ad-providers/dto";

export const useRemoveWorkspaceUserMutation = () => {
  return useMutation(
    (data: RemoveUserFromWorkspaceRequestDTO) => {
      return defaultMutationFn(`workspace/${data.workspaceId}/user`, {
        method: "DELETE",
        data: {
          email: data.email,
        },
      });
    },
    {
      onMutate: (vars) => {
        return queryClient.getQueryData([`workspace/${vars.workspaceId}`]);
      },
      onSuccess: async (_, vars) => {
        queryClient.setQueryData<WorkspaceWithRolesDTO | undefined>([`workspace/${vars.workspaceId}`], (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              roles: oldData.roles.filter((role) => role.email !== vars.email),
              invitedUsers: oldData.invitedUsers.filter((user) => user.email !== vars.email),
            };
          }
        });
        await queryClient.invalidateQueries([`workspace/${vars.workspaceId}`]);

        store.dispatch(
          showNotification({ message: "User successfully removed", severity: NotificationSeverity.SUCCESS }),
        );
      },

      onError: (_, vars) => {
        queryClient.setQueryData([`workspace/${vars.workspaceId}`], (old) => old);

        store.dispatch(
          showNotification({
            message: "The workspace data are not available at the moment.",
            severity: NotificationSeverity.ERROR,
          }),
        );
      },
    },
  );
};
