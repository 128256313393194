import React from "react";
import classNames from "classnames";

import { IntegrationStatus } from "../../redux/types";
import { StatusStripeProperties } from "../../utils/getStatusStripeProperties";
import { Skeleton } from "../Skeleton/Skeleton";

import styles from "./StatusStripe.module.scss";

interface StatusStripeProps extends StatusStripeProperties {
  isLoading?: boolean;
}

export const StatusStripe: React.FC<StatusStripeProps> = ({ status, text, title, isLoading, messages }) => {
  const appliedBannerClassNames = classNames([styles.status, styles[IntegrationStatus[status]]]);
  if (isLoading) {
    return (
      <div>
        <Skeleton height={50} />
      </div>
    );
  }

  return (
    <div className={styles.statusFooter}>
      <div className={styles.leftSegment}>
        <span className={styles.title}>{title}</span>
        {messages.map((message) => (
          <span key={message} className={styles.text}>
            {message}
          </span>
        ))}
      </div>
      <div className={appliedBannerClassNames}>{text}</div>
    </div>
  );
};
