import { MutationCache, QueryCache, QueryClient, QueryFunctionContext, QueryKey } from "react-query";
import axios, { AxiosRequestConfig } from "axios";

import { showNotification } from "../redux/reducers/notificationReducer";
import { store } from "../redux/store";
import { NotificationSeverity } from "../redux/types/notification";
import { removeJwtFromLocalStorage } from "../utils/localStorageHandlers";

const API_URL = process.env.REACT_APP_API_URL || "";

const mutationCache = new MutationCache({
  onError: (err: any) => {
    return handleErrors(err.response);
  },
});

const queryCache = new QueryCache({
  onError: (err: any) => {
    return handleErrors(err.response);
  },
});

const defaultQueryFn = async ({ queryKey, signal }: QueryFunctionContext<QueryKey, any>) => {
  const jwt: string = store.getState().app.jwt ?? "";

  const { data } = await axios.get(API_URL + "/" + queryKey[0], {
    ...defaultOptions,
    signal,
    headers: {
      ...defaultOptions.headers,
      "x-access-token": jwt,
      "Cache-Control": "no-store, max-age=0",
    },
  });

  return data;
};

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false, //process.env.NODE_ENV === "production",
      retry: false,
      staleTime: Infinity,
    },
  },
});

export const defaultMutationFn = async (endpoint: string, options?: AxiosRequestConfig) => {
  const jwt: string = store.getState().app.jwt ?? "";

  const { data } = await axios({
    url: API_URL + "/" + endpoint,
    method: options?.method ?? "POST",
    ...options,
    ...defaultOptions,
    headers: {
      ...options?.headers,
      ...defaultOptions.headers,
      "x-access-token": jwt,
    },
  });
  return data;
};

const defaultOptions: AxiosRequestConfig = {
  //@ts-ignore
  transformResponse: axios.defaults.transformResponse.concat((data) => {
    return data.data ?? data;
  }),
};

const handleErrors = (result: any) => {
  if (!result) return;
  if (result.status === 403) {
    store.dispatch(
      showNotification({
        message: "Permission denied",
        severity: NotificationSeverity.ERROR,
      }),
    );
    return;
  }

  if (result.status === 401) {
    removeJwtFromLocalStorage();
    store.dispatch(
      showNotification({
        message: "Token expired. Please log in again.",
        severity: NotificationSeverity.ERROR,
      }),
    );
    return;
  }

  store.dispatch(
    showNotification({
      message: result?.data?.error?.message ?? "Something went wrong. Please try again in a while.",
      severity: NotificationSeverity.ERROR,
    }),
  );
};
