import React, { CSSProperties, MouseEventHandler } from "react";

interface IconProps {
  name: string;
  width?: string;
  rotation?: string;
  style?: CSSProperties;
  height?: string;
  onClick?: MouseEventHandler;
  className?: string;
  isActive?: boolean;
}

export const Icon: React.FC<IconProps> = ({ name, width, rotation, style, height, onClick, className, isActive }) => {
  const getIconName = (): string => {
    let iconName = name;

    if (isActive) {
      iconName += "_active";
    }

    return iconName;
  };
  const iconSrc = `${process.env.PUBLIC_URL}/icons/${getIconName()}.svg`;

  const getAppliedTransformString = () => {
    let appliedTransformString = "";
    if (style && style.transform) {
      appliedTransformString = style.transform;
    }
    if (rotation) {
      appliedTransformString += ` rotate(${rotation})`;
    }

    return appliedTransformString;
  };

  return (
    <img
      className={className}
      src={iconSrc}
      alt={name}
      width={width}
      height={height}
      style={{
        ...style,
        transform: getAppliedTransformString(),
      }}
      onClick={onClick}
    />
  );
};
