import React, { useState } from "react";
import TimezoneSelect from "react-timezone-select";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { Icon } from "../../components/Icon/Icon";
import { SelectDropdownIndicator } from "../../components/Select/SelectDropdownIndicator";
import { Skeleton, SkeletonType } from "../../components/Skeleton/Skeleton";

import styles from "./WorkspaceForm.module.scss";

interface TimezoneDropdownComponentProps {
  selectedTimezone: string;
  setSelectedTimezone: React.Dispatch<React.SetStateAction<string>>;
  isMutationLoading: boolean;
}

interface TimezoneObject {
  value: string;
  label: string;
  offset: number;
  abbrev: string;
  altName: string;
}

export const TimezoneDropdownComponent: React.FC<TimezoneDropdownComponentProps> = ({
  selectedTimezone,
  setSelectedTimezone,
  isMutationLoading,
}) => {
  const workspace = useGetCurrentWorkspaceDetail();
  const [focusedT, setFocusedT] = useState(false);
  const onFocusT = () => setFocusedT(true);
  const onBlurT = () => setFocusedT(false);

  const handleTimezoneChange = (timezone: TimezoneObject) => {
    setSelectedTimezone(timezone.value);
  };

  return (
    <div>
      <div className={styles.timezone}>
        <label htmlFor="select">Timezone</label>
        {isMutationLoading || workspace.isLoading ? (
          <Skeleton type={SkeletonType.INPUT} />
        ) : (
          <div className={styles.inputWrapper} onBlur={onBlurT} onFocus={onFocusT}>
            <div className={styles.icon}>
              <Icon name="ico_clock" isActive={focusedT} width="24px" />
            </div>

            <TimezoneSelect
              isLoading={workspace.isLoading}
              value={selectedTimezone}
              className={styles.select}
              //@ts-ignore
              onChange={handleTimezoneChange}
              classNamePrefix="select"
              id="select"
              components={{ DropdownIndicator: SelectDropdownIndicator }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
