import React from "react";

import styles from "./Campaigns.module.scss";

interface DetailCardProps {
  card: CardDetail;
}

export interface CardDetail {
  label: string;
  value?: string;
  name: string;
}

export const DetailCard: React.FC<DetailCardProps> = ({ card }) => {
  const { label, value } = card;

  return (
    <div className={styles.detailCard}>
      <div className={styles.titleContainer}>
        <h6 className={styles.cardTitle}>{label}</h6>
      </div>
      <div className={styles.dataContainer}>
        <p className={styles.value}>{value}</p>
        <div className={styles.change}>
          <p></p>
        </div>
      </div>
    </div>
  );
};
