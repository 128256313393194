import { useSelector } from "react-redux";

import { getWorkspaceId } from "../../redux/selectors/workspace";
import { useGetUserRoles } from "../user/useGetUserRoles";

export const useGetCurrentWorkspaceId = (): number => {
  const roles = useGetUserRoles();
  const workspaceIdRedux = useSelector(getWorkspaceId);
  return workspaceIdRedux ?? roles[0]?.workspace?.id;
};
