import { useEffect, useState } from "react";

export const useMatchScreen = (width: number): boolean => {
  const [isScreenMatch, setIsScreenMatch] = useState(false);

  useEffect(() => {
    const handleMatchScreen = (): void => {
      if (window.innerWidth <= width) {
        setIsScreenMatch(true);
        return;
      }
      setIsScreenMatch(false);
    };

    handleMatchScreen();
    window.addEventListener("resize", handleMatchScreen);

    return () => window.removeEventListener("resize", handleMatchScreen);
  }, []);

  return isScreenMatch;
};
