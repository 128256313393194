import React from "react";

import { useConsentSubscriptionPriceUpgrade, useGetPricingPlanInfoForCurrent } from "../../api/billing";
import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { Button, ButtonVariant } from "../../components/Button/Button";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { Modal } from "../../components/Modal/Modal";
import { IModal } from "../../redux/types";
import { getFormattedPrice } from "../../utils/formatters/currencyFormatters";

import styles from "./WorkspaceOverLimitModal.module.scss";

type WorkspaceOverLimitModalProps = IModal;

export const WorkspaceOverLimitModal: React.FC<WorkspaceOverLimitModalProps> = ({ visible, toggle }) => {
  const { data } = useGetPricingPlanInfoForCurrent();
  const approve = useConsentSubscriptionPriceUpgrade();
  const currentWorkspace = useGetCurrentWorkspaceDetail();
  const pricingPlanInfo = data;
  const currency = pricingPlanInfo?.currency;
  const workspaceName = currentWorkspace.data?.name;
  const currentRevenue = getFormattedPrice(pricingPlanInfo?.currentRevenue ?? 0, currency);
  const nextPrice = getFormattedPrice(pricingPlanInfo?.next?.price ?? 0, currency);
  const nextRevenueLimit = getFormattedPrice(pricingPlanInfo?.next?.revenueMax ?? 0, currency);
  const maxLevel = pricingPlanInfo?.now?.revenueMax === 0;

  const handleWorkspaceClick = () => {
    approve.mutate();
    toggle();
  };
  const closeModal = () => {
    toggle();
  };

  return (
    <Modal visible={visible} animation="fadeIn" fitContent>
      <>
        {pricingPlanInfo ? (
          <>
            <div className={styles.content}>
              <h2 className={styles.title}>Please upgrade your plan</h2>
              <p className={styles.text}>
                <span>{workspaceName}</span> is doing really well and it has just hit {currentRevenue} in revenue.
                Upgrade your plan to keep the workspace tracked. Next plan lets you track{" "}
                {maxLevel ? (
                  "an unlimited amount"
                ) : (
                  <>
                    up to <span>{nextRevenueLimit}</span>
                  </>
                )}{" "}
                in ad revenue for <span>just {nextPrice} a month</span>.
              </p>
              <div className={styles.buttons}>
                <Button variant={ButtonVariant.SECONDARY} onClick={closeModal}>
                  Skip for now
                </Button>
                <Button variant={ButtonVariant.PRIMARY} onClick={handleWorkspaceClick} loading={approve.isLoading}>
                  Upgrade my plan
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.content}>
            <LoadingSpinner />
          </div>
        )}
      </>
    </Modal>
  );
};
