import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useGetPricingPlanInfoForCurrent } from "../../api/billing";
import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { setSubscriptionInfo } from "../../redux/reducers/workspaceReducer";
import { getSubscriptionDetailInfo } from "../../utils/getSubscriptionDetailInfo";

export const useLoadCurrentSubscriptionDetail = () => {
  const dispatch = useDispatch();
  const { data: currSubDetail } = useGetPricingPlanInfoForCurrent();
  const workspaceDetail = currSubDetail;
  const { data: currentWorkspace } = useGetCurrentWorkspaceDetail();

  useEffect(() => {
    const subscriptionDetailInfo = getSubscriptionDetailInfo(workspaceDetail);
    dispatch(setSubscriptionInfo(subscriptionDetailInfo));
  }, [workspaceDetail, currentWorkspace?.subscriptionStatus]);
};
