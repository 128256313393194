import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { CreateNewWorkspaceRequestDTO } from "../../api/workspace/create-new/dto";
import { Input } from "../../components/Input/Input";

interface DomainInputComponentProps {
  formHandler: UseFormReturn<CreateNewWorkspaceRequestDTO, any>;
  prefilledData?: CreateNewWorkspaceRequestDTO;
  isMutationLoading: boolean;
}

export const DomainInputComponent: React.FC<DomainInputComponentProps> = ({
  formHandler,
  prefilledData,
  isMutationLoading,
}) => {
  const workspace = useGetCurrentWorkspaceDetail();

  const {
    register,
    formState: { errors },
  } = formHandler;

  const registerDomainOptions = {
    required: "This field is required",
    pattern: {
      value: /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}$/,
      message: "Please enter a valid domain",
    },
  };

  return (
    <div>
      <Input
        isLoading={isMutationLoading || workspace.isLoading}
        defaultValue={prefilledData?.domain}
        error={!!errors.domain}
        label="Domain*"
        icon="ico_domain"
        placeholder="yourdomain.com"
        type="text"
        {...register("domain", registerDomainOptions)}
      />
      <ErrorMessage
        errors={errors}
        name="domain"
        render={({ message }) => (
          <span role="alert" className="errorMessage">
            {message}
          </span>
        )}
      />
    </div>
  );
};
