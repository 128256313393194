import React from "react";

import { useGetIntegrationDetails } from "../../../../api/workspace";
import { CardWithTitle } from "../../../../components/CardWithTitle/CardWithTitle";
import { CodeBox } from "../../../../components/CodeBox/CodeBox";
import { StatusStripe } from "../../../../components/StatusStripe/StatusStripe";
import { useGetEcommerceStatusStripeProperties } from "../../../../hooks/integration/useGetEcommerceStatusStripeProperties";
import { useGetIntegrationGlobalSiteTag } from "../../../../hooks/integration/useGetIntegrationGlobalSiteTag";
import { EcommercePlatformEnum } from "../../../../redux/types";

export const ShoptetPlatform: React.FC = () => {
  const { isFetching } = useGetIntegrationDetails();
  const { combinedParametersProps } = useGetEcommerceStatusStripeProperties();
  const globalSiteTag = useGetIntegrationGlobalSiteTag(EcommercePlatformEnum.shoptet);

  return (
    <CardWithTitle
      iconName="ico-shoptet-big"
      title="Global Tracking Code"
      tag="global_tracking_code"
      subtitle="Shoptet"
      videoControl={false}>
      <p>You need to install the global site tag in your Shoptet site.</p>
      <ol>
        <li>
          <p>Open your Shoptet admin and click Templates {">"} Editor of the appearance.</p>
        </li>
        <li>
          <p>Click on the `HTML Code` tab.</p>
        </li>
        <li>
          <p>Copy the code below and paste it in the Header (bottom of the HEAD tag) box.</p>
        </li>
        <li>
          <p>Click Save.</p>
        </li>
      </ol>
      <CodeBox src={globalSiteTag} />
      <StatusStripe isLoading={isFetching} {...combinedParametersProps} />
    </CardWithTitle>
  );
};
