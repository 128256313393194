import React from "react";
import classNames from "classnames";

import { InnerContainerComponent } from "./InnerContainerComponent";

import styles from "./Container.module.scss";

interface ContainerProps {
  children: React.ReactNode;
  backgroundColor?: string;
  className?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  noFlex?: boolean;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  backgroundColor,
  style,
  className,
  fullWidth,
  noFlex = false,
}) => {
  const getAppliedContainerClasses = () => {
    const appliedContainerClassesArray = [styles.container];
    if (className) {
      appliedContainerClassesArray.push(className);
    }
    if (fullWidth) {
      appliedContainerClassesArray.push(styles.fullWidth);
    }

    if (noFlex) {
      appliedContainerClassesArray.push(styles.noFlex);
    }

    const appliedContainerClasses = classNames(...appliedContainerClassesArray);
    return appliedContainerClasses;
  };

  return (
    <div className={getAppliedContainerClasses()} style={{ ...style, backgroundColor }}>
      {React.Children.map(children, (child, i) => (
        <InnerContainerComponent child={child} i={i}>
          {children}
        </InnerContainerComponent>
      ))}
    </div>
  );
};
