import React from "react";
import { DeepMap, FieldError, UseFormRegister } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { Input, InputStyle } from "../../components/Input/Input";
import { IUpdatePasswordRequestDTO } from "./ChangePasswordForm";

import styles from "./ChangePasswordForm.module.scss";

interface UpdatePasswordComponentProps {
  label: string;
  name: keyof IUpdatePasswordRequestDTO;
  register: UseFormRegister<any>;
  errors: DeepMap<IUpdatePasswordRequestDTO, FieldError>;
}

export const UpdatePasswordComponent: React.FC<UpdatePasswordComponentProps> = ({ label, name, register, errors }) => {
  const registerProps = register(name, {
    required: "This field is required",
    minLength: { value: 8, message: "Password must contain at least 8 characters." },
    pattern: { value: /\S/, message: "Password cannot be empty" },
  });

  return (
    <div>
      <Input
        label={label}
        type="password"
        error={!!errors[name]}
        {...registerProps}
        required
        inputStyle={InputStyle.VERTICAL}
        placeholder={label}
        icon="lock"
      />
      <div className={styles.errorMessageWrapper}>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <span role="alert" className="errorMessage">
              {message}
            </span>
          )}
        />
      </div>
    </div>
  );
};
