import React from "react";

import { useGetDisplayedComponentsProperties } from "../../hooks/screen/useGetDisplayedComponentsProperties";

import styles from "./Layout.module.scss";

export const Layout: React.FC = ({ children }) => {
  const { getAddWorkspaceModifiedClassName } = useGetDisplayedComponentsProperties();
  const appliedClassName = getAddWorkspaceModifiedClassName(styles.Layout, styles.addWorkspaceMobile);

  return (
    <div className={appliedClassName}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
