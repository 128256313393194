import React, { useMemo } from "react";
import GoogleButton from "react-google-button";

import {
  useGetGoogleAdAccounts,
  useLogoutGoogleTrackingMutation,
  useSelectGoogleAccessibleCustomerMutation,
} from "../../../../api/google";
import { useGetIntegrationDetails } from "../../../../api/workspace";
import { CardWithTitle } from "../../../../components/CardWithTitle/CardWithTitle";
import { CodeBox } from "../../../../components/CodeBox/CodeBox";
import { Divider } from "../../../../components/Divider/Divider";
import { Select, SelectOption, SelectType } from "../../../../components/Select/Select";
import { StatusStripe } from "../../../../components/StatusStripe/StatusStripe";
import { Switch } from "../../../../components/Switch/Switch";
import { useReceivePopupWindowMessage } from "../../../../hooks/user/useReceivePopupWindowMessage";
import { GoogleAccessibleCustomer, IntegrationStatus } from "../../../../redux/types";
import { getStatusStripeProperties } from "../../../../utils/getStatusStripeProperties";

import styles from "../IntegrationContent.module.scss";

export const GoogleAdsProvider: React.FC = () => {
  const {
    isLoading: isIntegrationLoading,
    isFetching: isIntegrationFetching,
    data: integrationDetails,
  } = useGetIntegrationDetails();
  const urlParameters = integrationDetails?.adProvidersData.google.urlParameters;
  const isUrlParametersConnected = urlParameters && urlParameters.status === IntegrationStatus.success;
  const googleUrlParametersProps = getStatusStripeProperties([urlParameters]);
  const authentication = integrationDetails?.adProvidersData.google.authentication;
  const isAuthenticated = authentication && authentication.status === IntegrationStatus.success;
  const googleAuthenticationProps = getStatusStripeProperties([authentication]);
  const adAccountSelection = integrationDetails?.adProvidersData.google.accountSelection;
  const googleAdAccountSelectionProps = getStatusStripeProperties([adAccountSelection]);
  const adAccountId = integrationDetails?.adProvidersData.google.adAccountId;
  const googleAdAccounts = useGetGoogleAdAccounts();
  useReceivePopupWindowMessage();

  const handleGoogleLogin = () => {
    const height = 500;
    const width = 500;
    const top = (window.innerHeight - height) / 2;
    const left = (window.innerWidth - width) / 2;

    window.open(
      `${window.location.origin}/authentication/sign-in`,
      "googleSignIn",
      `titlebar=0,width=${width},height=${height},left=${left},top=${top}`,
    );
  };

  const getOptionsArray = (adAccountsArray: GoogleAccessibleCustomer[]): SelectOption[] => {
    const optionsArray = adAccountsArray.map(({ id, name }) => {
      const option = { value: id, label: `(${id}) ${name}` };

      return option;
    });
    return optionsArray;
  };

  const options = useMemo(() => {
    let adAccountsArray: GoogleAccessibleCustomer[] = [];
    if (googleAdAccounts.data) {
      adAccountsArray = googleAdAccounts.data;
    }
    const optionsArray = getOptionsArray(adAccountsArray);
    return optionsArray;
  }, [googleAdAccounts.data]);

  const selectedOption = useMemo(() => {
    const selOpt = options.find((opt) => opt.value === adAccountId);
    return selOpt;
  }, [adAccountId, options]);

  const selectAdAccountMutation = useSelectGoogleAccessibleCustomerMutation();
  const handleSelectChange = (option: any) => {
    selectAdAccountMutation.mutate(option.value);
  };
  const logoutGoogleTrackingMutation = useLogoutGoogleTrackingMutation();
  const disableGoogleIntegration = () => {
    logoutGoogleTrackingMutation.mutate();
  };
  const checkedLabel = isUrlParametersConnected ? "Working" : "You must set up URL parameters first";
  const isAccountsSelectShown = isUrlParametersConnected && isAuthenticated;

  return (
    <>
      <CardWithTitle iconName="ico-googleads-big" title="Add URL Parameters" subtitle="Google Ads" videoControl={false}>
        <p>To set up your Google Ads URL parameters:</p>
        <ol>
          <li>
            <p>Navigate to your Google Ads account.</p>
          </li>
          <li>
            <p>
              In the left sidebar menu, open Settings {">"} Account Settings {">"} Tracking.{" "}
              {`(If you do not see left sidebar, click "Switch View" in the top bar.)`}
            </p>
          </li>
          <li>
            <p>Paste the following code under “Final URL suffix“.</p>
          </li>
          <li>
            <p>Click the Save button.</p>
          </li>
        </ol>
        <CodeBox src={`google_id={creative}`} />
        <p>(If you already have UTMs or parameters in your tracking area add our url parameter with an “&” sign.)</p>

        <Divider />
        <StatusStripe isLoading={isIntegrationFetching} {...googleUrlParametersProps} />
      </CardWithTitle>
      <CardWithTitle
        iconName="ico-googleads-big"
        title="Google Authentication"
        subtitle="Google Ads"
        videoControl={false}>
        <div>
          <div className={styles.importer}>
            <div className={styles.main}>
              <div className={styles.title}>Google Ads</div>
              <div className={styles.subtitle}>
                {isUrlParametersConnected
                  ? "Acquire token necessary to fetch data from Google Ads API"
                  : "Url parameters must be set up first"}
              </div>
            </div>
            {!isAuthenticated ? (
              <GoogleButton disabled={!isUrlParametersConnected} onClick={handleGoogleLogin} />
            ) : (
              <Switch
                onCheck={disableGoogleIntegration}
                onUncheck={disableGoogleIntegration}
                checkedLabel={checkedLabel}
                uncheckedLabel={""}
                checked={isAuthenticated}
                isDisabled={false}
                isLoading={isIntegrationLoading}
              />
            )}
          </div>
        </div>
        <Divider />
        <StatusStripe isLoading={isIntegrationFetching} {...googleAuthenticationProps} />
      </CardWithTitle>
      <CardWithTitle
        iconName="ico-googleads-big"
        title="Ads Account Selection"
        subtitle="Google Ads"
        videoControl={false}>
        <div style={{ marginTop: 24, position: "relative" }}>
          <div className={styles.importer}>
            <div className={styles.main}>
              <div className={styles.title}>Choose Google Ads account</div>
              <div className={styles.subtitle}>List of your Ads accounts</div>
            </div>
            <div>
              {isAccountsSelectShown && (
                <Select
                  value={selectedOption as any}
                  onChange={handleSelectChange}
                  options={options}
                  selectType={SelectType.SMALL}
                  isDisabled={selectAdAccountMutation.isLoading || isIntegrationFetching}
                />
              )}
            </div>
          </div>
        </div>
        <Divider />
        <StatusStripe isLoading={isIntegrationFetching} {...googleAdAccountSelectionProps} />
      </CardWithTitle>
    </>
  );
};
