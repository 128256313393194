import { setCurrentId } from "../../redux/reducers/workspaceReducer";
import { store } from "../../redux/store";
import { Workspace } from "../../redux/types";
import { setWorkspaceToLocalStorage } from "../../utils/localStorageHandlers";

export const useSwitchWorkspace = (data: Workspace, isCreateWorkspace = false) => {
  setWorkspaceToLocalStorage(data.id);
  if (!isCreateWorkspace) {
    store.dispatch(setCurrentId(data.id));
  }
};
