import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";

import { useHandleClickOutside } from "../../hooks/screen/useHandleClickOutside";
import { IModal } from "../../redux/types";
import { getModalAnimationProperties } from "../../utils/getModalAnimation";

import styles from "./Modal.module.scss";

export interface ModalProps extends Omit<IModal, "toggle"> {
  animation: "slideUp" | "fadeIn";
  children: ReactNode;
  header?: string;
  overlay?: "blue" | "gray" | "blueblur" | "grayblur";
  className?: string;
  fitContent?: boolean;
  zIndex?: number;
  toggle?: (bool?: boolean) => void;
}

export const Modal: React.FC<ModalProps> = ({
  animation,
  children,
  toggle,
  header,
  visible,
  overlay,
  className,
  fitContent,
  zIndex,
}) => {
  const toggleWithPropagation = () => {
    if (toggle) {
      toggle();
    }
  };

  const ref = useHandleClickOutside(toggleWithPropagation);
  const { blur, fadeIn, animate } = getModalAnimationProperties(animation);

  return ReactDOM.createPortal(
    <AnimatePresence initial>
      {visible && (
        <>
          {(overlay === "blueblur" || overlay === "grayblur") && (
            <motion.div
              className={styles.blur}
              variants={blur}
              initial="hidden"
              animate="visible"
              exit="exit"
              style={{ zIndex: zIndex ? zIndex - 1 : 199 }}
            />
          )}
          <motion.div
            className={`${styles.overlay} ${styles[overlay ?? "gray"]}`}
            style={{ zIndex: zIndex ? zIndex - 2 : 198 }}
            variants={fadeIn}
            initial="hidden"
            animate="visible"
            exit="exit"
          />
          <motion.div
            ref={ref}
            style={{ zIndex: zIndex ? zIndex : 200 }}
            className={`${styles.modal} ${className ?? ""} ${fitContent ? styles.fitContent : ""}`}
            variants={{ ...animate, visible: { ...animate.visible, opacity: 1 } }}
            initial="hidden"
            animate="visible"
            exit="exit">
            {header && <h2 className={styles.header}>{header}</h2>}
            {children}
          </motion.div>
        </>
      )}
    </AnimatePresence>,
    document.getElementById("root")!,
  );
};
