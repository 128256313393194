import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { CreateNewWorkspaceRequestDTO } from "../../api/workspace/create-new/dto";
import { Icon } from "../../components/Icon/Icon";
import { Select, SelectType } from "../../components/Select/Select";
import { Skeleton, SkeletonType } from "../../components/Skeleton/Skeleton";
import { CurrencyObject, CurrencyType } from "../../redux/types";
import { getCurrencyOption } from "../../utils/formatters/currencyFormatters";

import styles from "./WorkspaceForm.module.scss";

interface DomainInputComponentProps {
  formHandler: UseFormReturn<CreateNewWorkspaceRequestDTO, any>;
  selectedCurrency: CurrencyObject;
  setSelectedCurrency: React.Dispatch<React.SetStateAction<CurrencyObject>>;
  isCreateForm: boolean;
  isMutationLoading: boolean;
}

export const CurrencyDropdownComponent: React.FC<DomainInputComponentProps> = ({
  formHandler,
  isCreateForm,
  selectedCurrency,
  setSelectedCurrency,
  isMutationLoading,
}) => {
  const workspace = useGetCurrentWorkspaceDetail();
  const [focusedC, setFocusedC] = useState(false);
  const onFocusC = () => setFocusedC(true);
  const onBlurC = () => setFocusedC(false);

  const handleSelectCurrency = (currency: any): void => {
    setSelectedCurrency(currency);
  };
  const {
    formState: { errors },
  } = formHandler;

  const currencyOptions = Object.values(CurrencyType)
    .map((currency) => getCurrencyOption(currency))
    .sort((a, b) => a.label.localeCompare(b.label, navigator.language));

  const getAppliedNoInputClassName = (): string => {
    const appliedNoInputClassNameArray = [styles.currencyNoInput];
    const appliedNoInputClassName = classNames(...appliedNoInputClassNameArray);
    return appliedNoInputClassName;
  };

  return (
    <div>
      <div className={styles.currencyWrapper} onBlur={onBlurC} onFocus={onFocusC}>
        <label htmlFor="select">Currency</label>
        {isMutationLoading || workspace.isLoading ? (
          <Skeleton type={SkeletonType.INPUT} />
        ) : (
          <div className={styles.inputWrapper}>
            <div className={styles.icon}>
              <Icon name="ico_prizing" isActive={focusedC} width="24px" />
            </div>

            {isCreateForm ? (
              <Select
                defaultVal={selectedCurrency}
                value={selectedCurrency as any}
                onChange={handleSelectCurrency}
                options={currencyOptions}
                selectType={SelectType.BIG}
              />
            ) : (
              <div className={getAppliedNoInputClassName()}>
                <span>{selectedCurrency.value}</span>
              </div>
            )}
          </div>
        )}
        <ErrorMessage
          errors={errors}
          name="currency"
          render={({ message }) => (
            <span role="alert" className="errorMessage">
              {message}
            </span>
          )}
        />
      </div>
    </div>
  );
};
