import React, { ReactNode } from "react";

import { Icon } from "../Icon/Icon";

import styles from "./Callout.module.scss";

interface CalloutProps {
  type: "info";
  children: ReactNode;
}

export const Callout: React.FC<CalloutProps> = ({ type, children }) => {
  return (
    <div className={styles.callout.concat(" ", styles[type])}>
      <div className={styles.icon}>
        <Icon name="info_icon" width="17px" />
      </div>
      <div className={styles.text}>{children}</div>
    </div>
  );
};
