import React from "react";

import styles from "./ContentWithMenu.module.scss";

interface ContentWithMenuProps {
  // the menu and content need to be passed as react children instead of props. if they would be passed as props, the framer-motion effect on the menu would stop working
  children: React.ReactNode[];
  customContainer?: boolean;
}

export const ContentWithMenu: React.FC<ContentWithMenuProps> = ({ children, customContainer }) => {
  const menuItem = children[0];
  const contentItem = children[1];

  const getAppliedContent = () => {
    let appliedContent = <div className={styles.column}>{contentItem}</div>;

    if (customContainer) {
      appliedContent = <> {contentItem}</>;
    }

    return appliedContent;
  };

  return (
    <div className={styles.layout}>
      <div className={styles.rest}>
        <div className={styles.menu}>{menuItem}</div>
        {getAppliedContent()}
      </div>
    </div>
  );
};
