import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useGetLoggedInUser, useUpdateUser, useUploadProfilePicture } from "../../api/user";
import { Container } from "../../components/Container/Container";
import { FormDivider } from "../../components/Divider/FormDivider";
import { ConfirmButtonComponent } from "./ConfirmButtonComponent";
import { FirstNameInputComponent } from "./FirstNameInputComponent";
import { ImageInputComponent } from "./ImageInputComponent";
import { LastNameInputComponent } from "./LastNameInputComponent";

import styles from "./PersonalSettingsForm.module.scss";

export interface UserInput {
  firstName: string;
  lastName: string;
}

export const PersonalSettingsForm: React.FC = () => {
  const currentUser = useGetLoggedInUser();
  const uploadProfilePic = useUploadProfilePicture();
  const updateUser = useUpdateUser();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImageString, setSelectedImageString] = useState<string | undefined | null>(
    currentUser.data?.profilePicture,
  );
  const isButtonLoading: boolean = updateUser.isLoading || uploadProfilePic.isLoading;

  const formHandler = useForm<UserInput>({
    reValidateMode: "onSubmit",
    defaultValues: {
      firstName: currentUser.data?.firstName,
      lastName: currentUser.data?.lastName,
    },
  });
  const { handleSubmit } = formHandler;

  const onSubmit: SubmitHandler<UserInput> = async (data) => {
    const isFirstNameDirty = data.firstName !== currentUser.data?.firstName;
    const isLastNameDirty = data.lastName !== currentUser.data?.lastName;
    const isFormDirty = isFirstNameDirty || isLastNameDirty;
    if (isFormDirty) await updateUser.mutateAsync({ ...data });
    if (selectedImage) {
      uploadProfilePic.mutate(selectedImage);
    }
  };

  const mainProps = { formHandler };
  const imageInputProps = {
    selectedImage,
    setSelectedImage,
    selectedImageString,
    setSelectedImageString,
  };
  const confirmButtonProps = {
    isButtonLoading,
    selectedImageString,
  };

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Personal Settings</h3>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formContainer}>
          <div className={styles.row}>
            <FirstNameInputComponent {...mainProps} />
            <LastNameInputComponent {...mainProps} />
            <ImageInputComponent {...imageInputProps} />
          </div>
        </div>
        <FormDivider />
        <ConfirmButtonComponent {...confirmButtonProps} />
      </form>
    </Container>
  );
};
