import React from "react";

import { TableHeaderCellContentProps } from "../../../redux/types/table";

import styles from "./TableHeader.module.scss";

export const TableHeaderCellContent: React.FC<TableHeaderCellContentProps> = ({ col }) => {
  return (
    <p className={styles.boldHeaderText} style={{ textAlign: col.alignment }}>
      {col.name}
    </p>
  );
};
