import React from "react";
import { useSelector } from "react-redux";

import { useConsentSubscriptionPriceUpgrade, useManageSubscriptionPlan } from "../../../api/billing";
import { useGetCurrentWorkspaceDetail } from "../../../api/workspace";
import { Button, ButtonSize, ButtonVariant } from "../../../components/Button/Button";
import { Container } from "../../../components/Container/Container";
import { TariffUsageBar } from "../../../components/TariffUsageBar/TariffUsageBar";
import { getWorkspaceSubscriptionInfo } from "../../../redux/selectors/workspace";
import { CurrencyType, SubscriptionStatus } from "../../../redux/types";
import { getCurrencyDataFromCode, getFormattedPrice } from "../../../utils/formatters/currencyFormatters";
import { getSubscriptionUsageRoundedString } from "../../../utils/getSubscriptionDetailInfo";
import { HighlightsCard } from "./HighlightsCard";

import styles from "../WorkspaceSetupPage.module.scss";

export const WorkspacePlanDetails: React.FC = () => {
  const subscriptionInfo = useSelector(getWorkspaceSubscriptionInfo);
  const workspace = useGetCurrentWorkspaceDetail();
  const workspaceCurrency = getWorkspaceCurrencyObject(workspace.data?.currency);
  const { percentageUsed, subscriptionLimitRoundedString, priceRoundedString } = subscriptionInfo;
  const subscriptionUsageRoundedString = getSubscriptionUsageRoundedString(subscriptionInfo, true);
  const subStatus = workspace.data?.subscriptionStatus;
  const isUnapprovedSubStatus = subStatus && subStatus === SubscriptionStatus.Unapproved;
  const manageSubscriptionPlanMutation = useManageSubscriptionPlan();
  const handleManageSubscriptionPlan = () => {
    manageSubscriptionPlanMutation.mutate();
  };
  const approve = useConsentSubscriptionPriceUpgrade();
  const handleApproveSubscription = () => {
    approve.mutate();
  };

  const highlightsCardsArray = [
    {
      title: "my plan",
      value: `Under ${subscriptionLimitRoundedString}`,
      iconName: "ico-star",
    },
    {
      title: "cost",
      value: `${priceRoundedString}/month`,
      iconName: "ico-chart",
    },
    {
      title: "currency",
      value: `${workspaceCurrency.currencyFullName} (${workspaceCurrency.currencySymbol})`,
      iconName: "ico-wallet",
    },
  ];

  return (
    <Container noFlex className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Plan Details</h3>
        {isUnapprovedSubStatus ? (
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={handleApproveSubscription}
            loading={approve.isLoading}>
            Upgrade plan
          </Button>
        ) : (
          <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.SMALL} onClick={handleManageSubscriptionPlan}>
            Manage plan
          </Button>
        )}
      </div>
      <div className={styles.workspacePlanContent}>
        <div className={styles.contentTop}>
          <div className={styles.statusContainer}>
            <h6 className={styles.subtitle}>Status</h6>
            <div className={styles.indicatorContainer}>
              <div className={styles.indicator} />
              <p className={styles.indicatorText}>Active</p>
            </div>
          </div>
          <div className={styles.card}>
            <h6 className={styles.cardSubtitle}>{subscriptionUsageRoundedString}</h6>
            <TariffUsageBar percentage={percentageUsed} height="16px" />
          </div>
        </div>
        <div className={styles.contentBottom}>
          <h6 className={styles.subtitle}>Details</h6>
          <div className={styles.highlightsContainer}>
            {highlightsCardsArray.map((card) => (
              <HighlightsCard key={card.title} {...card} />
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export const getWorkspaceCurrencyObject = (currencyCode = CurrencyType.USD) => {
  const currencySymbol = getFormattedPrice(0, currencyCode).replace("0", "").trim();
  const workspaceCurrencyData = getCurrencyDataFromCode(currencyCode);
  const currencyFullName = workspaceCurrencyData?.currency ?? "";
  return { currencySymbol, currencyFullName };
};
