import { queryClient } from "../api/api";
import { setJwt } from "../redux/reducers/appReducer";
import { setCurrentId } from "../redux/reducers/workspaceReducer";
import { store } from "../redux/store";
import { TableColumn } from "../redux/types/table";

export const setJwtToLocalStorage = (jwt: string): void => {
  window.localStorage.setItem("jwt", jwt);
};

export const removeJwtFromLocalStorage = (): void => {
  window.localStorage.removeItem("jwt");
  localStorage.removeItem("selected-workspace");
};

export const setWorkspaceToLocalStorage = (workspaceId: number) => {
  localStorage.setItem("selected-workspace", workspaceId.toString());
};

export const getWorkspaceFromLocalStorage = (): string | undefined => {
  return window.localStorage.getItem("selected-workspace") ?? undefined;
};

export const invalidateSession = async () => {
  store.dispatch(setCurrentId(undefined));
  store.dispatch(setJwt(""));
  localStorage.clear();
  sessionStorage.clear();
  queryClient.removeQueries();
};

export const updateLocalStorageCampaingsColumns = (columnsArray: TableColumn[]): void => {
  const campaignsTableColsAccessors = columnsArray.map((col) => col.accessor);
  localStorage.setItem("selectedCampaignsColumns", JSON.stringify(campaignsTableColsAccessors));
};
