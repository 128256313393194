import React from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { useGetWorkspaceDetailMutation } from "../../api/workspace";
import { useModal } from "../../hooks/modal/useModal";
import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { WorkspaceSwitch } from "../WorkspaceSwitch/WorkspaceSwitch";
import { HeaderContent } from "./HeaderContent/HeaderContent";
import { MobileHeaderContent } from "./HeaderContent/MobileHeaderContent";

import styles from "./Header.module.scss";

export const Header: React.FC = () => {
  const switchControl = useModal();
  const { visible, toggle } = switchControl;
  const getWorkspaceDetail = useGetWorkspaceDetailMutation();
  const { isMobile } = useGetBreakpointProperties();
  const { pathname } = useLocation();
  const isAddWorkspacePath = pathname === "/add-workspace" || pathname === "/add-first-workspace";

  const getAppliedHeaderClasses = () => {
    const appliedHeaderClassesArray = [styles.header];

    if (visible) {
      appliedHeaderClassesArray.push(styles.headerSwitch);
    }

    if (isAddWorkspacePath) {
      appliedHeaderClassesArray.push(styles.workspacePath);
    }

    const appliedHeaderClasses = classNames(...appliedHeaderClassesArray);

    return appliedHeaderClasses;
  };

  const headerContentProps = { ...switchControl, isWorkspaceLoading: getWorkspaceDetail.isLoading };

  return (
    <>
      <AnimatePresence initial exitBeforeEnter>
        <motion.div className={getAppliedHeaderClasses()}>
          {isMobile ? <MobileHeaderContent {...headerContentProps} /> : <HeaderContent {...headerContentProps} />}
        </motion.div>
      </AnimatePresence>
      <WorkspaceSwitch switchVisible={visible} onClose={() => toggle(false)} getWorkspace={getWorkspaceDetail} />
    </>
  );
};
