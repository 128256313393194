import React from "react";
import { UseFormReturn } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { CreateNewWorkspaceRequestDTO } from "../../api/workspace/create-new/dto";
import { Input } from "../../components/Input/Input";

interface NameInputComponentProps {
  formHandler: UseFormReturn<CreateNewWorkspaceRequestDTO, any>;
  prefilledData?: CreateNewWorkspaceRequestDTO;
  isMutationLoading: boolean;
}

export const NameInputComponent: React.FC<NameInputComponentProps> = ({
  formHandler,
  prefilledData,
  isMutationLoading,
}) => {
  const workspace = useGetCurrentWorkspaceDetail();

  const {
    register,
    formState: { errors },
  } = formHandler;

  const registerNameOptions = {
    required: "This field is required",
    minLength: { value: 1, message: "Workspace name cannot be empty" },
    pattern: { value: /\S/, message: "Workspace name cannot be empty" },
  };

  return (
    <div>
      <Input
        isLoading={isMutationLoading || workspace.isLoading}
        defaultValue={prefilledData?.name}
        label="Workspace name*"
        placeholder={`Eg. "Amazon"`}
        type="text"
        icon="ico_workspace"
        error={!!errors.name}
        {...register("name", registerNameOptions)}
      />
      <ErrorMessage
        errors={errors}
        name="name"
        render={({ message }) => (
          <span role="alert" className="errorMessage">
            {message}
          </span>
        )}
      />
    </div>
  );
};
