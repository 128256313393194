export const capitalizeString = (text: string): string => {
  const capitalizedString = text.charAt(0).toUpperCase() + text.slice(1);

  return capitalizedString;
};

/**
 * takes a string and returns only the number.
 *
 * Example:
 *
 * -364.25$ => -364.25
 */
export const parseNumber = (input: string): number => {
  return parseFloat(input?.replace(/[^0-9.-]+/g, ""));
};

export const getFormattedCellValue = (
  value: number | null | undefined | string,
  valueGetter: (value: number) => string,
): string => {
  const valueToFormat = value ?? 0;
  let formattedCellValue = "-";

  if (typeof valueToFormat === "number" && typeof value === "number") {
    formattedCellValue = valueGetter(valueToFormat);
  }

  return String(formattedCellValue);
};

/**
 * Converts a string to camelCase.
 *
 * Example:
 * "Reported vs Revenue" -> "reportedVsRevenue"
 */
export const getCamelCaseString = (input: string) => {
  return input
    .toLowerCase()
    .split(" ")
    .map((str, idx) => (idx !== 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str))
    .join("");
};
