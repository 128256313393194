import React from "react";

import { useGetCurrencyTypeCellValue } from "../../../hooks/formatters/useGetCurrencyTypeCellValue";
import { TableColumn } from "../../../redux/types/table";
import { getRoasMultiple } from "../../../utils/formatters/currencyFormatters";
import { getFormattedCellValue } from "../../../utils/formatters/stringFormatters";
import { TableTooltip } from "./TableTooltip";

import styles from "./CampaignsTable.module.scss";

export const useGetFormattedColumnValue = (col: TableColumn, props: any): JSX.Element => {
  const getCurrencyTypeCellValue = useGetCurrencyTypeCellValue();
  const cellValue = props.cell.value;
  const mainRowId = Number(props.row.id.split(".").slice(0, 1)[0]);
  let formattedCellValue = cellValue ?? "-";

  const tooltipValue = getTooltipValue(col, props);
  const tooltipDifference = Number((cellValue - tooltipValue).toFixed(1));
  let formattedTooltipValue = String(tooltipValue);
  let formattedTooltipDifference = String(tooltipDifference);

  const getFormattedRoasTooltipValue = () => {
    let formattedRoasTooltipValue = cellValue;

    if (typeof cellValue === "number" && col.tooltipAccessor) {
      formattedRoasTooltipValue = getRoasMultiple(props.row.original[col.tooltipAccessor]);
    }

    return formattedRoasTooltipValue;
  };

  //CURRENCY
  if (col.currency) {
    if (col.tooltipAccessor) {
      formattedTooltipValue = getCurrencyTypeCellValue(tooltipValue);
      formattedTooltipDifference = getCurrencyTypeCellValue(tooltipDifference);
    } else {
      formattedTooltipValue = "0";
      formattedTooltipDifference = "";
    }
    formattedCellValue = getFormattedCellValue(cellValue, getCurrencyTypeCellValue);
  }
  //ROAS
  if (col.accessor?.includes("roas")) {
    formattedTooltipValue = getFormattedRoasTooltipValue();
    formattedTooltipDifference = typeof cellValue === "number" ? getRoasMultiple(tooltipDifference) : cellValue;
    formattedCellValue = getFormattedCellValue(cellValue, getRoasMultiple);
  }

  let formattedCell = <>{formattedCellValue}</>;

  if (col.accessor !== "src") {
    formattedCell = <p className={styles.formattedCellValue}>{formattedCellValue}</p>;
  }
  const tooltipDisplayed = col.tooltipAccessor && !isNaN(tooltipDifference) && (cellValue !== 0 || tooltipValue !== 0);

  if (tooltipDisplayed) {
    formattedCell = (
      <TableTooltip
        formattedTooltipValue={formattedTooltipValue}
        formattedTooltipDifference={formattedTooltipDifference}
        tooltipDifference={tooltipDifference}
        isLastCell={col.name.toLowerCase() === "roas"}
        isLastRow={props.row.isLastRow}
        iconName={getIconName(mainRowId)}>
        {formattedCell}
      </TableTooltip>
    );
  }

  return formattedCell;
};

const getIconName = (mainRowId: number) => {
  let iconName = "";
  if (mainRowId === 0) {
    iconName = "fb_logo_tooltip";
  }
  if (mainRowId === 1) {
    iconName = "google_logo_tooltip";
  }
  return iconName;
};

const getTooltipValue = (col: TableColumn, props: any): number => {
  let tooltipValue = 0;
  if (col.tooltipAccessor) {
    const fullTooltipValue = Number(props.row.original[col.tooltipAccessor]);
    const tooltipValueRounded = fullTooltipValue.toFixed(1);
    tooltipValue = Number(tooltipValueRounded);
  }

  return tooltipValue;
};
