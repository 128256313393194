import React from "react";

import { WorkspaceRoleDTO } from "../../api/workspace/get-one/dto";
import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { BiggerScreenView } from "./BiggerScreenView";
import { MobileScreenView } from "./MobileScreenView";

import styles from "./WorkspaceUsersTable.module.scss";

export interface WorkspaceUsersTableProps {
  setSelectedUser: React.Dispatch<React.SetStateAction<WorkspaceRoleDTO | undefined>>;
  deleteUserModalToggle: (bool?: boolean | undefined) => void;
}
export const WorkspaceUsersTable: React.FC<WorkspaceUsersTableProps> = ({ setSelectedUser, deleteUserModalToggle }) => {
  const { isMobile } = useGetBreakpointProperties();
  return (
    <div className={styles.WorkspaceUsersTable}>
      <div>
        <div className={styles.table}>
          {!isMobile ? (
            <BiggerScreenView setSelectedUser={setSelectedUser} deleteUserModalToggle={deleteUserModalToggle} />
          ) : (
            <MobileScreenView />
          )}
        </div>
      </div>
    </div>
  );
};
