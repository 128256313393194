export enum NotificationSeverity {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export interface Notification {
  open: boolean;
  message: string;
  severity: NotificationSeverity;
  hideAfter?: number;
  icon?: string;
}

export interface NotificationState {
  notification: Notification;
}
export interface NotificationInput {
  message: string;
  severity: NotificationSeverity;
  hideAfter?: number;
  icon?: string;
}
