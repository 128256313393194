import React from "react";

import { useGetIntegrationDetails } from "../../../../api/workspace";
import { CardWithTitle } from "../../../../components/CardWithTitle/CardWithTitle";
import { CodeBox } from "../../../../components/CodeBox/CodeBox";
import { StatusStripe } from "../../../../components/StatusStripe/StatusStripe";
import { useGetEcommerceStatusStripeProperties } from "../../../../hooks/integration/useGetEcommerceStatusStripeProperties";
import { useGetIntegrationGlobalSiteTag } from "../../../../hooks/integration/useGetIntegrationGlobalSiteTag";
import { EcommercePlatformEnum } from "../../../../redux/types";

export const ShopifyPlatform: React.FC = () => {
  const { isFetching } = useGetIntegrationDetails();
  const { commonUrlParametersProps, commonConversionParametersProps } = useGetEcommerceStatusStripeProperties();
  const globalSiteTag = useGetIntegrationGlobalSiteTag(EcommercePlatformEnum.shopify);
  const codeText = `{% if first_time_accessed %}
    
${globalSiteTag}
<!-- Event snippet for conversion page -->
<script>
etag('event', 'conversion', {
'value': '{{ checkout.subtotal_price | divided_by: 100.0 }}',
'order_id': '{{ checkout.order_id }}',
'currency': '{{ checkout.currency }}',
'transaction_id': '{{ order_number }}',
'customer_email': '{{ checkout.customer.email }}',
'customer_id': '{{ checkout.customer.id }}',
'customer_first_name': '{{ checkout.customer.first_name }}',
'customer_last_name': '{{ checkout.customer.last_name }}',
'customer_total_spent': '{{ checkout.customer.total_spent }}',
'customer_phone': '{{ checkout.customer.phone }}',
'customer_accepts_marketing': '{{ checkout.customer.accepts_marketing }}'
});
</script>
{% endif %}`;

  return (
    <>
      <CardWithTitle iconName="ico-shopify-big" title="Global Tracking Code" subtitle="Shopify" videoControl={true}>
        <p>You need to install the global site tag in your Shopify theme code.</p>
        <ol>
          <li>
            <p>Open your Shopify admin and click Online Store.</p>
          </li>
          <li>
            <p>Click Actions {">"} Edit code.</p>
          </li>
          <li>
            <p>Open your `theme.liquid` file.</p>
          </li>
          <li>
            <p>
              If this is the first time you’ve installed a global site tag, then paste the copied global site tag
              between the &apos;{"<head>"}&apos; and &apos;{"</head>"}&apos; tags to apply the tag to every page in your
              store. If you’re editing a global site tag that you’ve already installed, then locate the global site tag
              in your `theme.liquid` file and change the code as suggested.
            </p>
          </li>
          <li>
            <p>Click Save.</p>
          </li>
        </ol>
        <CodeBox src={globalSiteTag} />
        <StatusStripe isLoading={isFetching} {...commonUrlParametersProps} />
      </CardWithTitle>

      <CardWithTitle iconName="ico-shopify-big" title="Event Snippet" subtitle="Shopify" videoControl={true}>
        <p>After that, you also need to install an event snippet.</p>
        <p>
          An event snippet tracks a conversion any time somebody clicks one of your ads and then later reaches the
          checkout page on your online store.
        </p>
        <ol>
          <li>
            <p>Open your Shopify admin and click Settings {">"} Checkout.</p>
          </li>
          <li>
            <p>
              In the Order processing section, in the Additional scripts text box, paste the event snippet. If you
              already have code in the Additional scripts text box, then add the event snippet on a new line below the
              existing code.
            </p>
          </li>
        </ol>
        <CodeBox src={codeText} />
        <StatusStripe isLoading={isFetching} {...commonConversionParametersProps} />
      </CardWithTitle>
    </>
  );
};
