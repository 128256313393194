import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ApplicationBreakpoint, AppState, AttributionType } from "../types/app";

const initAppState: AppState = {
  sidePanelShown: false,
  headerTitle: "",
  attributionType: AttributionType.lastClick,
  stripeRequestSent: false,
  appBreakpoint: ApplicationBreakpoint.desktop,
  jwt: localStorage.getItem("jwt") ?? "",
};

const appSlice = createSlice({
  name: "app",
  initialState: initAppState,
  reducers: {
    changeHeaderTitle: (state, { payload }: PayloadAction<string>) => {
      state.headerTitle = payload;
    },
    setAttributionType: (state, { payload }: PayloadAction<AttributionType>) => {
      state.attributionType = payload;
    },
    setStripeRequestSent: (state, { payload }: PayloadAction<boolean>) => {
      //this is so we don't display the "workspace is not paid" modal when the user didn't even get redirected to stripe yet
      state.stripeRequestSent = payload;
    },
    setAppBreakpoint: (state, action: PayloadAction<ApplicationBreakpoint>) => {
      state.appBreakpoint = action.payload;
    },
    setJwt: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
  },
});

export const { changeHeaderTitle, setAttributionType, setStripeRequestSent, setAppBreakpoint, setJwt } =
  appSlice.actions;

export default appSlice.reducer;
