import { useGetLoggedInUser } from "../../api/user";
import { WorkspaceRole } from "../../redux/types";

export const useGetUserRoles = (): WorkspaceRole[] => {
  const current = useGetLoggedInUser();
  if (!current.data) {
    return [];
  }
  return current.data.roles;
};
