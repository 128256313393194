import React from "react";
import { useSelector } from "react-redux";

import { useGetBreakpointProperties } from "../../../hooks/screen/useGetBreakpointProperties";
import { getHeaderTitle } from "../../../redux/selectors/app";
import { IModal } from "../../../redux/types";
import { DatePicker } from "../../DatePicker/DatePicker";
import { UserInfo } from "../../UserInfo/UserInfo";
import { BigWorkspaceSwitchButton } from "../HeaderWorkspaceSwitchButton/BigWorkspaceSwitchButton";
import { SmallWorkspaceSwitchButton } from "../HeaderWorkspaceSwitchButton/SmallWorkspaceSwitchButton";

import styles from "./HeaderContent.module.scss";

export const HeaderContent: React.FC<IModal> = ({ visible, toggle }) => {
  const headerTitle = useSelector(getHeaderTitle);
  const { isTablet } = useGetBreakpointProperties();

  return (
    <>
      <h2 className={styles.title}>{headerTitle}</h2>
      <div className={styles.middle}>
        {isTablet ? (
          <SmallWorkspaceSwitchButton {...{ visible, toggle }} />
        ) : (
          <BigWorkspaceSwitchButton {...{ visible, toggle }} />
        )}
        <DatePicker />
      </div>
      <div className={styles.right}>
        <div className={styles.user}>
          <UserInfo />
        </div>
      </div>
    </>
  );
};
