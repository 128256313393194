import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { usePutAdProvidersMutation } from "../../api/workspace/set-ad-providers";
import { Button, ButtonVariant } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { IAdsMenuItem } from "../../containers/IntegrationPage/IntegrationMenu/IntegrationMenu";
import { useGetModalGridStyles } from "../../hooks/modal/useGetModalGridStyles";
import { getWorkspaceId } from "../../redux/selectors/workspace";
import { AdProviderEnum, IModal } from "../../redux/types";
import { AdProviderItem } from "./AdProviderItem";

import styles from "./AddAdProviderModal.module.scss";

interface AddAdProviderModalProps extends IModal {
  platformObjects: IAdsMenuItem[];
  selected: IAdsMenuItem[];
}

export const AddAdProviderModal: React.FC<AddAdProviderModalProps> = ({
  visible,
  toggle,
  platformObjects,
  selected,
}) => {
  // workspace must be selected
  const workspaceId = Number(useSelector(getWorkspaceId));
  const [temporarilySelected, setTemporarilySelected] = useState<IAdsMenuItem[]>(selected);
  const { mutateAsync: putAdProviders, isLoading } = usePutAdProvidersMutation();
  const appliedModalGridStyles = useGetModalGridStyles(platformObjects);

  const handleCancel = () => {
    toggle();
  };

  const handleSave = async () => {
    const filteredAdProviders = temporarilySelected.map((temp) => {
      return temp.name as AdProviderEnum;
    });

    await putAdProviders({
      adProviders: filteredAdProviders,
      workspaceId,
    });

    toggle();
  };

  useEffect(() => {
    setTemporarilySelected(selected);
  }, [selected, visible]);

  return (
    <Modal visible={visible} toggle={toggle} animation="fadeIn" fitContent className={styles.selectProviderModal}>
      <h3 className={styles.header}>Add an ad provider</h3>
      <p className={styles.text}>
        To change which ad provider is being shown in the app, select it from the list below. At least one ad provider
        must be selected.
      </p>
      <div>
        <div className={styles.selectProviderContainer} style={appliedModalGridStyles}>
          {platformObjects.map((platform) => (
            <AdProviderItem
              platform={platform}
              key={platform.name}
              {...{ temporarilySelected, setTemporarilySelected }}
            />
          ))}
        </div>
        <div className={styles.buttons}>
          <Button variant={ButtonVariant.SECONDARY} label="Cancel" onClick={handleCancel} />
          <Button variant={ButtonVariant.PRIMARY} label="Save my choice" onClick={handleSave} loading={isLoading} />
        </div>
      </div>
    </Modal>
  );
};
