import React from "react";

import { GoogleAuthenticationPopupPage } from "../GoogleAuthenticationPopupPage/GoogleAuthenticationPopupPage";
import { MainPage } from "../MainPage/MainPage";

//user is authenticated
export const AuthenticatedApp: React.FC = () => {
  return (
    // if window.opener exists, the window is a popup
    window.opener ? <GoogleAuthenticationPopupPage /> : <MainPage />
  );
};
