import React from "react";

import { DashboardViewResponseDTO } from "../../../api/views/dashboard/dto";
// import { AttributionSwitch } from "../../../components/AttributionSwitch/AttributionSwitch";
import { Container } from "../../../components/Container/Container";
import { Skeleton, SkeletonVariant } from "../../../components/Skeleton/Skeleton";
import { useGetBreakpointProperties } from "../../../hooks/screen/useGetBreakpointProperties";
import { CurrencyType } from "../../../redux/types";
import { getFormattedPrice, getRoasMultiple } from "../../../utils/formatters/currencyFormatters";
import { getFormattedCellValue } from "../../../utils/formatters/stringFormatters";
import { getRoas } from "../../../utils/transformCampaignsData";
import { LockedSection } from "../LockedSection/LockedSection";
import { DetailCard } from "./DetailCard";

import styles from "./Campaigns.module.scss";

export const Campaigns: React.FC<{ data: DashboardViewResponseDTO; currency: CurrencyType }> = ({ data, currency }) => {
  const { isMobile, isTablet } = useGetBreakpointProperties();
  let containerClassName = styles.bigContainer;
  let contentContainerClassName = styles.contentContainer;
  let headerContainerClassName = "";
  if (isTablet || isMobile) {
    containerClassName = styles.tabletContainer;
    contentContainerClassName = styles.tabletContentContainer;
    headerContainerClassName = styles.tabletViewTop;
  }
  const detailCardsArray = [
    {
      label: "Total Revenue",
      name: "totalRevenue",
      value: getFormattedPrice(Math.round(data.currentPeriod.sumPurchasesValue * 100) / 100, currency),
    },
    {
      label: "Revenue from Ads",
      name: "ecomtrackRevenue",
      value: getFormattedPrice(Math.round(data.currentPeriod.sumPurchasesValueFromAds * 100) / 100, currency),
    },
    {
      label: "Total spend",
      name: "spend",
      value: getFormattedPrice(Math.round(data.currentPeriod.spend * 100) / 100, currency),
    },
    {
      label: "ROAS",
      name: "roas",
      value: getFormattedCellValue(
        getRoas(data.currentPeriod.sumPurchasesValueFromAds, data.currentPeriod.spend),
        getRoasMultiple,
      ),
    },
  ];
  return (
    <Container className={containerClassName}>
      <div className={styles.headerContainer}>
        <div className={headerContainerClassName}>
          <h3>Campaigns</h3>
        </div>
        {/* <div className={styles.attributionSwitchContainer}>
          <AttributionSwitch isInfoHidden={isMobile || isTablet} />
        </div> */}
      </div>
      <div className={contentContainerClassName}>
        {detailCardsArray.map((cardObj) => (
          <DetailCard key={cardObj.name} card={cardObj} />
        ))}
      </div>
    </Container>
  );
};

export const LoadingCampaigns: React.FC = () => {
  return (
    <Container className={styles.overview}>
      <div className={styles.header}>
        <h3>Campaigns</h3>
      </div>
      <Skeleton height={100} variant={SkeletonVariant.LIGHT} />
    </Container>
  );
};

export const LockedCampaigns: React.FC = () => {
  return (
    <Container className={styles.overview}>
      <div className={styles.header}>
        <h3>Campaigns</h3>
      </div>
      <LockedSection height={160} />
    </Container>
  );
};
