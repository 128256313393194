import React from "react";

import { useGetLoggedInUser } from "../../api/user";
import { Button, ButtonType, ButtonVariant } from "../../components/Button/Button";
import { useGetFormButtonProperties } from "../../hooks/componentSpecific/useGetFormButtonProperties";

import styles from "./PersonalSettingsForm.module.scss";

interface ConfirmButtonComponentProps {
  isButtonLoading: boolean;
  selectedImageString: string | null | undefined;
}

export const ConfirmButtonComponent: React.FC<ConfirmButtonComponentProps> = ({
  isButtonLoading,
  selectedImageString,
}) => {
  const { appliedButtonSize, isFullWidth } = useGetFormButtonProperties();
  const currentUser = useGetLoggedInUser();
  const userProfilePicture = currentUser.data?.profilePicture;

  const isButtonDisabled = (): boolean => {
    if (userProfilePicture && selectedImageString === "default") {
      return false;
    }
    if (userProfilePicture && userProfilePicture === selectedImageString) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.btnContainer}>
      <Button
        type={ButtonType.SUBMIT}
        variant={ButtonVariant.PRIMARY}
        size={appliedButtonSize}
        fullWidth={isFullWidth}
        disabled={isButtonDisabled()}
        loading={isButtonLoading}>
        Save changes
      </Button>
    </div>
  );
};
