import React from "react";
import ReactSelect from "react-select";

import { SelectDropdownIndicator } from "./SelectDropdownIndicator";

import styles from "./Select.module.scss";

export interface SelectOption {
  value: string;
  label: string;
}

export enum SelectType {
  SMALL = "small",
  BIG = "big",
}

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  options: SelectOption[];
  defaultVal?: SelectOption;
  selectType?: SelectType;
  isDisabled?: boolean;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, defaultVal, selectType = SelectType.BIG, isDisabled = false, ...props }: SelectProps, ref: any) => {
    const appliedClassName = selectType === SelectType.BIG ? styles.select : styles.selectSmall;

    return (
      <div className={styles.selectWrapper}>
        <div className={styles.label}>{props.label}</div>
        {/* @ts-ignore */}
        <ReactSelect
          components={{ DropdownIndicator: SelectDropdownIndicator }}
          options={options}
          classNamePrefix="select"
          className={appliedClassName}
          defaultValue={defaultVal}
          isDisabled={isDisabled}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
