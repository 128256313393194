import React from "react";
import classNames from "classnames";

import { TableBodyCellContentProps } from "../../../redux/types/table";
import { colorizeText } from "../../../utils/formatters/colorizeText";
import { getCamelCaseString } from "../../../utils/formatters/stringFormatters";
import { Icon } from "../../Icon/Icon";
import { Skeleton } from "../../Skeleton/Skeleton";

import styles from "./TableBody.module.scss";

export const TableBodyCellContent: React.FC<TableBodyCellContentProps> = ({
  props,
  col,
  loading,
  getColumnValue,
  rowClick,
}) => {
  const value = props.cell.value;
  const appliedColoredClassName = colorizeText(value, col.accessor);
  const isMainName = props.row.depth === 0 && col.accessor === "name";
  const cellValue = getColumnValue ? getColumnValue(col, props) : defaultGetColumnValue(props);
  const isChevronDisplayed =
    col.name && getCamelCaseString(col.name) === "src" && props.row.depth > 0 && props.row.canExpand;

  // show loading skeleton when table is laoding
  if (loading) {
    return (
      <div>
        <Skeleton round={col.skeleton?.round} height={col.skeleton?.height} width={col.skeleton?.width} />
      </div>
    );
  }

  if (isChevronDisplayed) {
    return (
      <div style={{ textAlign: col.alignment }} className={styles.chevronContainer}>
        <Icon name="chevron" rotation={props.row.isExpanded ? "0" : "-90deg"} />
      </div>
    );
  }

  if (col.accessor === "src") {
    return <div>{cellValue}</div>;
  }

  const getAppliedBodyCellClassName = () => {
    const appliedClassNameArray = [styles.tableBodyCellContent];
    if (col.coloredNumber) {
      appliedClassNameArray.push(styles[appliedColoredClassName]);
    }
    if (col.pointer) {
      appliedClassNameArray.push(styles.pointer);
    }
    if (isMainName) {
      appliedClassNameArray.push(styles.boldText);
    }

    const appliedBodyCellClassName = classNames(...appliedClassNameArray);
    return appliedBodyCellClassName;
  };

  return (
    <div
      onClick={() => {
        if (rowClick) rowClick(props.row, props.cell);
      }}
      className={getAppliedBodyCellClassName()}>
      {cellValue}
    </div>
  );
};

const defaultGetColumnValue = (props: any) => {
  const cellValue = props.cell.value;
  const formattedCellValue = cellValue ?? "-";
  const formattedCell = <>{formattedCellValue}</>;
  return formattedCell;
};
