import React from "react";
import { useSelector } from "react-redux";

import { useGetPricingPlanInfoForCurrent } from "../../../api/billing";
import { useLoadCurrentSubscriptionDetail } from "../../../hooks/billing/useLoadCurrentSubscriptionDetail";
import { getWorkspaceSubscriptionInfo } from "../../../redux/selectors/workspace";
import { getSubscriptionUsageRoundedString } from "../../../utils/getSubscriptionDetailInfo";
import { TariffUsageBar } from "../../TariffUsageBar/TariffUsageBar";

import styles from "./PlanUsage.module.scss";

export const PlanUsage: React.FC = () => {
  const currSubDetail = useGetPricingPlanInfoForCurrent();
  const subDetail = useSelector(getWorkspaceSubscriptionInfo);
  const { percentageUsed } = subDetail;
  const subscriptionUsageRoundedString = getSubscriptionUsageRoundedString(subDetail);
  useLoadCurrentSubscriptionDetail();

  if (currSubDetail.isLoading) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h3 className={styles.title}>Plan Usage</h3>
        <p className={styles.details}>{subscriptionUsageRoundedString}</p>
      </div>
      <TariffUsageBar percentage={percentageUsed} />
    </div>
  );
};
