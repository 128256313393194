import React, { ReactChild, ReactChildren } from "react";
import classNames from "classnames";

import { Icon } from "../Icon/Icon";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

import styles from "./Button.module.scss";

export type OnClickHandler = ((e: any) => void) | (() => Promise<void>);

export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERCIARY = "terciary",
  TEXT = "text",
  DANGER = "danger",
}

export enum ButtonSize {
  BIG = "big",
  MEDIUM = "medium",
  SMALL = "small",
}

export enum ButtonType {
  SUBMIT = "submit",
  BUTTON = "button",
}

export interface ButtonProps {
  label?: string;
  onClick?: OnClickHandler;
  variant: ButtonVariant;
  size?: ButtonSize;
  iconName?: string;
  loading?: boolean;
  fullWidth?: boolean;
  iconOnly?: boolean;
  children?: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
  type?: ButtonType;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  form?: string;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  variant,
  iconName,
  loading,
  fullWidth,
  iconOnly,
  children,
  type,
  className = "",
  style,
  size = ButtonSize.BIG,
  disabled,
  form,
}) => {
  const getAppliedButtonClassNames = () => {
    const appliedButtonClassNamesArray = [styles.button, styles[variant], styles[size]];
    if (fullWidth) appliedButtonClassNamesArray.push(styles.fullWidth);
    if (iconOnly) appliedButtonClassNamesArray.push(styles.iconOnly);
    if (disabled) appliedButtonClassNamesArray.push(styles.disabled);
    if (className) appliedButtonClassNamesArray.push(className);
    const appliedButtonClassNames = classNames(appliedButtonClassNamesArray);

    return appliedButtonClassNames;
  };

  const getAppliedIconWidthString = (): string => {
    let appliedWidthString = "18px";

    if (size === ButtonSize.SMALL) {
      appliedWidthString = "12px";
    }
    if (iconOnly) {
      appliedWidthString = "10px";
    }

    return appliedWidthString;
  };

  const getAppliedLoadingSpinnerSize = () => {
    let appliedLoadingSpinnerSize = 24;
    if (size === ButtonSize.MEDIUM) {
      appliedLoadingSpinnerSize = 20;
    }
    if (size === ButtonSize.SMALL) {
      appliedLoadingSpinnerSize = 16;
    }

    return appliedLoadingSpinnerSize;
  };

  const getAppliedContentClassNames = () => {
    const appliedContentClassNamesArray = [styles.content];

    if (loading) {
      appliedContentClassNamesArray.push(styles.hiddenContent);
    }

    const appliedContentClassNames = classNames(appliedContentClassNamesArray);
    return appliedContentClassNames;
  };

  const handleClick: OnClickHandler = (e) => {
    if (loading || !onClick) {
      return;
    }
    onClick(e);
  };

  return (
    <button
      form={form}
      className={getAppliedButtonClassNames()}
      style={style}
      onClick={handleClick}
      disabled={disabled}
      type={type ? type : "button"}>
      <div className={getAppliedContentClassNames()}>
        <div className={styles.innerContainer}>
          {iconName && <Icon name={iconName} width={getAppliedIconWidthString()} />}
          {children ?? <span>{label}</span>}
        </div>
        {loading && (
          <div className={styles.spinnerContainer}>
            <LoadingSpinner color="white" size={getAppliedLoadingSpinnerSize()} />
          </div>
        )}
      </div>
    </button>
  );
};
