import React from "react";

import { ContentWithMenu } from "../../components/ContentWithMenu/ContentWithMenu";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { WorkspaceSetupContent } from "./WorkspaceSetupContent/WorkspaceSetupContent";
import { WorkspaceSetupMenu } from "./WorkspaceSetupMenu/WorkspaceSetupMenu";

export const WorkspaceSetupPage: React.FC = () => {
  return (
    <PageContainer>
      <ContentWithMenu>
        <WorkspaceSetupMenu />
        <WorkspaceSetupContent />
      </ContentWithMenu>
    </PageContainer>
  );
};
