import React from "react";

import { Button, ButtonVariant } from "../../components/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { IModal } from "../../redux/types";

import styles from "./ConfirmationModal.module.scss";

interface ConfirmationModalProps extends IModal {
  onConfirm: () => void;
  children: React.ReactChild;
  title: string;
  onCancelText: string;
  onConfirmText: string;
  isButtonLoading?: boolean;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  visible,
  toggle,
  onConfirm,
  children,
  title,
  onCancelText,
  onConfirmText,
  isButtonLoading,
}) => {
  const handleToggle = () => toggle();

  return (
    <Modal visible={visible} animation="fadeIn" toggle={toggle} className={styles.confirmationModal}>
      <div className={styles.title}>{title}</div>

      <div className={styles.bottom}>
        <div>{children}</div>

        <div className={styles.buttons}>
          <Button variant={ButtonVariant.SECONDARY} fullWidth onClick={handleToggle}>
            {onCancelText}
          </Button>

          <Button variant={ButtonVariant.PRIMARY} fullWidth onClick={onConfirm} loading={isButtonLoading}>
            {onConfirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
