import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router";
import { Link } from "react-router-dom";

import { useResetPassword } from "../../api/auth";
import { Button, ButtonType, ButtonVariant } from "../../components/Button/Button";
import { Image } from "../../components/Image/Image";
import { Input } from "../../components/Input/Input";
import { SlideShow } from "../../components/SlideShow/SlideShow";
import { useMatchScreen } from "../../hooks/screen/useMatchScreen";
import { useUrlQuery } from "../../hooks/url/useUrlQuery";
import { showNotification } from "../../redux/reducers/notificationReducer";
import { store } from "../../redux/store";
import { NotificationSeverity } from "../../redux/types";

import styles from "../LoginPage/LoginPage.module.scss";

interface LoginInput {
  newPassword: string;
  newPasswordConfirmation: string;
}

export const ResetPasswordPage: React.FC = () => {
  const { register, handleSubmit } = useForm<LoginInput>();
  const isScreenMatch = useMatchScreen(950);
  const query = useUrlQuery();
  const history = useHistory();
  const { mutate, isLoading } = useResetPassword();
  const token = query.get("token");
  if (!token) {
    store.dispatch(
      showNotification({
        message: "No reset password token found in query",
        severity: NotificationSeverity.ERROR,
      }),
    );
    return <Redirect to={"/login"} />;
  }
  const onSubmit: SubmitHandler<LoginInput> = async (data) => {
    if (data.newPassword !== data.newPasswordConfirmation) {
      return store.dispatch(
        showNotification({ message: "Passwords don't match", severity: NotificationSeverity.ERROR }),
      );
    }
    mutate(
      { token, newPassword: data.newPassword },
      {
        onSuccess: () => {
          history.push("/login");
        },
      },
    );
  };

  return (
    <div className={styles.LoginPage}>
      <div className={styles.firstHalf}>
        <div className={styles.firstHalfContent}>
          <div className={styles.column}>
            <h1>{"Reset password"}</h1>
            <p className={styles.subtitle}>
              {
                "Reset password token found in query. After submitting the form, your password will be reset and you will be redirected to the login page. "
              }
            </p>
          </div>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <Input
              icon="lock"
              label="New password*"
              type="password"
              placeholder="new password"
              {...register("newPassword", { required: "This field is required" })}
              required
            />
            <Input
              icon="lock"
              label="Confirm new password*"
              type="password"
              placeholder="new password again"
              {...register("newPasswordConfirmation", { required: "This field is required" })}
              required
            />

            <div className={styles.buttons}>
              <Button variant={ButtonVariant.PRIMARY} type={ButtonType.SUBMIT} fullWidth={true} loading={isLoading}>
                Reset password
              </Button>
            </div>
            <div className={styles.row}>
              <span>
                Abort resetting and return to <Link to={"/login"}>Login page</Link>{" "}
              </span>
            </div>
          </form>
        </div>
        <div className={styles.logo}>
          <Image src="logo.svg" alt="ecomtrack logo" />
        </div>
      </div>
      {!isScreenMatch && (
        <div className={styles.secondHalf}>
          <SlideShow />
        </div>
      )}
    </div>
  );
};
