import React from "react";

import { useGetGoogleSignIn } from "../../api/google";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";

export const GoogleAuthenticationSignIn: React.FC = () => {
  useGetGoogleSignIn();

  return <LoadingSpinner />;
};
