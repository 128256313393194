import { useLocation } from "react-router";
import classNames from "classnames";

import { useGetBreakpointProperties } from "./useGetBreakpointProperties";

type AddWorkspaceModifiedClassNameGetter = (cssClassName: string, workspaceModifierClassName: string) => string;

interface DisplayedComponentsProperties {
  isAddWorkspacePath: boolean;
  isMainMenuShown: boolean;
  isHeaderShown: boolean;
  isFooterShown: boolean;
  getAddWorkspaceModifiedClassName: AddWorkspaceModifiedClassNameGetter;
}

export const useGetDisplayedComponentsProperties = (): DisplayedComponentsProperties => {
  const { isDesktop, isLargeDesktop } = useGetBreakpointProperties();
  const { pathname } = useLocation();
  const isAddWorkspacePath = pathname === "/add-workspace" || pathname === "/add-first-workspace";
  const isMainMenuShown = (isDesktop || isLargeDesktop) && !isAddWorkspacePath;
  const isHeaderShown = !isAddWorkspacePath;
  const isFooterShown = !isDesktop && !isLargeDesktop && !isAddWorkspacePath;

  const getAddWorkspaceModifiedClassName = (cssClassName: string, workspaceModifierClassName: string): string => {
    const appliedClassNameArray = [cssClassName];

    if (isAddWorkspacePath) {
      appliedClassNameArray.push(workspaceModifierClassName);
    }

    const addWorkspaceModifiedClassName = classNames(appliedClassNameArray);

    return addWorkspaceModifiedClassName;
  };

  const displayedComponentsProperties: DisplayedComponentsProperties = {
    isAddWorkspacePath,
    isMainMenuShown,
    isHeaderShown,
    isFooterShown,
    getAddWorkspaceModifiedClassName,
  };

  return displayedComponentsProperties;
};
