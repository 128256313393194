import React from "react";
import { Redirect, Route, Switch as RouterSwitch } from "react-router-dom";

import { useGetSelectedAdProviders } from "../../../hooks/integration/useGetSelectedAdProviders";
import { AdProviderEnum } from "../../../redux/types";
import { IAdsMenuItem } from "../IntegrationMenu/IntegrationMenu";
import { FacebookProvider } from "./AdProvidersComponents/FacebookProvider";
import { GoogleAdsProvider } from "./AdProvidersComponents/GoogleAdsProvider";
import { TikTokAdsProvider } from "./AdProvidersComponents/TiktokAdsProvider";

interface IExtendedAdsMenuItem extends IAdsMenuItem {
  component: React.FC;
}

export const AdProviders: React.FC = () => {
  const selectedAdsMenuItems = useGetSelectedAdProviders();
  const extendedSelectedAdsMenuItems: IExtendedAdsMenuItem[] = selectedAdsMenuItems.map((item) => {
    const { name } = item;

    const extendedSelectedAdsMenuItem: IExtendedAdsMenuItem = { ...item, component: GoogleAdsProvider };

    if (name === AdProviderEnum.FACEBOOK) {
      extendedSelectedAdsMenuItem.component = FacebookProvider;
    }
    if (name === AdProviderEnum.GOOGLE) {
      extendedSelectedAdsMenuItem.component = GoogleAdsProvider;
    }
    if (name === AdProviderEnum.TIKTOK) {
      extendedSelectedAdsMenuItem.component = TikTokAdsProvider;
    }
    return extendedSelectedAdsMenuItem;
  });

  return (
    <RouterSwitch>
      {extendedSelectedAdsMenuItems.map(({ name, component }) => (
        <Route key={name} path={`/integration/ad-providers/${name}`} component={component} />
      ))}
      <Route path="/integration/ad-providers/*">
        <Redirect to="/integration/ad-providers" />
      </Route>
    </RouterSwitch>
  );
};
