import React from "react";
import { Link } from "react-router-dom";

import { Button, ButtonSize, ButtonVariant } from "../../../components/Button/Button";
import { Container } from "../../../components/Container/Container";
import { WorkspaceManagerTable } from "../../../tables/WorkspaceManagerTable/WorkspaceManagerTable";

import styles from "./WorkspaceManager.module.scss";

export const WorkspaceManager: React.FC = () => {
  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Workspace Manager</h3>
        <div className={styles.right}>
          <Link to="/add-workspace">
            <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.SMALL}>
              Add new
            </Button>
          </Link>
        </div>
      </div>
      <WorkspaceManagerTable />
    </Container>
  );
};
