import React, { useState } from "react";
import classNames from "classnames";

import { BannerType } from "../../containers/DashboardPage/AlertBanner/AlertBanner";
import { Icon } from "../Icon/Icon";

import styles from "./HintBanner.module.scss";

interface HintBannerProps {
  children: React.ReactNode;
  type?: BannerType;
}

export const HintBanner: React.FC<HintBannerProps> = ({ children, type = BannerType.BLUE }) => {
  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => {
    setIsVisible(false);
  };

  const getAppliedBannerClassNames = () => {
    const appliedBannerClassNamesArray = [styles.banner];
    appliedBannerClassNamesArray.push(styles[type]);
    return classNames(appliedBannerClassNamesArray);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={getAppliedBannerClassNames()}>
      <div className={styles.left}>
        <Icon name={`ico-hint-${type}`} />
        <p className={styles.text}>{children}</p>
      </div>
      <Icon name={`ico-close-${type}`} width="16px" onClick={handleClose} className={styles.closeIcon} />
    </div>
  );
};
