import { SubscriptionStatus } from "../redux/types";

export const isDangerSubscriptionStatus = (subStatus: SubscriptionStatus | undefined): boolean => {
  return (
    subStatus === SubscriptionStatus.Empty ||
    subStatus === SubscriptionStatus.Pending ||
    subStatus === SubscriptionStatus.Error ||
    subStatus === SubscriptionStatus.Unapproved
  );
};
