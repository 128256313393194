import React from "react";
import classNames from "classnames";

import { IModal } from "../../../redux/types/app";
import { Icon } from "../../Icon/Icon";

import styles from "./HeaderWorkspaceSwitchButton.module.scss";

type SmallWorkspaceSwitchButtonProps = IModal;

export const SmallWorkspaceSwitchButton: React.FC<SmallWorkspaceSwitchButtonProps> = ({ visible, toggle }) => {
  const getAppliedButtonClasses = () => {
    const appliedButtonClassesArray = [styles.smallScreenButton];

    if (visible) {
      appliedButtonClassesArray.push(styles.whiteBg);
    }
    const appliedButtonClasses = classNames(...appliedButtonClassesArray);

    return appliedButtonClasses;
  };

  return (
    <div className={getAppliedButtonClasses()} onClick={() => toggle()}>
      <Icon name="bag" />
    </div>
  );
};
