import React, { useState } from "react";
import { useHistory } from "react-router";

import { useGetPricingPlanInfoForAllWorkspaces } from "../../api/billing";
import { useGetLoggedInUser } from "../../api/user";
import { useGetWorkspaceDetailMutation } from "../../api/workspace";
import { useDeleteWorkspace } from "../../api/workspace/mark-for-deletion";
import { useRestoreWorkspace } from "../../api/workspace/restore";
import { Table } from "../../components/Table/Table";
import { TableDropdown } from "../../components/TableDropdown/TableDropdown";
import { TariffUsage } from "../../components/TarrifUsage/TariffUsage";
import { useModal } from "../../hooks/modal/useModal";
import { useGetUserRoles } from "../../hooks/user/useGetUserRoles";
import { SubscriptionStatus, Workspace, WorkspaceRoleType } from "../../redux/types";
import { TableCellAlignment, TableColumn, TableType } from "../../redux/types/table";
import { getFormattedPrice } from "../../utils/formatters/currencyFormatters";
import { getDaysDifferenceString } from "../../utils/formatters/dateFormatters";
import { capitalizeString } from "../../utils/formatters/stringFormatters";
import { getSubscriptionDetailInfo } from "../../utils/getSubscriptionDetailInfo";
import { ConfirmationModalWrapper } from "./ConfirmationModalWrapper";

import styles from "./WorkspaceManagerTable.module.scss";

export const WorkspaceManagerTable: React.FC = () => {
  const roles = useGetUserRoles();
  const currentWorkspaceMutation = useGetWorkspaceDetailMutation();
  const currentUser = useGetLoggedInUser();
  const history = useHistory();
  const subDetails = useGetPricingPlanInfoForAllWorkspaces();
  const deleteWorkspaceMutation = useDeleteWorkspace();
  const reactivate = useRestoreWorkspace();
  const confirmation = useModal();
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const filteredRoles = roles.filter((role) => role.role === WorkspaceRoleType.ADMIN);

  const goToWorkspaceSettings = (id: number) => {
    currentWorkspaceMutation.mutate(id, {
      onSuccess: () => {
        history.push("/workspace-setup/settings");
      },
    });
  };

  const deleteCurrentWorkspace = (id: number) => {
    confirmation.toggle(false);
    deleteWorkspaceMutation.mutate(id);
  };

  const dropdownItems = (id: number, toBeDeletedAt: Date | null) => {
    const dropdownItemsArray: { name: string; color?: string; icon: string; onClick: () => void }[] = [];

    const editWorkspaceItem = {
      name: "Edit Workspace",
      icon: "ico_pen",
      onClick: () => goToWorkspaceSettings(id),
    };

    const deleteWorkspaceItem = {
      name: "Delete Workspace",
      color: "var(--alert)",
      icon: "ico_cloud",
      onClick: () => {
        setSelectedId(id);
        confirmation.toggle();
      },
    };

    const reactivateWorkspaceItem = {
      name: "Reactivate Workspace",
      icon: "ico_switch",
      onClick: () => {
        reactivate.mutate(id);
      },
    };

    dropdownItemsArray.push(editWorkspaceItem);

    if (toBeDeletedAt) {
      dropdownItemsArray.push(reactivateWorkspaceItem);
      return dropdownItemsArray;
    }
    dropdownItemsArray.push(deleteWorkspaceItem);

    return dropdownItemsArray;
  };

  const makeData = (data: Workspace) => {
    const subDetail = subDetails.data?.find((s) => s.workspaceId === data.id);
    const workspace = filteredRoles.find(({ workspace }) => workspace.id === subDetail?.workspaceId);
    const { percentageUsed } = getSubscriptionDetailInfo(subDetail);
    const roleObject = currentUser?.data?.roles?.find((role) => role.workspace.id === data?.id);
    const currWorkspaceUserRole = roleObject ? capitalizeString(roleObject?.role) : "";
    const toBeDeletedAt = data.toBeDeletedAt as Date;
    const daysDifferenceString = getDaysDifferenceString(toBeDeletedAt).daysDifferenceFullString;
    const capitalizedDaysDifferenceString = capitalizeString(daysDifferenceString);
    //I added subDetail.subStatus in the cache query in useDeactivateWorkspace etc.
    //so that the UI changes correctly when user does something to the workspace.
    // This is used to display status specific data and UI and for sorting.
    //@ts-ignore
    const subStatus = subDetail?.subStatus ?? workspace?.subscriptionStatus;

    const getTrackedAmountString = () => {
      let trackedAmountString = "";

      if (subDetail?.now) {
        trackedAmountString = getFormattedPrice(subDetail?.currentRevenue ?? 0, subDetail?.currency ?? "USD");
      }

      return trackedAmountString;
    };

    const getPrice = () => {
      let priceValue = <></>;
      if (subDetail?.now) {
        priceValue = <strong>{getFormattedPrice(subDetail?.now?.price ?? 0, subDetail?.currency ?? "USD")}/m</strong>;
      }

      return priceValue;
    };

    const getUsage = () => {
      let usageValue = <TariffUsage percentage={percentageUsed} status={subStatus} />;
      if (toBeDeletedAt) {
        usageValue = <>{capitalizedDaysDifferenceString}</>;
      }

      return usageValue;
    };

    return {
      name: data.name,
      role: currWorkspaceUserRole,
      tracked: getTrackedAmountString(),
      price: getPrice(),
      usage: getUsage(),
      settings: <TableDropdown items={dropdownItems(data.id, data.toBeDeletedAt)} />,
      subStatus,
    };
  };

  const getDisplayedData = () => {
    const transformedData = filteredRoles.map(({ workspace }) => makeData(workspace));

    return (
      transformedData
        //sort by name
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => {
          //then sort active first, unapproved 2nd etc.
          //@ts-ignore
          return subStatusValues.get(a.subStatus) - subStatusValues.get(b.subStatus);
        })
    );
  };

  const confirmationModalProps = { confirmation, deleteWorkspace: deleteCurrentWorkspace, selectedId };

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <Table
          cols={cols}
          data={getDisplayedData()}
          loading={subDetails.isLoading}
          tableType={TableType.WORKSPACE_MANAGER}
        />
        <ConfirmationModalWrapper {...confirmationModalProps} />
      </div>
    </div>
  );
};

const cols: TableColumn[] = [
  {
    name: "Name",
    accessor: "name",
    alignment: TableCellAlignment.LEFT,
    skeleton: { round: false, width: 0, height: 28 },
    minWidth: 35,
    style: { fontWeight: 500 },
  },
  {
    name: "Role",
    accessor: "role",
    alignment: TableCellAlignment.CENTER,
    minWidth: 30,
    skeleton: { round: false, width: 60, height: 28 },
  },
  {
    name: "Tariff usage",
    accessor: "usage",
    alignment: TableCellAlignment.CENTER,
    minWidth: 30,
    skeleton: { round: false, width: 60, height: 28 },
  },
  {
    name: "Tracked",
    accessor: "tracked",
    alignment: TableCellAlignment.CENTER,
    skeleton: { round: false, width: 60, height: 28 },
  },
  {
    name: "Paid",
    accessor: "price",
    alignment: TableCellAlignment.CENTER,
    skeleton: { round: false, width: 60, height: 28 },
  },
  {
    name: "",
    accessor: "settings",
    alignment: TableCellAlignment.CENTER,
    skeleton: { round: false, width: 0, height: 0 },
    disableLoading: true,
    maxWidth: 10,
    pointer: true,
    flexGrow: 0,
  },
];

//for sorting purposes.
const subStatusValues = new Map([
  [SubscriptionStatus.Active, 1],
  [SubscriptionStatus.Unapproved, 2],
  [SubscriptionStatus.Pending, 3],
  [SubscriptionStatus.Empty, 4],
  [SubscriptionStatus.Error, 5],
  [SubscriptionStatus.Ignored, 6],
]);
