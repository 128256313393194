import { useQuery } from "react-query";

import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { IntegrationDetails } from "../../../redux/types";

export const getIntegrationDetailUrl = (workspaceId: number) => `workspace/${workspaceId}/integration-status`;

export const useGetIntegrationDetails = () => {
  const currentId = useGetCurrentWorkspaceId();
  const integrationDetailUrl = getIntegrationDetailUrl(currentId);

  return useQuery<IntegrationDetails>([integrationDetailUrl], {
    enabled: !!currentId,
  });
};
