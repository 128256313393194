import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { Icon } from "../../../components/Icon/Icon";

import styles from "./CampaignsTable.module.scss";

interface ITableTooltip {
  formattedTooltipValue: string;
  formattedTooltipDifference: string | number;
  tooltipDifference: number;
  isLastCell: boolean;
  isLastRow: boolean;
  iconName: string;
}

export const TableTooltip: React.FC<ITableTooltip> = ({
  children,
  formattedTooltipValue,
  formattedTooltipDifference,
  tooltipDifference,
  iconName,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const animation = {
    hidden: {
      left: -80,
      right: "50%",
      transform: "translateX(-50%) scale(1)",
      top: "-80%",
      opacity: 0,
    },
    visible: {
      top: "-80%",
      margin: "auto",
      left: -80,
      transform: "translateX(-50%) scale(1)",
      right: "50%",
      opacity: 1,

      transition: {
        type: "spring",
        damping: 22,
        stiffness: 200,
      },
    },
  };

  return (
    <AnimatePresence initial>
      <div
        className={styles.tooltip}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}>
        {tooltipVisible && (
          <motion.div
            className={styles.tooltipContainer}
            variants={animation}
            initial="hidden"
            animate="visible"
            exit="exit">
            <div className={styles.tooltipContent}>
              {iconName && <Icon name={iconName} width="14px" className={styles.tooltipIcon} />}
              <div className={styles.tooltipNumbers}>
                {formattedTooltipValue}
                <div>
                  <Icon name={tooltipDifference > 0 ? "arrow-green" : "arrow-red"} width="14px" />
                  <span className={styles.tooltipDifference}>{formattedTooltipDifference}</span>
                </div>
              </div>
            </div>
          </motion.div>
        )}
        {children}
      </div>
    </AnimatePresence>
  );
};
