import { useEffect } from "react";

export const useLiveAgentScript = (): void => {
  const innerFunctionText = `(function (d, src, c) {
    var t = d.scripts[d.scripts.length - 1],
      s = d.createElement("script");
    s.id = "la_x2s6df8d";
    s.async = true;
    s.src = src;
    s.onload = s.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != "complete" && rs != "loaded") {
        return;
      }
      c(this);
    };
    t.parentElement.insertBefore(s, t.nextSibling);
  })(document, "https://ecomtrack.ladesk.com/scripts/track.js", function (e) {
    LiveAgent.createButton("k52lyj0t", e);
  })`;

  useEffect(() => {
    const script = document.createElement("script");

    script.innerHTML = innerFunctionText;

    const rootElement = document.getElementById("root");

    rootElement?.parentNode?.insertBefore(script, rootElement.nextSibling);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
