import React from "react";
import { useSelector } from "react-redux";

import { getAllCampaignCols } from "../../../redux/selectors/campaigns";
import { TableColumnDropdownItem } from "../TableColumnDropdownItem/TableColumnDropdownItem";

import styles from "./TableColumnDropdown.module.scss";

export const TableColumnDropdown: React.FC = () => {
  const campaignsTableCols = useSelector(getAllCampaignCols);
  const filteredColumns = campaignsTableCols.filter((col) => col.accessor !== "src" && col.accessor !== "name");

  return (
    <ul className={styles.dropdown}>
      {filteredColumns.map((col) => (
        <TableColumnDropdownItem key={col.accessor} col={col} />
      ))}
    </ul>
  );
};
