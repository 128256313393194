import { useMutation } from "react-query";

import { defaultMutationFn } from "../../api";
import { RequestPasswordResetTokenRequestDTO } from "./request";

export const postRequestPasswordResetUrl = "auth/request-password-reset";

export const useRequestPasswordReset = () => {
  return useMutation((data: RequestPasswordResetTokenRequestDTO) => {
    return defaultMutationFn(postRequestPasswordResetUrl, { method: "POST", data });
  });
};
