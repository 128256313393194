import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { AdProviderEnum, NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";

export interface SetAdProvidersRequestDTO {
  workspaceId: number;
  adProviders: AdProviderEnum[];
}

export const usePutAdProvidersMutation = () => {
  return useMutation(
    (data: SetAdProvidersRequestDTO) => {
      return defaultMutationFn(`workspace/${data.workspaceId}/ad-providers`, {
        method: "PUT",
        data,
      });
    },
    {
      onMutate: async (newData) => {
        return queryClient.getQueryData([`workspace/${newData.workspaceId}`]);
      },
      onSuccess: async (data, vars) => {
        await queryClient.invalidateQueries([`workspace/${vars.workspaceId}`]);
        store.dispatch(
          showNotification({
            message: "Workspace successfully updated",
            severity: NotificationSeverity.SUCCESS,
          }),
        );
      },
      onError: (_, vars, context) => {
        queryClient.setQueryData([`workspace/${vars.workspaceId}`], context);
      },
    },
  );
};
