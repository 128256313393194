import { useMemo } from "react";

import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { platformObjects } from "../../containers/IntegrationPage/IntegrationContent/EcommercePlatforms";
import { EcommercePlatformObject } from "../../redux/types/workspace";

export const useGetSelectedEcommercePlatformObject = (): EcommercePlatformObject => {
  const { data: currentWorkspaceData } = useGetCurrentWorkspaceDetail();

  const selectedPlatformObject: EcommercePlatformObject = useMemo(() => {
    let selectedObj: EcommercePlatformObject = platformObjects[0];
    if (!currentWorkspaceData) {
      return selectedObj;
    }

    const selectedObject = platformObjects.find((platform) => {
      return currentWorkspaceData.eCommercePlatform === platform.platformName;
    });
    if (selectedObject) {
      selectedObj = selectedObject;
    }

    return selectedObj;
  }, [currentWorkspaceData, platformObjects]);
  return selectedPlatformObject;
};
