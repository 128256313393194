import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { useLogin } from "../../api/auth";
import { Button, ButtonType, ButtonVariant } from "../../components/Button/Button";
import { CheckBox } from "../../components/CheckBox/CheckBox";
import { Image } from "../../components/Image/Image";
import { Input } from "../../components/Input/Input";
import { SlideShow } from "../../components/SlideShow/SlideShow";
import { useMatchScreen } from "../../hooks/screen/useMatchScreen";

import styles from "./LoginPage.module.scss";

interface LoginInput {
  email: string;
  password: string;
  remember: boolean;
}

export const LoginPage: React.FC = () => {
  const [remember, setRemember] = useState(true);
  const { register, handleSubmit } = useForm<LoginInput>();
  const isScreenMatch = useMatchScreen(950);
  const { mutate, isLoading } = useLogin();
  const onSubmit: SubmitHandler<LoginInput> = async (data) => {
    mutate({ ...data });
  };
  const handleCheckbox = (checked: boolean) => {
    setRemember(checked);
  };

  return (
    <div className={styles.LoginPage}>
      <div className={styles.firstHalf}>
        <div className={styles.firstHalfContent}>
          <div className={styles.column}>
            <h1>{"Get started!"}</h1>
            <p className={styles.subtitle}>{"Nice to see you again. Are you to ready to rock?"}</p>
          </div>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <Input
              icon="email"
              label="Email*"
              placeholder="email"
              type="email"
              {...register("email", { required: "This field is required" })}
              required
            />
            <Input
              icon="lock"
              label="Password*"
              type="password"
              placeholder="password"
              {...register("password", { required: "This field is required" })}
              required
            />

            <div className={styles.multiRow}>
              <CheckBox onChange={(checked) => handleCheckbox(checked)} label="Remember me?" checked={remember} />
              <Link to="/recover-password">Forgot password?</Link>
            </div>
            <div className={styles.buttons}>
              <Button variant={ButtonVariant.PRIMARY} type={ButtonType.SUBMIT} fullWidth={true} loading={isLoading}>
                Log in
              </Button>
            </div>
          </form>
          <div className={styles.row}>
            <span>
              Don&apos;t have an account? <Link to={"/register"}>Create one</Link>{" "}
            </span>
          </div>
        </div>
        <div className={styles.logo}>
          <Image src="logo.svg" alt="ecomtrack logo" />
        </div>
      </div>
      {!isScreenMatch && (
        <div className={styles.secondHalf}>
          <SlideShow />
        </div>
      )}
    </div>
  );
};
