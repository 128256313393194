import { GetPricingPlanInfoResponseDTO } from "../api/billing/get-pricing-plan-info/dto";
import { CurrencyType } from "../redux/types";
import { getFormattedPrice } from "./formatters/currencyFormatters";

export interface SubscriptionDetailInfo {
  percentageUsed: number;
  hasASubscription: boolean;
  maxLevel: boolean;
  currentRevenue: number;
  subscriptionLimit: number;
  subscriptionLimitRoundedString: string;
  currentRevenueRoundedString: string;
  currency: CurrencyType;
  price: number;
  priceRoundedString: string;
}

const getRoundToThousandsString = (thousands: number, currency?: CurrencyType): string => {
  const thousandsDivided = Math.floor(thousands / 1000);
  let roundToThousandsString = String(thousandsDivided);
  if (currency) {
    roundToThousandsString = getFormattedPrice(thousandsDivided, currency);
  }
  roundToThousandsString += "k";

  return roundToThousandsString;
};

export const getSubscriptionDetailInfo = (subDetail?: GetPricingPlanInfoResponseDTO): SubscriptionDetailInfo => {
  const hasASubscription = Boolean(subDetail?.now);
  const currentRevenue = subDetail?.currentRevenue ?? 0;
  const subscriptionLimit = subDetail?.next?.revenueMin ?? 0;
  const currency = subDetail?.currency ?? CurrencyType.USD;
  const price = subDetail?.now?.price ?? 0;
  const maxLevel = subDetail?.now?.revenueMax === 0;
  const subscriptionLimitRoundedString = getRoundToThousandsString(subscriptionLimit);
  const currentRevenueRoundedString = getRoundToThousandsString(currentRevenue);
  const priceRoundedString = getFormattedPrice(price, currency);
  const percentageUsed =
    Math.round(((100 * (subDetail?.currentRevenue ?? 0)) / (subDetail?.now?.revenueMax ?? 1)) * 10) / 10;

  const subscriptionDetailInfo: SubscriptionDetailInfo = {
    percentageUsed,
    hasASubscription,
    maxLevel,
    currentRevenue,
    subscriptionLimit,
    subscriptionLimitRoundedString,
    currentRevenueRoundedString,
    currency,
    price,
    priceRoundedString,
  };

  return subscriptionDetailInfo;
};

export const getSubscriptionUsageRoundedString = (
  subscriptionDetailInfo: SubscriptionDetailInfo,
  long?: boolean,
): string => {
  const { hasASubscription, currentRevenueRoundedString, subscriptionLimitRoundedString } = subscriptionDetailInfo;
  let subscriptionUsageRoundedString = "Unlimited";
  if (!hasASubscription) {
    return subscriptionUsageRoundedString;
  }

  subscriptionUsageRoundedString = `${currentRevenueRoundedString} / ${subscriptionLimitRoundedString}`;
  if (long) {
    // must be adjusted to account for the workspace currency symbol!
    subscriptionUsageRoundedString += " tracked in the last 30 days";
  }

  return subscriptionUsageRoundedString;
};
