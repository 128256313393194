import { useMutation, useQuery } from "react-query";

import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { useSwitchWorkspace } from "../../../hooks/workspace/useSwitchWorkspace";
import { invalidateSession } from "../../../utils/localStorageHandlers";
import { defaultMutationFn, queryClient } from "../../api";
import { WorkspaceWithRolesDTO } from "./dto";

//Mutation to allow easy passing of workspaceId and calling imperatively
//only when we need it. Prefer using GetCurrentWorkspaceDetail.
export const useGetWorkspaceDetailMutation = () => {
  return useMutation(
    (workspaceId: number) => {
      return defaultMutationFn(`workspace/${workspaceId}`, { method: "GET", data: undefined });
    },
    {
      onSuccess: (data: WorkspaceWithRolesDTO) => {
        queryClient.setQueryData([`workspace/${data.id}`], data);
        useSwitchWorkspace(data);
      },
    },
  );
};

export const useGetCurrentWorkspaceDetail = () => {
  const currentId = useGetCurrentWorkspaceId();

  return useQuery<WorkspaceWithRolesDTO>([`workspace/${currentId}`], {
    enabled: !!currentId,

    onError: () => {
      invalidateSession();
    },
    onSuccess: (data: WorkspaceWithRolesDTO) => {
      useSwitchWorkspace(data);
    },
  });
};
