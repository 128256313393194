import { useMutation } from "react-query";

import { setJwt } from "../../../redux/reducers/appReducer";
import { store } from "../../../redux/store";
import { setJwtToLocalStorage } from "../../../utils/localStorageHandlers";
import { defaultMutationFn } from "../../api";
import { LoginUserRequestDTO } from "./request";
import { LoginUserResponseDTO } from "./response";

export const postLoginUrl = "auth/login";

export const useLogin = () => {
  return useMutation(
    (data: LoginUserRequestDTO) => defaultMutationFn(postLoginUrl, { data: { ...data, remember: undefined } }),
    {
      onSuccess: (data: LoginUserResponseDTO) => {
        setJwtToLocalStorage(data.token);
        store.dispatch(setJwt(data.token));
      },
    },
  );
};
