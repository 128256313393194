import React from "react";
import { useExpanded, useFlexLayout, useSortBy, useTable } from "react-table";

import { TableCellAlignment, TableProps, TableType } from "../../redux/types/table";
import { getCamelCaseString } from "../../utils/formatters/stringFormatters";
import { TableBody } from "./TableBody/TableBody";
import { TableBodyCellContent } from "./TableBody/TableBodyCellContent";
import { TableHeader } from "./TableHeader/TableHeader";
import { TableHeaderCellContent } from "./TableHeader/TableHeaderCellContent";

import styles from "./Table.module.scss";

export const Table: React.FC<TableProps> = ({
  cols,
  data,
  loading,
  sortBy,
  rowClick,
  maxHeight,
  defaultSortBy,
  getRowClass,
  getColumnValue,
  tableType,
}) => {
  const tableData = React.useMemo(() => (loading ? Array(10).fill({}) : data), [loading, data]);
  const columns: any[] = React.useMemo(
    () =>
      cols.map((col: any) => {
        const columnObjectProperties = {
          name: col.name,
          // if the sortBy array doesn't contain the column id, disable sort for that column.
          disableSortBy: !(sortBy && sortBy.filter((obj) => obj.id === col.accessor).length > 0) ?? false,
          accessor: col.accessor || getCamelCaseString(col.name),
          isRealColumn: col.isRealColumn,
          isEcomtrackVsFbColumn: col.isEcomtrackVsFbColumn,
          alignment: col.alignment,
          minWidth: col.minWidth ?? 30, // minWidth is only used as a limit for resizing
          width: col.width ?? 30, // width is used for both the flex-basis and flex-grow
          maxWidth: col.maxWidth, // maxWidth is only used as a limit for resizing
          flexGrow: col.flexGrow,
          isHidden: col.isHidden,
        };

        const bodyCellContentProps = {
          col,
          loading,
          getColumnValue,
          rowClick,
        };

        const tableCellWithSurroundingsConstuctor = TableCellContentWithSurroundings(bodyCellContentProps);

        return {
          Header: <TableHeaderCellContent col={col} />,
          Cell: tableCellWithSurroundingsConstuctor,
          ...columnObjectProperties,
        };
      }),
    [cols, sortBy, loading],
  );

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      autoResetSortBy: false,
      initialState: {
        // @ts-ignore
        expanded: {
          "1": true,
          sortBy: defaultSortBy ? [defaultSortBy] : [],
        },
      },
    },
    useSortBy,
    useFlexLayout,
    useExpanded,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    setSortBy,
    rowsById,
  } = tableInstance;

  const tableHeaderProps = { tableType, headerGroups, setSortBy, sortBy };
  const tableBodyProps = { tableType, getTableBodyProps, rows, prepareRow, getRowClass, rowsById };
  const headersArray = headerGroups[0].headers;
  //@ts-ignore
  const hiddenHeadersArray = headersArray.filter((header) => header.isHidden);
  const isHeaderHidden = hiddenHeadersArray.length === headersArray.length;
  const appliedHeaderMaxHeight = maxHeight ?? "100%";

  return (
    <div
      className={tableType === TableType.CAMPAIGNS ? styles.campaignsTableContainer : styles.tableContainer}
      style={{ maxHeight: appliedHeaderMaxHeight }}>
      <table {...getTableProps()} cellSpacing="20">
        {!isHeaderHidden && <TableHeader {...tableHeaderProps} />}
        <TableBody {...tableBodyProps} />
      </table>
    </div>
  );
};

export const getCellFlexTableCellAlignmentClassName = (alignment: TableCellAlignment) => {
  let cellFlexTableCellAlignmentClassName = "alignedCenter";
  if (alignment === TableCellAlignment.LEFT) {
    cellFlexTableCellAlignmentClassName = "alignedFlexStart";
  }
  if (alignment === TableCellAlignment.RIGHT) {
    cellFlexTableCellAlignmentClassName = "alignedFlexEnd";
  }

  return cellFlexTableCellAlignmentClassName;
};

const TableCellContentWithSurroundings = (outerProps: any) => {
  return (props: any) => <TableBodyCellContent {...outerProps} props={props} />;
};
