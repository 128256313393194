import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useGetCampaignsFromCurrentDetails } from "../../api/views";
import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import {
  restoreDefaultCampaigns,
  setCampaigns,
  setDataContentStatus,
  setExtendedCampaigns,
} from "../../redux/reducers/campaignReducer";
import { AdProviderEnum, Campaign, ExtendedCampaign, IAggregatedCampaigns, Workspace } from "../../redux/types";
import { transformCampaignsData } from "../../utils/transformCampaignsData";
import { useGetSubscriptionStates } from "../billing/useGetSubscriptionStates";

export const useLoadCampaigns = (): void => {
  const dispatch = useDispatch();

  const { isSuccessSubscriptionStatus } = useGetSubscriptionStates();

  const { data: currentWorkspace } = useGetCurrentWorkspaceDetail();

  const { data: campaignsData } = useGetCampaignsFromCurrentDetails();
  const initializeCampaigns = () => dispatch(restoreDefaultCampaigns());
  const updateCampaigns = (campaignsData: IAggregatedCampaigns) => {
    const filteredCampaignsData = getFilteredCampaignsData(currentWorkspace, campaignsData);

    const campaignsArray = transformCampaignsData(filteredCampaignsData);
    const extendedCampaignsArray = getExtendedCampaigns(campaignsArray);

    dispatch(setCampaigns(campaignsArray));
    dispatch(setExtendedCampaigns(extendedCampaignsArray));
    dispatch(setDataContentStatus(campaignsData.dataContentStatus));
  };

  const loadCampaigns = () => {
    if (!isSuccessSubscriptionStatus) {
      initializeCampaigns();
      return;
    }
    if (campaignsData) {
      updateCampaigns(campaignsData);
    }
  };

  useEffect(() => {
    loadCampaigns();
  }, [isSuccessSubscriptionStatus, campaignsData, currentWorkspace]);
};

const getFilteredCampaignsData = (currentWorkspace?: Workspace, campaignsData?: IAggregatedCampaigns) => {
  const campaignsDataObject = {} as IAggregatedCampaigns;

  for (const key in campaignsData) {
    const campaign = currentWorkspace?.adProviders.find(
      (adProvider) => adProvider === key || key === AdProviderEnum.ORGANIC,
    );

    if (!campaign) {
      continue;
    }

    campaignsDataObject[key] = campaignsData[key];
  }

  return campaignsDataObject;
};

const getExtendedCampaigns = (campaignsData: Campaign[]): ExtendedCampaign[] =>
  campaignsData.map((obj, i) => {
    const extendedCampaign: ExtendedCampaign = {
      ...obj,
      expanded: false,
      lastExpanded: false,
      main: true,
      adsets: [],
      ads: [],
      rootId: obj.id ? obj.id : -i,
      parentId: obj.id ? obj.id : -i,
      id: obj.id ? obj.id : -i,
      depth: 1,
    };

    return extendedCampaign;
  });
