import { useMutation } from "react-query";

import { setJwt } from "../../../redux/reducers/appReducer";
import { store } from "../../../redux/store";
import { setJwtToLocalStorage } from "../../../utils/localStorageHandlers";
import { defaultMutationFn } from "../../api";
import { LoginUserResponseDTO } from "../login/response";
import { RegisterUserRequestDTO } from "./request";

export const postRegisterUrl = "auth/register";

export const useRegister = () => {
  return useMutation((data: RegisterUserRequestDTO) => defaultMutationFn(postRegisterUrl, { data }), {
    onSuccess: (data: LoginUserResponseDTO) => {
      setJwtToLocalStorage(data.token);
      store.dispatch(setJwt(data.token));
    },
  });
};
