import { useMutation } from "react-query";

import { useGetUserRoles } from "../../../hooks/user/useGetUserRoles";
import { showNotification, somethingWentWrong } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { NotificationSeverity } from "../../../redux/types";
import { getDaysDifferenceString } from "../../../utils/formatters/dateFormatters";
import { defaultMutationFn } from "../../api";
import { useGetLoggedInUser } from "../../user";

export const useDeleteWorkspace = () => {
  const { refetch } = useGetLoggedInUser();

  const roles = useGetUserRoles();

  return useMutation((id: number) => defaultMutationFn(`workspace/${id}`, { method: "DELETE" }), {
    onSuccess: (data, selectedId) => {
      const selectedWorkspace = roles.find(({ workspace }) => workspace.id === selectedId)?.workspace;

      const toBeDeletedAt = data.toBeDeletedAt;

      const daysDifference = getDaysDifferenceString(toBeDeletedAt).daysDifferenceString;

      refetch();
      store.dispatch(
        showNotification({
          severity: NotificationSeverity.SUCCESS,
          message: `Done! Workspace ${selectedWorkspace?.name} was deactivated and will be deleted in ${daysDifference}.`,
          hideAfter: 7000,
        }),
      );
    },
    onError: () => {
      store.dispatch(somethingWentWrong());
    },
  });
};
