import { WorkspaceRoleDTO, WorkspaceWithRolesDTO } from "../../api/workspace/get-one/dto";

export const getPresentableUsers = (workspace: WorkspaceWithRolesDTO): WorkspaceRoleDTO[] => {
  const users = [...workspace.roles];
  for (const invitedUser of workspace.invitedUsers) {
    users.push({
      email: invitedUser.email,
      role: invitedUser.role,
      firstName: "(invited)",
      lastName: "",
      profilePictureUrl: null,
    });
  }
  return users;
};
