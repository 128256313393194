import { Campaign, DataContentStatus, ExtendedCampaign, RootState } from "../types";
import { TableColumn } from "../types/table";

export const getCampaigns = (state: RootState): Campaign[] => state.campaigns.campaigns;

export const getCampaignsDataContentStatus = (state: RootState): DataContentStatus => state.campaigns.dataContentStatus;

export const getExtendedCampaigns = (state: RootState): ExtendedCampaign[] => state.campaigns.extendedCampaigns;

export const getAllCampaignCols = (state: RootState): TableColumn[] => state.campaigns.campaignsTableCols.all;

export const getTemporaryCampaignCols = (state: RootState): TableColumn[] =>
  state.campaigns.campaignsTableCols.temporarilySelected;
