import { useMutation } from "react-query";

import { defaultMutationFn } from "../../api";
import { ResetPasswordRequestDTO } from "./request";

export const postResetPasswordUrl = "auth/reset-password";

export const useResetPassword = () => {
  return useMutation((data: ResetPasswordRequestDTO) => {
    return defaultMutationFn(postResetPasswordUrl, { method: "POST", data });
  });
};
