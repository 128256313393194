import React from "react";
import classNames from "classnames";

import { TableHeaderProps, TableType } from "../../../redux/types/table";
import { TableHeaderRow } from "./TableHeaderRow";

import styles from "./TableHeader.module.scss";

export const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const { tableType } = props;

  const getAppliedTableHeaderClassName = () => {
    const appliedTableHeaderClassNamesArray = [styles.tableHeader];
    if (tableType === TableType.CAMPAIGNS) {
      appliedTableHeaderClassNamesArray.push(styles.campaignsTableHeader);
    }

    const appliedTableHeaderClassName = classNames(...appliedTableHeaderClassNamesArray);
    return appliedTableHeaderClassName;
  };
  return (
    <thead className={getAppliedTableHeaderClassName()}>
      {props.headerGroups.map((headerGroup) => {
        return <TableHeaderRow headerGroup={headerGroup} {...props} key={headerGroup.id} />;
      })}
    </thead>
  );
};
