import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";

import { isAllowed, useGetUserRole } from "../../hooks/user/useGetUserRole";
import { changeHeaderTitle } from "../../redux/reducers/appReducer";
import { getJwt } from "../../redux/selectors/app";
import { WorkspaceRoleType } from "../../redux/types";

interface PrivateRouteProps extends RouteProps {
  component: any;
  title: string;
  requiredRoles?: WorkspaceRoleType[];
  isHidden?: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  title,
  requiredRoles,
  isHidden,
  ...rest
}) => {
  const userRole = useGetUserRole();
  const jwt = useSelector(getJwt);
  const dispatch = useDispatch();
  const hasPermissions = isAllowed(
    userRole,
    requiredRoles || [WorkspaceRoleType.ADMIN, WorkspaceRoleType.CLIENT, WorkspaceRoleType.STAFF],
  );
  useEffect(() => {
    dispatch(changeHeaderTitle(title));
  }, [title]);

  const handleRender = (props: RouteComponentProps) => {
    if (userRole || jwt) {
      return (
        <>
          {!hasPermissions || isHidden ? (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: props.location },
              }}
            />
          ) : (
            <Component {...props} />
          )}
        </>
      );
    }
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...rest} render={handleRender} />;
};
