import { DataContentStatus, SubscriptionStatus } from "../../../redux/types";
import { AlertBannerVariant } from "./AlertBanner";
import { AlertBannerName } from "./types";
import { useGetAllAlertBanners } from "./useGetAllAlertBanners";

export const useGetBannerToShow = (
  subscriptionStatus: SubscriptionStatus,
  dataContentStatus: DataContentStatus,
): AlertBannerVariant | null => {
  const hasNoData: boolean = dataContentStatus === DataContentStatus.NO_DATA;
  const hasNoCurrentData: boolean = dataContentStatus === DataContentStatus.NO_DATA_IN_PERIOD_AND_DATA_OUTSIDE;
  const hasNoDataYoungerThanWeek: boolean = dataContentStatus === DataContentStatus.NO_DATA_AND_YOUNGER_THAN_WEEK;
  const allBanners = useGetAllAlertBanners();
  const findBannerByName = (name: AlertBannerName): AlertBannerVariant => {
    return allBanners.find((banner) => banner.name === name) as AlertBannerVariant;
  };
  if (subscriptionStatus === SubscriptionStatus.Empty) {
    return findBannerByName(AlertBannerName.PAYMENT_EMPTY);
  }
  if (subscriptionStatus === SubscriptionStatus.Error) {
    return findBannerByName(AlertBannerName.PAYMENT_ERROR);
  }
  if (subscriptionStatus === SubscriptionStatus.Unapproved) {
    return findBannerByName(AlertBannerName.PAYMENT_UNAPPROVED);
  }
  if (subscriptionStatus === SubscriptionStatus.Pending) {
    return findBannerByName(AlertBannerName.PAYMENT_PENDING);
  }
  if (hasNoData) {
    return findBannerByName(AlertBannerName.NO_DATA);
  }
  if (hasNoCurrentData) {
    return findBannerByName(AlertBannerName.NO_DATA_IN_PERIOD_AND_DATA_OUTSIDE);
  }
  if (hasNoDataYoungerThanWeek) {
    return findBannerByName(AlertBannerName.NO_DATA_AND_YOUNGER_THAN_WEEK);
  }
  return null;
};
