import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { DateRange } from "../../redux/types";
import { getMillisecondsFromDate, getUrlDateRange, toLocalDateString } from "../../utils/formatters/dateFormatters";

interface DatesObject {
  from: string;
  to: string;
}

interface DateUrlParametersSetters {
  datePickerState: DateRange;
  setDatePickerState: Dispatch<SetStateAction<DateRange>>;
  setUrlParams: () => void;
}

export const useSetDateUrlParams = (): DateUrlParametersSetters => {
  const history = useHistory();
  const { pathname, search } = history.location;
  const { from, to } = getUrlDateRange();
  const [datePickerState, setDatePickerState] = useState<DateRange>({
    startDate: new Date(from),
    endDate: new Date(to),
  });

  const getNewUrlSearch = (): string => {
    const urlParams = new URLSearchParams(window.location.search);
    const fromNumber = getMillisecondsFromDate(datePickerState.startDate);
    const toNumber = getMillisecondsFromDate(datePickerState.endDate);
    const from = toLocalDateString(new Date(fromNumber));
    const to = toLocalDateString(new Date(toNumber));
    const datesObj: DatesObject = { from, to };

    let key: keyof DatesObject;
    for (key in datesObj) {
      urlParams.set(key, String(datesObj[key]));
    }

    return `?${urlParams.toString()}`;
  };
  const newUrlSearch = getNewUrlSearch();

  const setUrlParams = (): void => {
    history.push(newUrlSearch);
  };

  useEffect(() => {
    if (search !== newUrlSearch) {
      setUrlParams();
    }
  }, [pathname, search]);

  return { datePickerState, setDatePickerState, setUrlParams };
};
