import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { queryClient } from "../../api/api";
import { getIntegrationDetailUrl } from "../../api/workspace";
import { showNotification } from "../../redux/reducers/notificationReducer";
import { NotificationInput } from "../../redux/types";
import { useGetCurrentWorkspaceId } from "../workspace/useGetCurrentWorkspaceId";

export const useReceivePopupWindowMessage = (): void => {
  const dispatch = useDispatch();
  const workspaceId = useGetCurrentWorkspaceId();

  const receiveMessage = async (event: any) => {
    const sourcePathname = "/authentication/result";
    if (event.origin !== window.location.origin || !event.source || !event.data) {
      return;
    }
    if (event.source.location.pathname === sourcePathname && event.data.isGoogleAuthMessage) {
      await queryClient.invalidateQueries([getIntegrationDetailUrl(workspaceId)]);

      const notificationInput: NotificationInput = { message: event.data.message, severity: event.data.severity };
      dispatch(showNotification(notificationInput));
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessage);

    return () => {
      document.removeEventListener("message", receiveMessage);
    };
  }, []);
};
