import { parseNumber } from "./stringFormatters";

export const getColorizedTextClassName = (value: string | number, benchmark = 0): string => {
  let number = Number(value);

  if (typeof value === "string") {
    number = parseNumber(value);
  }

  let returnedColorText = "breakeven";
  if (number > benchmark) {
    returnedColorText = "profit";
  }
  if (number < benchmark) {
    returnedColorText = "loss";
  }

  return returnedColorText;
};

export const colorizeText = (value: any, accessor: string): string => {
  const isResultConvertible = !isNaN(parseInt(value));
  let valueArg = value;
  let benchmark = 0;

  if (accessor === "roas" && isResultConvertible && value !== 0) {
    valueArg = parseInt(value) / 100;
    benchmark = 1;
  }
  const colorizedText = getColorizedTextClassName(valueArg, benchmark);

  return colorizedText;
};
