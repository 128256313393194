import React from "react";
import classNames from "classnames";

import { Icon } from "../Icon/Icon";

import styles from "./CustomCheckmark.module.scss";

interface CustomCheckmarkProps {
  isActive?: boolean;
  isDisabled?: boolean;
}

export const CustomCheckmark: React.FC<CustomCheckmarkProps> = ({ isActive, isDisabled }) => {
  const getAppliedClassNames = () => {
    const appliedClassNamesArray = [styles.container];
    if (isActive) {
      appliedClassNamesArray.push(styles.active);
    }
    if (isDisabled) {
      appliedClassNamesArray.push(styles.disabled);
    }

    const appliedClassNames = classNames(appliedClassNamesArray);
    return appliedClassNames;
  };

  return (
    <div className={getAppliedClassNames()}>
      <Icon name="ico-checkmark-square" />
    </div>
  );
};
