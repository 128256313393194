import React from "react";

import { useDeleteProfilePicture, useGetLoggedInUser } from "../../api/user";
import { Icon } from "../../components/Icon/Icon";
import { ProfilePicture } from "../../components/ProfilePicture/ProfilePicture";

import styles from "./PersonalSettingsForm.module.scss";

interface ImageInputComponentProps {
  selectedImage: File | null;
  setSelectedImage: React.Dispatch<React.SetStateAction<File | null>>;
  selectedImageString: string | null | undefined;
  setSelectedImageString: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}

export const ImageInputComponent: React.FC<ImageInputComponentProps> = ({
  selectedImage,
  setSelectedImage,
  selectedImageString,
  setSelectedImageString,
}) => {
  const currentUser = useGetLoggedInUser();
  const deleteProfilePic = useDeleteProfilePicture();

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      const picture: File = event.target.files[0];

      setSelectedImage(picture);

      const pictureUrlString = URL.createObjectURL(picture);

      setSelectedImageString(pictureUrlString);
    }
  };

  const handleImageDelete = (): void => {
    setSelectedImage(null);
    setSelectedImageString("default");
    deleteProfilePic.mutate();
  };

  return (
    <div className={styles.imageInputWrapper}>
      <div className={styles.label}>Profile picture</div>
      <div className={styles.imageSection}>
        <label>
          <input type="file" accept="image/*" onChange={handleImageUpload} />
          <div className={styles.imageInput}>
            <Icon name="ico_pic" width="24" height="24" />
            <div className={styles.imageInputRight}>
              <p>{"Upload..."}</p>
              <Icon name="plus" width="12px" />
            </div>
          </div>
        </label>
        <div className={styles.previewImageWrapper}>
          <div className={styles.previewImage}>
            <>
              {currentUser.data && (
                <ProfilePicture
                  size={54}
                  firstName={currentUser.data.firstName}
                  lastName={currentUser.data.lastName}
                  profilePictureUrl={selectedImageString}
                />
              )}{" "}
            </>
          </div>
          {(currentUser.data?.profilePicture || selectedImage) && (
            <Icon name="x" width="12px" onClick={handleImageDelete} />
          )}
        </div>
      </div>
    </div>
  );
};
