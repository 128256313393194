import React from "react";
import { Cell } from "react-table";
import classNames from "classnames";
import { motion } from "framer-motion";

import { useGetBreakpointProperties } from "../../../hooks/screen/useGetBreakpointProperties";
import { TableBodyRowProps, TableType } from "../../../redux/types/table";
import { TableBodyCell } from "./TableBodyCell";

import styles from "./TableBody.module.scss";

export const TableBodyRow: React.FC<TableBodyRowProps> = (props) => {
  const { rows, row, idx, prepareRow, tableType, getRowClass, rowsById } = props;

  prepareRow(row);

  const { isLargeDesktop } = useGetBreakpointProperties();

  const arrayOfExpanded = rows.filter((row: any) => row.depth === 0);

  const isSticky = row.depth === 0;

  const expandedIndex = arrayOfExpanded.findIndex((expandedRow) => expandedRow.id === row.id);

  const getStickyIndex = () => {
    let index = 0;

    if (expandedIndex >= 0 && row.depth === 0) {
      index = expandedIndex;
      return index;
    }

    return index;
  };

  const somePrecedingExpandedRow = arrayOfExpanded.find((expandedRow, i) => {
    const isPrecedingRow = i < expandedIndex;

    const isRowExpanded = expandedRow.isExpanded && expandedRow.canExpand;

    const isPrecedingRowExpanded = isPrecedingRow && isRowExpanded;

    return isPrecedingRowExpanded;
  });

  const isStickyBottom = isSticky && somePrecedingExpandedRow;

  const offset = getStickyIndex() * 51 + (!isLargeDesktop ? 39 : 37);

  const bottomOffset = (arrayOfExpanded.length - getStickyIndex() - 1) * 51;

  const topPosition = `${expandedIndex >= 0 ? offset : 0}px`;
  const bottomPosition = `${bottomOffset}px`;

  const getAppliedRowClassName = () => {
    let appliedRowClassNameArray = [styles.rowGeneric, styles[row.original.subStatus]];

    if (tableType === TableType.CAMPAIGNS) {
      appliedRowClassNameArray = [styles.campaignRow];
    }

    if (tableType === TableType.CAMPAIGNS && isSticky) {
      appliedRowClassNameArray.push(styles.sticky);
    }
    if (tableType === TableType.CAMPAIGNS && isStickyBottom) {
      appliedRowClassNameArray.push(styles.stickyBottom);
    }

    if (tableType === TableType.CAMPAIGNS && !isSticky && !isStickyBottom) {
      appliedRowClassNameArray.push(styles.notSticky);
    }

    if (tableType === TableType.WORKSPACE_MANAGER) appliedRowClassNameArray.push(styles.workspaceManagerRow);

    if (row.subRows.length > 0) {
      appliedRowClassNameArray.push(styles.pointer);
    }

    if (getRowClass) {
      const customRowClass = getRowClass({
        rowNumber: row.depth,
        expanded: row.isExpanded,
        rowId: row.id,
        rowsById,
      });
      appliedRowClassNameArray.push(customRowClass);
    }

    const appliedRowClasses = classNames(...appliedRowClassNameArray);
    return appliedRowClasses;
  };

  const getAppliedRowStyles = () => {
    let appliedRowStyles = undefined;
    if (tableType === TableType.CAMPAIGNS) {
      appliedRowStyles = {
        top: isSticky ? topPosition : undefined,
        bottom: isStickyBottom ? bottomPosition : undefined,
        zIndex: isSticky ? `${300 + idx}` : undefined,
      };
    }

    return appliedRowStyles;
  };

  const handleRowClick = () => {
    if (row.depth !== 3 && row.canExpand) {
      row.toggleRowExpanded();
    }
  };

  return (
    <motion.tr
      {...row.getRowProps()}
      className={getAppliedRowClassName()}
      style={getAppliedRowStyles()}
      onClick={handleRowClick}>
      {row.cells.map((cell: Cell) => (
        <TableBodyCell key={cell.column.id} cell={cell} {...props} />
      ))}
    </motion.tr>
  );
};
