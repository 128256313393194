import React, { CSSProperties } from "react";

import styles from "./LoadingSpinner.module.scss";

interface LoadingSpinnerProps {
  size?: number;
  color?: string;
  containerWidth?: CSSProperties["width"];
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size, color, containerWidth }) => {
  const appliedContainerStyles = { width: containerWidth };
  const appliedStyles = { width: size, height: size, borderColor: color };

  return (
    <div className={styles.spinner} style={appliedContainerStyles}>
      <div className={styles.innerSpinner} style={appliedStyles} />
    </div>
  );
};
