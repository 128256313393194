import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useChangePassword } from "../../api/user";
import { Button, ButtonType, ButtonVariant } from "../../components/Button/Button";
import { Container } from "../../components/Container/Container";
import { FormDivider } from "../../components/Divider/FormDivider";
import { useGetFormButtonProperties } from "../../hooks/componentSpecific/useGetFormButtonProperties";
import { UpdatePasswordComponent } from "./ChangePasswordInputComponent";

import styles from "./ChangePasswordForm.module.scss";

export interface IUpdatePasswordRequestDTO {
  oldPassword: string;
  newPassword: string;
  newPasswordAgain: string;
}

interface InputObject {
  label: string;
  name: keyof IUpdatePasswordRequestDTO;
}

export const ChangePasswordForm: React.FC = () => {
  const { appliedButtonSize, isFullWidth } = useGetFormButtonProperties();
  const changePassword = useChangePassword();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUpdatePasswordRequestDTO>({
    reValidateMode: "onSubmit",
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordAgain: "",
    },
  });

  const onSubmit: SubmitHandler<IUpdatePasswordRequestDTO> = async ({ oldPassword, newPassword }) => {
    const relevantData = { oldPassword, newPassword };

    changePassword.mutate(relevantData, {
      onSuccess: () => {
        reset();
      },
    });
  };

  const allInputsArray: InputObject[] = [
    {
      label: "Current password*",
      name: "oldPassword",
    },
    {
      label: "New password*",
      name: "newPassword",
    },
    {
      label: "New password again*",
      name: "newPasswordAgain",
    },
  ];

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Password</h3>
      </div>
      <form id="savePassword" onSubmit={handleSubmit(onSubmit)} noValidate className={styles.form}>
        <div className={styles.wrapper}>
          {allInputsArray.map(({ name, label }) => (
            <UpdatePasswordComponent key={name} label={label} name={name} register={register} errors={errors} />
          ))}
        </div>
        <FormDivider />
        <div className={styles.btnContainer}>
          <Button
            type={ButtonType.SUBMIT}
            variant={ButtonVariant.PRIMARY}
            size={appliedButtonSize}
            loading={changePassword.isLoading}
            fullWidth={isFullWidth}
            disabled={false}>
            Save password
          </Button>
        </div>
      </form>
    </Container>
  );
};
