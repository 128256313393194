import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { PrivateRoute } from "../../PrivateRoute/PrivateRoute";
import { AdProviders } from "./AdProviders";
import { EcommercePlatforms } from "./EcommercePlatforms";

export const IntegrationContent: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/integration/ecommerce-provider" component={EcommercePlatforms} title="Integration" />
      <PrivateRoute exact path="/integration/ad-providers*" component={AdProviders} title="Integration" />
      <Route>
        <Redirect to="/integration/ecommerce-provider" />
      </Route>
    </Switch>
  );
};
