import React from "react";

import { useGetIntegrationDetails } from "../../../../api/workspace";
import { CardWithTitle } from "../../../../components/CardWithTitle/CardWithTitle";
import { CodeBox } from "../../../../components/CodeBox/CodeBox";
import { StatusStripe } from "../../../../components/StatusStripe/StatusStripe";
import { useGetEcommerceStatusStripeProperties } from "../../../../hooks/integration/useGetEcommerceStatusStripeProperties";
import { useGetIntegrationGlobalSiteTag } from "../../../../hooks/integration/useGetIntegrationGlobalSiteTag";
import { EcommercePlatformEnum } from "../../../../redux/types";

export const MyCashflowPlatform: React.FC = () => {
  const { isFetching } = useGetIntegrationDetails();
  const { combinedParametersProps } = useGetEcommerceStatusStripeProperties();
  const globalSiteTag = useGetIntegrationGlobalSiteTag(EcommercePlatformEnum.myCashFlow);

  return (
    <CardWithTitle
      iconName="ico-mycashflow-big"
      title="Global Tracking Code"
      subtitle="MyCashFlow"
      videoControl={false}>
      <p>You need to install the global site tag in your MyCashflow site.</p>
      <ol>
        <li>
          <p>Open your MyCashflow admin and click User Interface {">"} Themes.</p>
        </li>
        <li>
          <p>
            Click on `Filebrowser` and select your current Theme. If you do not see your theme html files there, you
            might need to set up the environment yourself first. Follow the official MyCashflow documentation,
            particularly steps outlined {` `}
            <a
              href="https://support.mycashflow.com/en/user-guide/customizing-ready-to-use-themes"
              target="_blank"
              rel="noreferrer">
              here
            </a>{" "}
            and{" "}
            <a href="https://support.mycashflow.com/en/user-guide/enabling-themes" target="_blank" rel="noreferrer">
              here
            </a>
            .
          </p>
        </li>
        <li>
          <p>Go to the `helpers` folder and open the `header.html` file.</p>
        </li>
        <li>
          <p>Copy the tag below and paste it just above the {`</head>`} tag.</p>
        </li>
        <li>
          <p>Click Save.</p>
        </li>
      </ol>
      <CodeBox src={globalSiteTag} />
      <StatusStripe isLoading={isFetching} {...combinedParametersProps} />
    </CardWithTitle>
  );
};
