import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { GetLoggedInUserResponseDTO, NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn, queryClient } from "../../api";
import { getCurrentUserUrl } from "../../user";
import { UpdateWorkspaceRequestDTO } from "./request";

export const useUpdateWorkspaceMutation = () => {
  return useMutation(
    (data: UpdateWorkspaceRequestDTO) => {
      return defaultMutationFn(`workspace/${data.workspaceId}/details`, {
        method: "PUT",
        data: {
          ...data,
          workspaceId: undefined,
        },
      });
    },
    {
      onMutate: async (newData) => {
        //Return previousData so they can be used onError as `context`
        const previousData = queryClient.getQueryData([`workspace/${newData.workspaceId}`]);

        return previousData;
      },
      onSuccess: async (data, vars) => {
        // update the workspsace even in user's workspace list
        queryClient.setQueryData<GetLoggedInUserResponseDTO | undefined>([getCurrentUserUrl], (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              workspaces: [...oldData.roles.filter(({ workspace }) => workspace.id !== vars.workspaceId), data],
            };
          }
        });

        const endpoint = [`workspace/${vars.workspaceId}`];
        await queryClient.invalidateQueries(endpoint);

        store.dispatch(
          showNotification({
            message: "Workspace successfully updated",
            severity: NotificationSeverity.SUCCESS,
          }),
        );
      },
      onError: (_, vars, context: any) => {
        queryClient.setQueryData([`workspace/${vars.workspaceId}`], context);
      },
    },
  );
};
