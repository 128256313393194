import React from "react";
import classNames from "classnames";

import { useGetCurrentWorkspaceDetail } from "../../../api/workspace";
import { useGetBreakpointProperties } from "../../../hooks/screen/useGetBreakpointProperties";
import { IModal } from "../../../redux/types";
import { Button, ButtonVariant } from "../../Button/Button";
import { ChevronIcon } from "../../Icon/ChevronIcon/ChevronIcon";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";

import styles from "./HeaderWorkspaceSwitchButton.module.scss";

export const BigWorkspaceSwitchButton: React.FC<IModal> = ({ visible, toggle }) => {
  const currentWorkspaceDetail = useGetCurrentWorkspaceDetail();
  const { isMobile } = useGetBreakpointProperties();

  const getAppliedWorkspaceSwitchClasses = () => {
    const appliedWorkspaceSwitchClassesArray = [styles.workspaceSwitch];
    if (visible) {
      appliedWorkspaceSwitchClassesArray.push(styles.whiteBg);
    }
    const appliedWorkspaceSwitchClasses = classNames(...appliedWorkspaceSwitchClassesArray);

    return appliedWorkspaceSwitchClasses;
  };

  const getAppliedWorkspaceName = () => {
    let appliedWorkspaceName = currentWorkspaceDetail.data?.name ?? "";

    if (isMobile) {
      appliedWorkspaceName = `${appliedWorkspaceName.slice(0, 11)}...`;
    }

    return appliedWorkspaceName;
  };

  return (
    <Button
      className={getAppliedWorkspaceSwitchClasses()}
      variant={ButtonVariant.TEXT}
      iconName="bag"
      onClick={() => toggle()}>
      <div>
        {currentWorkspaceDetail.isLoading ? (
          <LoadingSpinner size={16} />
        ) : (
          <p className={styles.workspaceName}>{getAppliedWorkspaceName()}</p>
        )}
      </div>
      <ChevronIcon isActive={visible} />
    </Button>
  );
};
