import { configureStore } from "@reduxjs/toolkit";
import { Store } from "redux";
import thunk from "redux-thunk";

import { rootReducer } from "../reducers";

// https://github.com/reduxjs/redux/blob/master/examples/real-world/src/store/configureStore.dev.js
export const createStore = (): Store => {
  return configureStore({
    reducer: { ...rootReducer },
    middleware: [thunk],
  });
};

export const store = createStore();
