import React from "react";

import { Button, ButtonType, ButtonVariant } from "../../components/Button/Button";
import { useGetFormButtonProperties } from "../../hooks/componentSpecific/useGetFormButtonProperties";

import styles from "./WorkspaceForm.module.scss";

interface ConfirmButtonComponentProps {
  isCreateForm: boolean;
  isMutationLoading: boolean;
  isSideBySideAlign: boolean;
}

export const ConfirmButtonComponent: React.FC<ConfirmButtonComponentProps> = ({
  isCreateForm,
  isMutationLoading,
  isSideBySideAlign,
}) => {
  const buttonText = isCreateForm ? "Continue" : "Save changes";
  const { appliedButtonSize, isFullWidth } = useGetFormButtonProperties(!isSideBySideAlign);

  return (
    <div className={styles.btnContainer}>
      <Button
        type={ButtonType.SUBMIT}
        variant={ButtonVariant.PRIMARY}
        size={appliedButtonSize}
        fullWidth={isFullWidth}
        loading={isMutationLoading}>
        {buttonText}
      </Button>
    </div>
  );
};
