import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { useAddWorkspaceUserMutation, useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { Button, ButtonType, ButtonVariant } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { Modal } from "../../components/Modal/Modal";
import { RadioButton } from "../../components/RadioButton/RadioButton";
import { IModal, WorkspaceRoleType } from "../../redux/types";

import styles from "./AddUserModal.module.scss";

export interface AddWorkspaceUserInput {
  email: string;
  role: WorkspaceRoleType;
}

type ChangePasswordModalProps = IModal;

export const AddUserModal: React.FC<ChangePasswordModalProps> = ({ visible, toggle }) => {
  const workspace = useGetCurrentWorkspaceDetail();
  const addWorkspaceUserMutation = useAddWorkspaceUserMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddWorkspaceUserInput>({ reValidateMode: "onSubmit", defaultValues: { role: WorkspaceRoleType.STAFF } });

  const onSubmit: SubmitHandler<AddWorkspaceUserInput> = async (data) => {
    if (workspace.data) {
      addWorkspaceUserMutation.mutate(
        {
          email: data.email,
          role: data.role,
          workspaceId: workspace.data.id,
        },
        {
          onSettled: () => {
            toggle();
            reset({ email: "", role: WorkspaceRoleType.STAFF });
          },
        },
      );
    }
  };

  return (
    <Modal animation="fadeIn" className={styles.modal} visible={visible} toggle={toggle}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>New user</div>
          <div className={styles.subtitle}>
            Add member to this workspace. Just type his/her email below and select a role.
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <Input
            icon="email"
            label="New user's email"
            placeholder="email"
            type="email"
            {...register("email", { required: "This field is required" })}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <span role="alert" className="errorMessage">
                {message}
              </span>
            )}
          />
          <label>New users&apos;s role</label>
          <div className={styles.roles}>
            <RadioButton value={WorkspaceRoleType.STAFF} {...register("role")} text="Team member" checked />
            <RadioButton value={WorkspaceRoleType.CLIENT} {...register("role")} text="Client" />
            <RadioButton value={WorkspaceRoleType.ADMIN} {...register("role")} text="Admin" />
          </div>
          <Button
            type={ButtonType.SUBMIT}
            variant={ButtonVariant.PRIMARY}
            fullWidth
            loading={addWorkspaceUserMutation.isLoading}>
            Send invitation
          </Button>
        </form>
      </div>
    </Modal>
  );
};
