import { SubscriptionDetailInfo } from "../../utils/getSubscriptionDetailInfo";
import { CurrencyType } from "./app";

export interface WorkspaceState {
  integrationDetails: IntegrationDetails;
  currentId?: number;
  subscriptionInfo: SubscriptionDetailInfo;
}

export interface Workspace {
  id: number;
  name: string;
  identifier: string;
  domain: string;
  timeZone: string;
  currency: CurrencyType;
  subscriptionId: string;
  subscriptionStatus: SubscriptionStatus;
  currentPlan: number;
  currentRevenue: number;
  toBeDeletedAt: Date | null;
  eCommercePlatform: string;
  googleAdId: string | null;
  adProviders: AdProviderEnum[];
}

export enum WorkspaceRoleType {
  ADMIN = "admin",
  STAFF = "staff",
  CLIENT = "client",
}

export enum IntegrationStatus {
  error = "error",
  warning = "warning",
  success = "success",
}

export interface WorkspaceRole {
  role: WorkspaceRoleType;
  workspace: Workspace;
}

interface IntegrationAdProviders {
  facebook: IntegrationAdBlock;
  google: IntegrationAdBlockGoogle;
  tiktok: IntegrationAdBlock;
}

export interface IntegrationAdBlockGoogle {
  urlParameters: IntegrationEventBlock;
  authentication: IntegrationEventBlock;
  accountSelection: IntegrationEventBlock;
  adAccountId?: string | null;
}

export interface MultipleIntegrationDetails {
  [key: string]: IntegrationDetails;
}

export interface IntegrationDetails {
  isLearning: boolean;
  ecommerceProvidersData: IntegrationBlock;
  adProvidersData: IntegrationAdProviders;
}

interface IntegrationBlock {
  pageView: IntegrationEventBlock;
  conversion: IntegrationEventBlock;
}

interface IntegrationAdBlock {
  urlParameters: IntegrationEventBlock;
  state?: IntegrationEventBlock;
  adAccountId?: string;
}

export interface IntegrationEventBlock {
  message: string;
  status: IntegrationStatus;
}

export enum SubscriptionStatus {
  Empty = "empty",
  Pending = "pending",
  Error = "error",
  Unapproved = "unapproved",
  Active = "active",
  Ignored = "ignored",
}

export interface EcommercePlatformObject {
  platformName: string;
  platformComponent?: JSX.Element;
  iconName?: string;
}

export enum EcommercePlatformEnum {
  shopify = 1,
  myCashFlow,
  wooCommerce,
  shoptet,
  custom,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EcommercePlatformEnum {
  export const toString = (platform: EcommercePlatformEnum): string => EcommercePlatformEnum[platform];
}

export enum AdProviderEnum {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  TIKTOK = "tiktok",
  ORGANIC = "organic",
}

export interface GoogleAccessibleCustomer {
  id: string;
  name: string;
}

export interface GoogleSignInRedirect {
  redirectUrl: string;
}
