import { useState } from "react";

import { IModal } from "../../redux/types";

export const useModal = (): IModal => {
  const [visible, setVisible] = useState(false);

  const toggle = (bool?: boolean) => {
    setVisible((prev) => bool ?? !prev);
  };

  return {
    visible,
    toggle,
  };
};
