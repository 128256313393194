import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { useGetLoggedInUser } from "../../api/user";
import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { Header } from "../../components/Header/Header";
import { FooterNavbar } from "../../components/MainMenu/FooterNavbar/FooterNavbar";
import { MainMenu } from "../../components/MainMenu/MainMenu";
import { useLoadMainPage } from "../../hooks/componentSpecific/useLoadMainPage";
import { useGetDisplayedComponentsProperties } from "../../hooks/screen/useGetDisplayedComponentsProperties";
import { WorkspaceOverLimitModal } from "../../modals/WorkspaceOverLimitModal/WorkspaceOverLimitModal";
import { WorkspaceRoleType } from "../../redux/types";
import { isDangerSubscriptionStatus } from "../../utils/isDangerSubscriptionStatus";
import { AddWorkspacePage } from "../AddWorkspacePage/AddWorkspacePage";
import { CampaignsPage } from "../CampaignsPage/CampaignsPage";
import { DashboardPage } from "../DashboardPage/DashboardPage";
import { IntegrationPage } from "../IntegrationPage/IntegrationPage";
import { Layout } from "../Layout/Layout";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { UserSettingsPage } from "../UserSettingsPage/UserSettingsPage";
import { WorkspaceSetupPage } from "../WorkspaceSetupPage/WorkspaceSetupPage";

import styles from "./MainPage.module.scss";

export const MainPage: React.FC = () => {
  const userQuery = useGetLoggedInUser();
  const currentWorkspace = useGetCurrentWorkspaceDetail();
  const { isMainMenuShown, isHeaderShown, isFooterShown } = useGetDisplayedComponentsProperties();
  const shouldLimitAccess = isDangerSubscriptionStatus(currentWorkspace?.data?.subscriptionStatus);

  const overLimitModal = useLoadMainPage();

  if (currentWorkspace.isLoading) {
    return null;
  }

  return (
    <>
      {isMainMenuShown && <MainMenu />}
      <main className={styles.main}>
        {isHeaderShown && <Header />}
        <Layout>
          <Switch>
            <PrivateRoute
              exact
              path="/dashboard"
              component={DashboardPage}
              title={`Hello, ${userQuery?.data?.firstName} ✋`}
            />
            <PrivateRoute
              exact
              path="/workspace-setup*"
              component={WorkspaceSetupPage}
              title="Workspace Setup"
              requiredRoles={[WorkspaceRoleType.ADMIN]}
            />
            <PrivateRoute exact path="/user/settings*" component={UserSettingsPage} title="User Settings" />
            <PrivateRoute exact path="/add-workspace" component={AddWorkspacePage} title="Add workspace" />
            <PrivateRoute
              exact
              path="/campaigns"
              component={CampaignsPage}
              title="Campaigns"
              isHidden={shouldLimitAccess}
            />
            <PrivateRoute
              exact
              path="/integration*"
              component={IntegrationPage}
              title="Integration"
              requiredRoles={[WorkspaceRoleType.ADMIN, WorkspaceRoleType.CLIENT]}
              isHidden={shouldLimitAccess}
            />
            <Route>
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </Layout>

        <WorkspaceOverLimitModal toggle={overLimitModal.toggle} visible={overLimitModal.visible} />

        {isFooterShown && <FooterNavbar />}
      </main>
    </>
  );
};
