import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { getAttribution } from "../../../redux/selectors/app";
import { getUrlDateRange } from "../../../utils/formatters/dateFormatters";
import { defaultMutationFn } from "../../api";
import { GenericViewsProps } from "../types";

const useGetPdf = ({ from, to, attributionType, workspaceId }: GenericViewsProps) => {
  return useMutation(
    () =>
      defaultMutationFn(`views/reporting/${workspaceId}?from=${from}&to=${to}&attributionType=${attributionType}`, {
        method: "GET",
        data: undefined,
        responseType: "arraybuffer",
      }),
    {
      onSuccess: (data) => {
        const blob = new Blob([data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `export.pdf`);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      },
    },
  );
};

export const useGetPdfFromCurrentDetails = () => {
  const { from, to } = getUrlDateRange();
  const currentId = useGetCurrentWorkspaceId();
  const attribution = useSelector(getAttribution);
  return useGetPdf({
    from,
    to,
    attributionType: attribution,
    workspaceId: currentId,
  });
};

// onSuccess: (data) => {
//           const blob = new Blob([data], { type: "application/pdf" });
//           const url = window.URL.createObjectURL(new Blob([blob]));
//           const link = document.createElement("a");
//           link.href = url;
//           link.setAttribute("download", `export.pdf`);
//           document.body.appendChild(link);
//           link.click();
//           if (link.parentNode) {
//             link.parentNode.removeChild(link);
//           }
//         },
