import { useEffect } from "react";

import { NotificationSeverity } from "../../redux/types";
import { useUrlQuery } from "../url/useUrlQuery";

interface PopupWindowDataObject {
  isGoogleAuthMessage: boolean;
  message: string;
  severity: NotificationSeverity;
}

export const useSendPopupWindowMessage = (): void => {
  const query = useUrlQuery();

  const getPopupWindowMessageType = (): NotificationSeverity | null => {
    let popupWindowMessageSeverity: NotificationSeverity | null = null;
    const successQuery = query.get("success");
    if (successQuery === "true") {
      popupWindowMessageSeverity = NotificationSeverity.SUCCESS;
    }
    if (successQuery === "false") {
      popupWindowMessageSeverity = NotificationSeverity.ERROR;
    }

    return popupWindowMessageSeverity;
  };

  useEffect(() => {
    const sendMessage = () => {
      const popupWindowMessageSeverity = getPopupWindowMessageType();
      const dataObject = popupWindowDataObjects.find(({ severity }) => severity === popupWindowMessageSeverity);

      if (!dataObject) return;

      window?.opener?.postMessage(dataObject, window.opener.location.href);
      window.close();
    };

    const timeout = setTimeout(() => {
      sendMessage();
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
};

const popupWindowDataObjects: PopupWindowDataObject[] = [
  {
    isGoogleAuthMessage: true,
    message: "Succesfully authenticated.",
    severity: NotificationSeverity.SUCCESS,
  },
  {
    isGoogleAuthMessage: true,
    message: "Failed to authenticate. Please make sure you are not using this Google account in another workspace.",
    severity: NotificationSeverity.ERROR,
  },
];
