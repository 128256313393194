import React, { Dispatch, SetStateAction } from "react";
import { DateRangePicker, OnDateRangeChangeProps } from "react-date-range";

import { useGetDatePickerStaticRanges } from "../../hooks/componentSpecific/useGetDatePickerStaticRanges";
import { useHandleClickOutside } from "../../hooks/screen/useHandleClickOutside";
import { DateRange } from "../../redux/types/app";
import { Button, ButtonSize, ButtonVariant } from "../Button/Button";

import styles from "./DatePicker.module.scss";

interface DatePickerCalendarProps {
  datePickerState: DateRange;
  setDatePickerState: Dispatch<SetStateAction<DateRange>>;
  handleOpen: () => void;
}

export const DatePickerCalendar: React.FC<DatePickerCalendarProps> = ({
  datePickerState,
  setDatePickerState,
  handleOpen,
}) => {
  const handleOpenWithoutPropagation = (event: MouseEvent) => {
    event.stopPropagation();
    handleOpen();
  };

  const ref = useHandleClickOutside(handleOpenWithoutPropagation);
  const datePickerStaticRanges = useGetDatePickerStaticRanges();

  const handleDateChange = (item: OnDateRangeChangeProps) => {
    const endDate = item.range1.endDate;
    const endDateInMs = endDate?.getTime();
    const nowInMs = Date.now();
    const appliedEndDate = endDateInMs && endDateInMs > nowInMs ? new Date(nowInMs) : endDate;
    const dateRange: DateRange = {
      startDate: item.range1.startDate,
      endDate: appliedEndDate,
    };

    setDatePickerState(dateRange);
  };

  return (
    <>
      <div className={styles.calendarContainer} ref={ref}>
        <DateRangePicker
          className={styles.picker}
          onChange={handleDateChange}
          rangeColors={["#142bb2"]}
          ranges={[datePickerState]}
          maxDate={new Date(Date.now())}
          staticRanges={datePickerStaticRanges}
        />
        <div className={styles.btnContainer}>
          <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.SMALL} className={styles.btn} onClick={handleOpen}>
            Save selection
          </Button>
        </div>
      </div>
    </>
  );
};
