import React from "react";

import { useGetBreakpointProperties } from "../../hooks/screen/useGetBreakpointProperties";
import { CampaignsMobileList } from "../../tables/CampaignsTable/CampaignsMobileList/CampaignsMobileList";
import { CampaignTable } from "../../tables/CampaignsTable/CampaignsTable/CampaignsTable";

import styles from "./CampaignsPage.module.scss";

export const DataSegment: React.FC = () => {
  const { isMobile } = useGetBreakpointProperties();
  return (
    <div className={styles.dataSegment}>
      <div className={styles.dataContainer}>{isMobile ? <CampaignsMobileList /> : <CampaignTable />}</div>
    </div>
  );
};
