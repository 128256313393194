import { useMutation } from "react-query";

import { showNotification } from "../../../redux/reducers/notificationReducer";
import { store } from "../../../redux/store";
import { NotificationSeverity } from "../../../redux/types";
import { defaultMutationFn } from "../../api";
import { UpdatePasswordRequestDTO } from "./request";

export const useChangePassword = () => {
  return useMutation(
    (data: UpdatePasswordRequestDTO) => defaultMutationFn("user/password", { data, method: "PATCH" }),
    {
      onSuccess: () => {
        store.dispatch(
          showNotification({
            message: "Password changed",
            severity: NotificationSeverity.SUCCESS,
          }),
        );
      },
    },
  );
};
