import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { useGetLoggedInUser } from "../../api/user";
import { Notification } from "../../components/Notification/Notification";
import { useResize } from "../../hooks/screen/useResize";
import { getJwt } from "../../redux/selectors/app";
import { AddWorkspacePage } from "../AddWorkspacePage/AddWorkspacePage";
import { LoginPage } from "../LoginPage/LoginPage";
import { RecoverPasswordPage } from "../RecoverPasswordPage/RecoverPasswordPage";
import { RegisterPage } from "../RegisterPage/RegisterPage";
import { ResetPasswordPage } from "../ResetPasswordPage/ResetPasswordPage";
import { AuthenticatedApp } from "./AuthenticatedApp";

import styles from "./App.module.scss";

const App: React.FC = () => {
  const location = useLocation();
  const jwtFromStore = useSelector(getJwt);
  const getUserQuery = useGetLoggedInUser();
  useResize();

  return (
    <div className={styles.App}>
      <Notification />
      {!jwtFromStore ? (
        <>
          <Switch>
            <Route path="/login" component={LoginPage} location={location} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/recover-password" component={RecoverPasswordPage} />
            <Route exact path="/reset-password" component={ResetPasswordPage} />
            <Route>
              <Redirect to="/login" />
            </Route>
          </Switch>
        </>
      ) : (
        // AuthenticatedApp is displayed only if the user is logged in and has created a workspace
        // This is to prevent errors with calling currentWorkspace when there is no workspace
        <>
          {getUserQuery.data?.roles && getUserQuery.data.roles.length !== 0 ? (
            <AuthenticatedApp />
          ) : (
            <Switch>
              <Route exact path="/add-first-workspace" component={AddWorkspacePage} />

              {getUserQuery.isFetchedAfterMount && (
                <Route>
                  <Redirect to="/add-first-workspace" />
                </Route>
              )}
            </Switch>
          )}
        </>
      )}
    </div>
  );
};

export default App;
