import { useGetBreakpointProperties } from "../screen/useGetBreakpointProperties";

interface ModalGridStyles {
  gridTemplateRows: string;
  gridTemplateColumns: string;
}

export const useGetModalGridStyles = (platformObjects: any[]): ModalGridStyles => {
  const { isMobile } = useGetBreakpointProperties();
  const getGridColumnsNumber = (): number => {
    let gridColumnsNumber = 3;
    if (platformObjects.length <= 2) {
      gridColumnsNumber = platformObjects.length;
      return gridColumnsNumber;
    }

    if (isMobile) {
      gridColumnsNumber = 2;
    }

    return gridColumnsNumber;
  };

  return {
    gridTemplateRows: `repeat(${Math.ceil(platformObjects.length / (isMobile ? 2 : 3))},1fr)`,
    gridTemplateColumns: `repeat(${getGridColumnsNumber()},1fr)`,
  };
};
