import { AFFILIATES_APP_REGISTER_URL } from "../../../utils/constants/urlStrings";
import { BannerCardProps } from "./BannerCard";

export const bannerCardsArray: BannerCardProps[] = [
  {
    title: "Refer and earn",
    text: "Join ecomtrack Affiliate Club and receive 15% recurring commission!",
    linkText: "Explore possibilities",
    onLinkClick: () => {
      window.open(AFFILIATES_APP_REGISTER_URL, "_blank");
    },
    imageName: "refer.svg",
  },
  {
    title: "New platforms available",
    text: "We are happy to announce that from now on, you can also track your TikTok ads with ecomtrack! The Shoptet integration is now available as well.",
    imageName: "new-platforms.svg",
  },
];
