import React, { useCallback } from "react";
import { Cell, Row } from "react-table";

import { useGetLoggedInUser } from "../../api/user";
import { useGetCurrentWorkspaceDetail } from "../../api/workspace";
import { WorkspaceRoleDTO } from "../../api/workspace/get-one/dto";
import { Icon } from "../../components/Icon/Icon";
import { ProfilePicture } from "../../components/ProfilePicture/ProfilePicture";
import { Table } from "../../components/Table/Table";
import { TableCellAlignment, TableColumn, TableType } from "../../redux/types/table";
import { getPresentableUsers } from "./helpers";
import { WorkspaceUsersTableProps } from "./WorkspaceUsersTable";

import styles from "./WorkspaceUsersTable.module.scss";

type BiggerScreenViewProps = WorkspaceUsersTableProps;

export const BiggerScreenView: React.FC<BiggerScreenViewProps> = ({ setSelectedUser, deleteUserModalToggle }) => {
  const workspace = useGetCurrentWorkspaceDetail();
  const currentUser = useGetLoggedInUser();
  const users = workspace.data ? getPresentableUsers(workspace.data) : [];
  const roleNamesMap = {
    admin: "Admin",
    staff: "Team member",
    client: "Client",
  };

  const handleRowClick = (row: Row, cell: Cell) => {
    if (cell.column.id !== "delete") {
      return;
    }
    if (workspace.data) {
      setSelectedUser(users.find((user) => user.email === (row.original as any).email));
      deleteUserModalToggle();
    }
  };
  const handleClick = useCallback((row: Row, cell: Cell) => handleRowClick(row, cell), [users]);

  const makeTableData = (user: WorkspaceRoleDTO) => {
    return {
      name: <UserName user={user} />,
      email: user.email,
      role: roleNamesMap[user.role],
      delete: user.email !== currentUser.data?.email ? <Icon name="trash" height="14px" /> : "",
    };
  };

  return (
    <Table
      cols={userTableCols}
      data={users.map(makeTableData)}
      rowClick={handleClick}
      tableType={TableType.WORKSPACE_USERS}
    />
  );
};

const userTableCols: TableColumn[] = [
  {
    name: "Name",
    accessor: "name",
    alignment: TableCellAlignment.LEFT,
    minWidth: 40,
    skeleton: { round: false, width: 160, height: 36 },
    isHidden: true,
  },
  {
    name: "Email",
    accessor: "email",
    alignment: TableCellAlignment.CENTER,
    skeleton: { round: false, width: 160, height: 36 },
    minWidth: 80,
    isHidden: true,
  },
  {
    name: "Role",
    accessor: "role",
    alignment: TableCellAlignment.CENTER,
    skeleton: { round: false, width: 80, height: 36 },
    isHidden: true,
  },
  {
    name: "",
    accessor: "delete",
    alignment: TableCellAlignment.CENTER,
    skeleton: { round: false, width: 20, height: 20 },
    maxWidth: 10,
    pointer: true,
    isHidden: true,
  },
];

const UserName: React.FC<{ user: WorkspaceRoleDTO }> = ({ user }) => {
  return (
    <div className={styles.user}>
      <ProfilePicture
        firstName={user.firstName}
        lastName={user.lastName}
        profilePictureUrl={user.profilePictureUrl}
        size={36}
      />
      {user.firstName} {user.lastName}
    </div>
  );
};
