import { IAdsMenuItem } from "../containers/IntegrationPage/IntegrationMenu/IntegrationMenu";
import { AdProviderEnum, IntegrationDetails, IntegrationStatus } from "../redux/types";
import { getHasArrayErrorStatus } from "./getHasArrayErrorStatus";

export interface AdProvidersIntegrationStates {
  isFbStatusError: boolean;
  isGoogleStatusError: boolean;
  isTikTokStatusError: boolean;
  isGlobalAdProvidersError?: boolean;
}

export const getAdProvidersIntegrationStates = (
  integrationDetails?: IntegrationDetails,
  selectedAdProviders?: IAdsMenuItem[],
) => {
  const fbUrlParametersState = integrationDetails?.adProvidersData.facebook.urlParameters?.status as IntegrationStatus;
  const fbTrackingStatus = integrationDetails?.adProvidersData.facebook.state?.status as IntegrationStatus;
  const googleAdsUrlParametersState = integrationDetails?.adProvidersData.google.urlParameters
    ?.status as IntegrationStatus;
  const googleAdsAuthenticationState = integrationDetails?.adProvidersData.google.authentication
    ?.status as IntegrationStatus;
  const googleAdsAccountSelectionState = integrationDetails?.adProvidersData.google.accountSelection
    ?.status as IntegrationStatus;
  const tikTokAdsUrlParametersState = integrationDetails?.adProvidersData.tiktok.urlParameters
    ?.status as IntegrationStatus;

  const isProviderSelected = (AdProviderEnum: AdProviderEnum): boolean => {
    const selectedAdProvider = selectedAdProviders?.find((selected) => selected.name === AdProviderEnum);

    return Boolean(selectedAdProvider);
  };

  const isGlobalAdProvidersError = (): boolean => {
    const statusArray: IntegrationStatus[] = [];
    const isFbSelected = isProviderSelected(AdProviderEnum.FACEBOOK);
    const isGoogleSelected = isProviderSelected(AdProviderEnum.GOOGLE);
    const isTikTokSelected = isProviderSelected(AdProviderEnum.TIKTOK);

    if (isFbSelected) {
      statusArray.push(fbTrackingStatus);
      statusArray.push(fbUrlParametersState);
    }
    if (isGoogleSelected) {
      statusArray.push(googleAdsUrlParametersState);
      statusArray.push(googleAdsAuthenticationState);
      statusArray.push(googleAdsAccountSelectionState);
    }
    if (isTikTokSelected) {
      statusArray.push(tikTokAdsUrlParametersState);
    }
    return getHasArrayErrorStatus(statusArray);
  };

  const fbStatusArray = [fbUrlParametersState, fbTrackingStatus];
  const googleStatusArray = [googleAdsUrlParametersState, googleAdsAuthenticationState, googleAdsAccountSelectionState];
  const tikTokStatusArray = [tikTokAdsUrlParametersState];

  const isFbStatusError = getHasArrayErrorStatus(fbStatusArray);
  const isGoogleStatusError = getHasArrayErrorStatus(googleStatusArray);
  const isTikTokStatusError = getHasArrayErrorStatus(tikTokStatusArray);

  const adProvidersIntegrationStates: AdProvidersIntegrationStates = {
    isFbStatusError,
    isGoogleStatusError,
    isTikTokStatusError,
    isGlobalAdProvidersError: isGlobalAdProvidersError(),
  };

  return adProvidersIntegrationStates;
};
