import React, { useState } from "react";
import Recaptcha from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import { UseMutationResult } from "react-query/types/react/types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ErrorMessage } from "@hookform/error-message";

import { RequestPasswordResetTokenRequestDTO } from "../../api/auth";
import { Button, ButtonType, ButtonVariant } from "../../components/Button/Button";
import { Image } from "../../components/Image/Image";
import { Input } from "../../components/Input/Input";
import { showNotification } from "../../redux/reducers/notificationReducer";
import { NotificationSeverity } from "../../redux/types/notification";

import styles from "./RecoveryLinkForm.module.scss";

interface RecoveryLinkFormProps {
  recoverPasswordMutation: UseMutationResult<any, unknown, RequestPasswordResetTokenRequestDTO, unknown>;
}

export const RecoveryLinkForm: React.FC<RecoveryLinkFormProps> = ({ recoverPasswordMutation }) => {
  const history = useHistory();
  const [isVerified, setVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<RequestPasswordResetTokenRequestDTO> = async ({ email }) => {
    if (!isVerified) {
      return dispatch(
        showNotification({ message: "Please verify that you are a human.", severity: NotificationSeverity.ERROR }),
      );
    }
    recoverPasswordMutation.mutate({ email, captchaToken });
  };
  const handleBack = () => {
    history.goBack();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestPasswordResetTokenRequestDTO>();

  const onRecaptchaVerify = (response: string | null) => {
    setCaptchaToken(response);
    if (response) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  };
  return (
    <>
      <div className={styles.column}>
        <h1>Reset password</h1>
        <p className={styles.subtitle}>Write us your email and we will send you an activation link in a moment.</p>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          icon="email"
          label="Email address*"
          placeholder="email"
          type="email"
          {...register("email", {
            required: "This field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          })}
          required
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <span role="alert" className="errorMessage" style={{ marginBottom: "0px", marginTop: "0px" }}>
              {message}
            </span>
          )}
        />
        <Recaptcha sitekey="6LehGt4fAAAAAKkIHu0RcF4YScFigj2lnLfZpYlU" onChange={onRecaptchaVerify} />
        <div className={styles.buttons}>
          <Button variant={ButtonVariant.PRIMARY} type={ButtonType.SUBMIT} loading={recoverPasswordMutation.isLoading}>
            Send
          </Button>
          <Button variant={ButtonVariant.SECONDARY} type={ButtonType.BUTTON} onClick={handleBack}>
            Back
          </Button>
        </div>
      </form>
      <div className={styles.logo}>
        <Image src="logo.svg" alt="ecomtrack logo" />
      </div>
    </>
  );
};
