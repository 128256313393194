import React from "react";

import { useRequestPasswordReset } from "../../api/auth";
import { SlideShow } from "../../components/SlideShow/SlideShow";
import { RecoveryLinkForm } from "../../forms/RecoveryLinkForm/RecoveryLinkForm";
import { useMatchScreen } from "../../hooks/screen/useMatchScreen";
import { RecoveryLinkSentComponent } from "./RecoveryLinkSentComponent";

import styles from "./RecoverPasswordPage.module.scss";

export const RecoverPasswordPage: React.FC = () => {
  const recoverPassword = useRequestPasswordReset();
  const isScreenMatch = useMatchScreen(950);

  return (
    <div className={styles.ResetPasswordPage}>
      <div className={styles.firstHalf}>
        <div className={styles.firstHalfContent}>
          {!recoverPassword.isSuccess ? (
            <RecoveryLinkForm recoverPasswordMutation={recoverPassword} />
          ) : (
            <RecoveryLinkSentComponent />
          )}
        </div>
      </div>
      {!isScreenMatch && (
        <div className={styles.secondHalf}>
          <SlideShow />
        </div>
      )}
    </div>
  );
};
