import { useQuery } from "react-query";

import { useGetCurrentWorkspaceId } from "../../../hooks/workspace/useGetCurrentWorkspaceId";
import { GetPricingPlanInfoResponseDTO } from "./dto";

export const createGetPricingPlanInfoUrl = (workspaceId: number) => {
  return `billing/subscription/info/${workspaceId}`;
};

const useGetPricingPlanInfo = (workspaceId: number) => {
  return useQuery<GetPricingPlanInfoResponseDTO>([createGetPricingPlanInfoUrl(workspaceId)]);
};

export const useGetPricingPlanInfoForCurrent = () => {
  const workspaceId = useGetCurrentWorkspaceId();
  return useGetPricingPlanInfo(workspaceId);
};
