import React from "react";
import { Route, Switch, useHistory } from "react-router";
import { AnimatePresence, motion, Variants } from "framer-motion";

import { Icon } from "../../components/Icon/Icon";
import { useGetDisplayedComponentsProperties } from "../../hooks/screen/useGetDisplayedComponentsProperties";
import { invalidateSession } from "../../utils/localStorageHandlers";
import { AddWorkspaceComponent } from "./AddWorkspaceComponent";

import styles from "./AddWorkspacePage.module.scss";

export const AddWorkspacePage: React.FC = () => {
  const history = useHistory();
  const hasNoBackOption = "/add-first-workspace" === history.location.pathname;
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { getAddWorkspaceModifiedClassName } = useGetDisplayedComponentsProperties();
  const appliedClassName = getAddWorkspaceModifiedClassName(styles.blueBackground, styles.addWorkspaceMobile);
  const goToDashboard = () => {
    history.push("/dashboard");
  };

  return (
    <AnimatePresence custom={0} exitBeforeEnter={false}>
      <motion.div variants={fadeIn} initial="visible" animate="visible" exit="exit" className={appliedClassName}>
        <motion.div
          ref={ref}
          key={0}
          variants={variants}
          custom={0}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "spring", stiffness: 200, damping: 24 },
          }}
          className={styles.container}>
          <Switch>
            <Route exact path="/add-workspace" component={AddWorkspaceComponent} />
            <Route exact path="/add-first-workspace" component={AddWorkspaceComponent} />
          </Switch>
        </motion.div>
        {!hasNoBackOption && (
          <div className={styles.back} onClick={goToDashboard}>
            <Icon name="arrow-back" />
            <p>Back</p>
          </div>
        )}
        {hasNoBackOption && (
          <p className={styles.logout} onClick={invalidateSession}>
            Log out
          </p>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

const variants: Variants = {
  enter: (direction: number) => {
    return {
      y: direction > 0 ? 1000 : -1000,
    };
  },
  center: {
    y: 0,
  },
  exit: (direction: number) => {
    return {
      y: direction <= 0 ? 1000 : -1000,
    };
  },
};

const fadeIn: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      type: "ease",

      duration: 0.4,
    },
  },
};
