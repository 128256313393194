import React, { CSSProperties } from "react";

interface ImageProps {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  className?: string;
  style?: CSSProperties;
}

export const Image: React.FC<ImageProps> = ({ src, alt, width = "100%", height = "100%", className, style }) => {
  return (
    <img
      {...{
        src: `${process.env.PUBLIC_URL}/images/${src}`,
        alt,
        width,
        height,
        className,
        style,
      }}
    />
  );
};
