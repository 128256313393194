import { ButtonSize } from "../../components/Button/Button";
import { useGetBreakpointProperties } from "../screen/useGetBreakpointProperties";

interface FormButtonProperties {
  appliedButtonSize: ButtonSize.BIG | ButtonSize.MEDIUM;
  isFullWidth: boolean;
}

export const useGetFormButtonProperties = (customCondition?: boolean): FormButtonProperties => {
  const { isMobile } = useGetBreakpointProperties();
  const getAppliedButtonSize = () => {
    let appliedButtonSize = ButtonSize.MEDIUM;
    const fallbackCondition = customCondition === undefined && isMobile;
    const isCustomConditionSatisfied = customCondition !== undefined && customCondition;
    const isAppliedConditionSatisfied = fallbackCondition || isCustomConditionSatisfied;

    if (isAppliedConditionSatisfied) {
      appliedButtonSize = ButtonSize.BIG;
    }

    return appliedButtonSize;
  };
  const appliedButtonSize = getAppliedButtonSize();
  const isFullWidth = appliedButtonSize === ButtonSize.BIG;
  const formButtonProperties: FormButtonProperties = { appliedButtonSize, isFullWidth };

  return formButtonProperties;
};
