import React from "react";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";

import { TableBodyProps, TableType } from "../../../redux/types/table";
import { TableBodyRow } from "./TableBodyRow";

import styles from "./TableBody.module.scss";

export const TableBody: React.FC<TableBodyProps> = (props) => {
  const { tableType, getTableBodyProps, rows } = props;

  const getAppliedTableBodyClassName = () => {
    const appliedTableBodyClassNamesArray = [styles.tableBody];
    if (tableType === TableType.WORKSPACE_MANAGER) {
      appliedTableBodyClassNamesArray.push(styles.workspaceManagerTableBody);
    }

    if (tableType === TableType.CAMPAIGNS) {
      appliedTableBodyClassNamesArray.push(styles.campaignsTableBody);
    }

    if (tableType === TableType.WORKSPACE_USERS) {
      appliedTableBodyClassNamesArray.push(styles.workspaceUsersTableBody);
    }

    const appliedTableBodyClassName = classNames(...appliedTableBodyClassNamesArray);
    return appliedTableBodyClassName;
  };

  return (
    <tbody {...getTableBodyProps()} className={getAppliedTableBodyClassName()}>
      <AnimatePresence>
        {rows.map((row: any, idx: number) => (
          <TableBodyRow row={row} key={row.id} idx={idx} {...props} />
        ))}
      </AnimatePresence>
    </tbody>
  );
};
