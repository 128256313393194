import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { motion } from "framer-motion";

import { Icon } from "../Icon/Icon";

import styles from "./MenuItem.module.scss";

export interface MenuItemProps {
  iconName: string;
  text: string;
  to: string;
  active?: boolean;
  onClick?: (id: number) => void;
  index: number;
  errorInfo?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({ to, active, iconName, text, onClick, index, errorInfo }) => {
  const { pathname } = useLocation();
  const isActive = pathname.startsWith(to) || active;

  const handleClick = () => {
    if (onClick) {
      onClick(index);
    }
  };

  const getAppliedMenuItemClassName = () => {
    const appliedMenuItemClassesArray = [styles.menuItem];
    if (isActive) {
      appliedMenuItemClassesArray.push(styles.activeText);
    }

    const appliedMenuItemClasses = classNames(...appliedMenuItemClassesArray);
    return appliedMenuItemClasses;
  };

  return (
    <Link to={to} className={styles.link} onClick={handleClick}>
      <div className={getAppliedMenuItemClassName()}>
        <Icon name={iconName} isActive={isActive} width="20px" height="20px" />
        {isActive && <motion.div className={styles.active} layoutId="active" />}
        <p className={styles.text}>{text}</p>
        {errorInfo && <Icon name="ico-hint-red" width="16" className={styles.errorIcon} />}
      </div>
    </Link>
  );
};
