import React, { useState } from "react";

import { useGetCurrentWorkspaceDetail, useRemoveWorkspaceUserMutation } from "../../../api/workspace";
import { WorkspaceRoleDTO } from "../../../api/workspace/get-one/dto";
import { Button, ButtonSize, ButtonVariant } from "../../../components/Button/Button";
import { Container } from "../../../components/Container/Container";
import { useModal } from "../../../hooks/modal/useModal";
import { useGetUserRole } from "../../../hooks/user/useGetUserRole";
import { AddUserModal } from "../../../modals/AddUserModal/AddUserModal";
import { ConfirmationModal } from "../../../modals/ConfirmationModal/ConfirmationModal";
import { WorkspaceRoleType } from "../../../redux/types";
import { WorkspaceUsersTable } from "../../../tables/WorkspaceUsersTable/WorkspaceUsersTable";

import styles from "../WorkspaceSetupPage.module.scss";

export const WorkspaceUserRoles: React.FC = () => {
  const addUserModal = useModal();
  const [selectedUser, setSelectedUser] = useState<WorkspaceRoleDTO | undefined>();
  const workspace = useGetCurrentWorkspaceDetail();
  const userRole = useGetUserRole();
  const deleteUser = useRemoveWorkspaceUserMutation();
  const deleteUserModal = useModal();

  const handleUserDelete = async (email?: string) => {
    if (email) {
      deleteUser.mutate(
        { email, workspaceId: workspace.data!.id },
        {
          onSuccess: () => {
            deleteUserModal.toggle();
          },
        },
      );
    }
  };

  return (
    <>
      <Container noFlex>
        <div className={styles.header}>
          <h3 className={styles.title}>User roles</h3>
          {userRole === WorkspaceRoleType.ADMIN && (
            <div className={styles.addButton}>
              <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.SMALL} onClick={addUserModal.toggle}>
                + Add user
              </Button>
            </div>
          )}
        </div>
        <WorkspaceUsersTable setSelectedUser={setSelectedUser} deleteUserModalToggle={deleteUserModal.toggle} />
      </Container>
      <AddUserModal visible={addUserModal.visible} toggle={addUserModal.toggle} />

      <ConfirmationModal
        visible={deleteUserModal.visible}
        toggle={deleteUserModal.toggle}
        title="Delete user"
        onConfirm={() => handleUserDelete(selectedUser?.email)}
        onCancelText="Cancel"
        onConfirmText="Delete"
        isButtonLoading={deleteUser.isLoading}>
        <div>
          Do you really want to delete user {selectedUser?.firstName} {selectedUser?.lastName}? This operation is
          irreversibble.
        </div>
      </ConfirmationModal>
    </>
  );
};
