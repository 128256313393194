import React from "react";
import { Route, Switch } from "react-router";

import { GoogleAuthenticationResult } from "./GoogleAuthenticationResult";
import { GoogleAuthenticationSignIn } from "./GoogleAuthenticationSignIn";

export const GoogleAuthenticationPopupPage: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/authentication/sign-in" component={GoogleAuthenticationSignIn} />
      <Route exact path="/authentication/result" component={GoogleAuthenticationResult} />
    </Switch>
  );
};
