import React from "react";
import { Point } from "@nivo/line";

import { Icon } from "../../../components/Icon/Icon";
import { getPercentageIncrease } from "../../../utils/formatters/numberFormatters";

import styles from "./SummaryTooltip.module.scss";

interface SummaryTooltipProps {
  point?: Point;
}

export const SummaryTooltip: React.FC<SummaryTooltipProps> = ({ point }) => {
  //@ts-ignore
  const increase = getPercentageIncrease(point?.data?.historicValue ?? 0, point?.data.yFormatted as number);

  const getAppliedIconName = () => {
    let appliedIconName = "arrow-gray";
    if (increase > 0) {
      appliedIconName = "arrow-green";
    }
    if (increase < 0) {
      appliedIconName = "arrow-red";
    }

    return appliedIconName;
  };

  return (
    <div className={styles.tooltip}>
      <div className={styles.text}>
        <div className={styles.title}>${point?.data.yFormatted}</div>
        <div className={styles.subtitle}>
          <Icon name={getAppliedIconName()} height="8px" width="15px" />
          <div>{increase} %</div>
        </div>
      </div>
      {/* //@ts-ignore */}
      <div className={styles.point} style={{ backgroundColor: point?.serieColor }}></div>
      <div className={styles.triangle}></div>
    </div>
  );
};
